import * as actionTypes from "./actionTypes";

export const taskCreate = (task, history) => {
	return {
		type: actionTypes.TASK_CREATE,
		task: task, 
		history: history
	}
}

export const taskCreateError = (thisError) => {
	return {
		type: actionTypes.TASK_CREATE_ERROR,
		error: thisError
	}
}

export const taskCreateSuccess = () => {
	return {
		type: actionTypes.TASK_CREATE_SUCCESS
	}
}

export const taskDelete = (id, history) => {
	return {
		type: actionTypes.TASK_DELETE,
		id: id,
		history: history
	}
}

export const taskDeleteError = (thisError) => {
	return {
		type: actionTypes.TASK_DELETE_ERROR,
		error: thisError
	}
}

export const taskDeleteSuccess = () => {
	return {
		type: actionTypes.TASK_DELETE_SUCCESS
	}
}

export const taskGetAll = () => {
	return {
		type: actionTypes.TASK_GET_ALL
	}
}

export const taskGetById = (id) => {
	return {
		type: actionTypes.TASK_GET_BY_ID,
		id: id
	}
}

export const taskGetError = (thisError) => {
	return {
		type: actionTypes.TASK_GET_ERROR,
		error: thisError
	}
}

export const taskGetSuccess = (data) => {
	return {
		type: actionTypes.TASK_GET_SUCCESS,
		tasks: data
	}
}

