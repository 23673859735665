import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, FormGroup, Label, Row } from 'reactstrap';
import { connect } from "react-redux";

import * as actionsBankAccount from '../../store/BankAccount/actions';
import * as actionsScheduledTask from '../../store/ScheduledTask/actions';
import Backdrop from '../Common/Backdrop';
import * as cronHelper from '../../helpers/cronHelper';
import * as editFormControls from '../../helpers/editFormControls';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
import * as ScheduledTaskType from '../../definitions/enums/ScheduledTaskType'
import ScheduleSelector from 'components/Common/ScheduleSelector';

import classes from './Pages.module.css';

const fieldDefaultValues = {
    id: 0,
    cronExpression: "* 0 8 ? * 1-5",
    bankAccountId: 0,
    allowCurrentDay: false,
    type: "STATEMENT_REQUEST",
}

class EditScheduledTask extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...fieldDefaultValues,

            changed: false
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    setStateFromScheduledTask() {
        if (this.props.scheduledTask && this.props.scheduledTask.id) {
            this.setState({
                id: this.props.scheduledTask.id,
                cronExpression: cronHelper.backendToFrontend(this.props.scheduledTask.cronExpression),
                bankAccountId: this.props.scheduledTask.bankAccountId,
                allowCurrentDay: this.props.scheduledTask.allowCurrentDay,
                type: this.props.scheduledTask.type,
            });
        } else {
            this.setState({
                ...fieldDefaultValues
            });
        }
    }

    componentDidMount() {
        this.setStateFromScheduledTask();
        if (!this.props.bankAccounts || !this.props.bankAccounts.length) {
            this.props.onGetBankAccounts();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.scheduledTask !== this.props.scheduledTask) {
            this.setStateFromScheduledTask();
        }
        if (!this.props.bankAccounts || !this.props.bankAccounts.length) {
            this.props.onGetBankAccounts();
        }
        if (this.props.bankAccounts && this.props.bankAccounts.length) {
            this.state.bankAccountId = this.props.bankAccounts[0].id
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedScheduledTask = {
            cronExpression: cronHelper.frontendToBackend(this.state.cronExpression),
            bankAccountId: this.state.bankAccountId,
            allowCurrentDay: this.state.allowCurrentDay,
            type: this.state.type,
        };
        if (this.state.id) {
            newOrUpdatedScheduledTask = {
                id: this.state.id,
                ...newOrUpdatedScheduledTask
            };
        }
        this.props.onSaveScheduledTask(newOrUpdatedScheduledTask);
        this.setState({
            changed: false
        });
        this.props.onClose();
    }

    closeForm = () => {
        this.props.onClose();
    }

    handleApplySchedule = (cronExpression) => {
        this.setState({
            cronExpression: cronExpression
        });
    };

    render() {

        const typeOptions = inputSelectUtils.generateOptionsFromData(
            ScheduledTaskType.ScheduledTaskType,
            row => row.description
        );

        const bankAccountOptions = inputSelectUtils.generateOptionsFromData(
            this.props.bankAccounts,
            row => row.accountNo + " " + row.currencyCode
        );

        let scheduledTaskControls;
        switch (this.state.type) {
            case "STATEMENT_REQUEST":
                scheduledTaskControls =
                    <React.Fragment>
                        {editFormControls.selectControl("bankAccountId", "Bank account", this.onChange, this.state.bankAccountId, bankAccountOptions)}
                        {editFormControls.checkboxControl("allowCurrentDay", "Allow current day requests", this.onChange, this.state.allowCurrentDay)}
                    </React.Fragment>
                break;
        }

        const editForm =
            <form
                className="outer-repeater"
                onSubmit={this.onSubmit}
            >
                {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                {editFormControls.hiddenValueControl("cronExpression", this.onChange, this.state.cronExpression)}
                
                {editFormControls.selectControl("type", "Task type", this.onChange, this.state.type, typeOptions, this.state.id)}
                {/* {editFormControls.staticTextControl("cronExpression", "Cron expression", this.state.cronExpression)} */}

                <FormGroup className="mb-4" row>
                    <Label
                        className={editFormControls.labelClass}
                    >
                        Schedule
                    </Label>
                    <Col lg={editFormControls.valueColSize}>
                        <ScheduleSelector
                            cronExpression={this.state.cronExpression}
                            toggle={this.toggleScheduleModal}
                            onApply={this.handleApplySchedule}
                        />
                    </Col>
                </FormGroup>

                {scheduledTaskControls}

                <Row>
                    <Col></Col>
                    <Col>
                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                    </Col>
                </Row>
            </form>

        return (

            <div>
                <Backdrop
                    show
                    onClick={this.closeForm}
                >
                </Backdrop>
                <div
                    className={classes.EditScheduledTaskContainer}
                >
                    <Card
                        className={classes.PageCard}
                    >
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    {this.props.scheduledTask ? "Edit" : "Create"} scheduled task
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.closeForm}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody>

                            {this.props.loadingBankAccounts
                                ? editFormControls.bigSpinner()
                                : editForm}

                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ bankAccount, scheduledTask }) => ({
    bankAccounts: bankAccount.bankAccounts,
    loadingBankAccounts: bankAccount.loading,
    saving: scheduledTask.saving
});

const mapDispatchToProps = dispatch => ({
    onGetBankAccounts: () => dispatch(actionsBankAccount.bankAccountGetAll()),
    onSaveScheduledTask: (scheduledTask) => dispatch(actionsScheduledTask.scheduledTaskCreate(scheduledTask))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditScheduledTask);