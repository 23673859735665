import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";

import { Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import * as actionsSmartId from "../../store/SmartId/actions";
import * as actionsUserAccountLogin from "../../store/UserAccountLogin/actions";
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as errorMessageHelper from '../../helpers/errorMessageHelper';
import * as formatUtils from '../../helpers/formatUtils';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
import * as loginHelper from '../../helpers/loginHelper';
import profile from "../../assets/images/cm-logo-transparent.png";
import * as SmartIdCountryCode from "../../definitions/enums/SmartIdCountryCode";



class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTabId: 0,

			email: "",
			password: "",
			smartIdIdentityNumber: "",
			smartIdCountryCode: "LV",
			rememberSmartIdData: false,

			showPassword: false,
			attemptedSubmission: false
		};

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		if (thisObjectName === "email" && this.state.activeTabId == 1) {
			const data = loginHelper.getUserSmartIdData(thisValue);
			if (data) {
				this.setState({
					smartIdIdentityNumber: data.identityNumber,
					smartIdCountryCode: data.countryCode,
					rememberSmartIdData: true
				});
			}
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		this.setState({
			attemptedSubmission: true
		});
		if (this.state.activeTabId === 0) {
			if (this.state.email.length && this.state.password.length) {
				this.props.onLoginUser({
					email: this.state.email.trim(),
					password: this.state.password
				}, this.props.history);
			}
		} else if (this.state.activeTabId === 1) {
			const identityNumber = formatUtils.formatIdentityNumber(this.state.smartIdIdentityNumber, this.state.smartIdCountryCode);
			if (this.state.email.length && identityNumber != null) {
				this.props.onLoginViaSmartId({
					username: this.state.email.trim(),
					identityType: "PNO",
					countryCode: this.state.smartIdCountryCode,
					identityNumber: identityNumber
				}, this.props.history, this.state.rememberSmartIdData);
			}
		}
	}

	componentDidMount() {
		switch (loginHelper.getLoginType()) {
			case loginHelper.LOGIN_TYPE_PASSWORD:
				this.setState({
					activeTabId: 0
				});
				break;
			case loginHelper.LOGIN_TYPE_SMART_ID:
				this.setState({
					activeTabId: 1
				});
				break;
		}
		this.props.onLoginCheck(this.props.history);
	}

	showPassword = (doShowPassword) => {
		this.setState({
			showPassword: doShowPassword
		});
	}

	setActiveTabId = (activeTabId) => {
		this.setState({
			activeTabId: activeTabId
		});
	}

	onResendActivation = () => {
		this.props.onResendActivation({ username: this.props.attemptedLoginUsername });
	}

	render() {

		const userAccountNotActivated = errorMessageHelper.httpErrorMessageAndAdditionalText("", this.props.errorUserLogin).includes("User account not activated");
		const userAccountNotActivatedAlert =
			<Alert
				color="warning"
			>
				User account {this.props.attemptedLoginUsername} is not activated. Check your mailbox for activation email or <a href="#" onClick={this.onResendActivation}>click here to send it again</a>.
			</Alert>

		const emailInput =
			<div className="mb-3">
				<Label>
					Email
				</Label>
				<Input
					id="email"
					name="email"
					type="text"
					invalid={this.state.attemptedSubmission && !this.state.email.length}
					placeholder={"Enter email"}
					onChange={this.onChange}
					value={this.state.email}
				/>
			</div>

		const passwordInput =
			<div className="mb-3">
				<Label>
					Password
				</Label>
				<InputGroup>
					<Input
						id="password"
						name="password"
						type={this.state.showPassword ? 'text' : 'password'}
						invalid={this.state.attemptedSubmission && !this.state.password.length}
						placeholder={"Enter password"}
						onChange={this.onChange}
						value={this.state.password}
					/>
					<InputGroupText
						onMouseDown={() => this.showPassword(true)}
						onTouchStart={() => this.showPassword(true)}
						onMouseUp={() => this.showPassword(false)}
						onTouchCancel={() => this.showPassword(false)}
						onTouchEnd={() => this.showPassword(false)}
						onContextMenu={(e) => e.preventDefault() /* prevents the context menu on touch and hold */}
					>
						{this.state.showPassword ? <FaEyeSlash /> : <FaEye />}
					</InputGroupText>
				</InputGroup>
			</div>

		const smartIdCountryCodeOptions = inputSelectUtils.generateOptionsFromData(SmartIdCountryCode.SmartIdCountryCode, identityType => identityType.description);
		const smartIdCountryCodeInput =
			<div className="mb-3">
				<Label>
					Country
				</Label>
				<Input
					id="smartIdCountryCode"
					name="smartIdCountryCode"
					type="select"
					onChange={this.onChange}
					value={this.state.smartIdCountryCode}
				>
					{smartIdCountryCodeOptions}
				</Input>
			</div>

		const smartIdIdentityNumber =
			<div className="mb-3">
				<Label>
					Identity number
				</Label>
				<Input
					id="smartIdIdentityNumber"
					name="smartIdIdentityNumber"
					type="text"
					invalid={this.state.attemptedSubmission && formatUtils.formatIdentityNumber(this.state.smartIdIdentityNumber, this.state.smartIdCountryCode) == null}
					placeholder={"Enter identity number"}
					onChange={this.onChange}
					value={this.state.smartIdIdentityNumber}
				/>
			</div>

		const loginButton =
			<div className="mt-3 d-grid">
				<button
					className="btn btn-primary btn-block"
					type="submit"
					disabled={this.props.loading}
				>
					Log In
					{" "}
					{this.props.loading ? editFormControls.buttonSpinner() : null}
				</button>
			</div>

		const passwordRestoreControl =
			<div className="mt-4 text-center">
				<Link
					to={endpointsFrontend.USER_PASSWORD_RESET_REQUEST}
					className="text-muted"
				>
					<i className="mdi mdi-lock me-1" /> Forgot your password?
				</Link>
			</div>

		const rememberSmartIdDataControl =
			<div className="form-check">
				<input
					id="rememberSmartIdData"
					name="rememberSmartIdData"
					type="checkbox"
					className="form-check-input"
					onChange={this.onChange}
					value={this.state.rememberSmartIdData ? this.state.rememberSmartIdData : ""}
					checked={this.state.rememberSmartIdData ? true : false}
				/>
				<label
					className="form-check-label"
					htmlFor="customControlInline"
				>
					Remember me
				</label>
			</div>

		const passwordLoginControls =
			<React.Fragment>
				{emailInput}
				{passwordInput}
				{loginButton}
				{passwordRestoreControl}
			</React.Fragment>

		const smartIdLoginControls =
			<React.Fragment>
				{emailInput}
				{smartIdCountryCodeInput}
				{smartIdIdentityNumber}
				{rememberSmartIdDataControl}
				{loginButton}
			</React.Fragment>

		const smartIdControlCodeControls =
			<React.Fragment>
				<Label>
					Control code:
				</Label>
				<br />
				{this.props.smartIdControlCodeLoading ? editFormControls.bigSpinner() : (<h1>{this.props.smartIdControlCode}</h1>)}
			</React.Fragment>

		const loginForm =
			<React.Fragment>
				<div className="p-2">
					<Row>
						<Nav tabs pills justified>
							<NavItem>
								<NavLink
									active={this.state.activeTabId === 0}
									onClick={() => this.setActiveTabId(0)}
								>
									Password
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									active={this.state.activeTabId === 1}
									onClick={() => this.setActiveTabId(1)}
								>
									Smart-ID
								</NavLink>
							</NavItem>
						</Nav>
					</Row>
					<br />
					<Row>
						<form
							onSubmit={this.onSubmit}
						>
							{userAccountNotActivated && !this.props.resendingActivation && userAccountNotActivatedAlert}
							{!userAccountNotActivated && editFormControls.errorAlert(this.props.errorUserLogin)}
							{editFormControls.errorAlert(this.props.errorSmartId)}

							{!this.props.checkingLogin && this.state.activeTabId === 0 && passwordLoginControls}
							{!this.props.checkingLogin && this.state.activeTabId === 1 && ((!this.props.smartIdControlCode && !this.props.smartIdControlCodeLoading) || this.props.errorSmartId) && smartIdLoginControls}
							{!this.props.checkingLogin && this.state.activeTabId === 1 && (this.props.smartIdControlCode || this.props.smartIdControlCodeLoading) && !this.props.errorSmartId && smartIdControlCodeControls}

						</form>
					</Row>
				</div>
			</React.Fragment>

		const checkingLoginSpinner =
			<div>

				<p align="center">
					<br />
					Please wait...
					<br />
					<br />
					{editFormControls.bigSpinner()}
				</p>
			</div>

		return (
			<React.Fragment>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-primary bg-soft px-5 py-3">	{/* change class from bg-primary bg-soft to landing-footer to obtain the dark background */}
										<img src={profile} alt="" className="img-fluid" />
									</div>
									<CardBody className="pt-0">
										{this.props.checkingLogin && checkingLoginSpinner}
										{!this.props.checkingLogin && loginForm}
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									{/*
									<p>
										Don't have an account?{" "}
										<Link
											to={endpointsFrontend.USER_ACCOUNT_REGISTER}
											className="fw-medium text-primary"
										>
											{" "}
											Sign up now{" "}
										</Link>{" "}
									</p>
											*/}
									<p>
										Copyright © {new Date().getFullYear()} D.Vision SIA
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div >
			</React.Fragment >
		)
	}
}

const mapStateToProps = ({ smartId, userLogin }) => {
	return {
		attemptedLoginUsername: userLogin.attemptedLoginUsername,
		checkingLogin: userLogin.checkingLogin,
		errorSmartId: smartId.error,
		errorUserLogin: userLogin.error,
		loading: userLogin.loading,
		resendingActivation: userLogin.resendingActivation,
		smartIdControlCode: smartId.controlCode,
		smartIdControlCodeLoading: smartId.controlCodeLoading
	};
}

const mapDispatchToProps = dispatch => ({
	onLoginUser: (user, history) => dispatch(actionsUserAccountLogin.loginUser(user, history)),
	onLoginCheck: (history) => dispatch(actionsUserAccountLogin.loginCheck(history)),
	onLoginViaSmartId: (data, history, saveUserData) => dispatch(actionsSmartId.smartIdLoginInit(data, history, saveUserData)),
	onResendActivation: (data) => dispatch(actionsUserAccountLogin.userAccountResendActivation(data))
})

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Login)
);