import * as actionTypes from "./actionTypes";

const initialState = {
	error: null,
	loading: false,
	dashboardData: null,
}

export default function dashboardDataReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.DASHBOARD_DATA_GET_ALL:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.DASHBOARD_DATA_GET_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				dashboardData: action.dashboardData
			}
		case actionTypes.DASHBOARD_DATA_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		default:
			return state;
	}
}