import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row } from "reactstrap";

import * as actions from '../../store/Allocation/actions';
import * as actionsCompanySettings from '../../store/CompanySettings/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsAllocation from '../../definitions/columns/allocation';
import * as columnsAllocatedBankCharge from '../../definitions/columns/allocation_bankCharge';
import * as columnsAllocatedCustomerInvoice from '../../definitions/columns/allocation_customerInvoice';
import * as columnsAllocatedPayment from '../../definitions/columns/allocation_payment';
import * as columnsAllocatedSupplierInvoice from '../../definitions/columns/allocation_supplierInvoice';
import * as columnsBankStatementRecord from '../../definitions/columns/bankStatementRecord';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsBackendFilters from '../../definitions/endpoints/endpoints-backend-filters';
import SelectPayment from '../../components/Pages/SelectPayment';
import SelectSupplierInvoices from '../../components/Pages/SelectSupplierInvoices';
import SelectCustomerInvoices from '../../components/Pages/SelectCustomerInvoices';
import OverlayWithSpinner from "components/Common/OverlayWithSpinner";

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class BankStatementReconcile extends Component {

	constructor(props) {
		super(props);
		this.state = {
			applyAllocationsDropdownOpen: false,
			bankStatementId: null,
			file: null,
			showSelectPayment: false,
			showSelectSupplierInvoices: false,
			showSelectCustomerInvoices: false,
			bankChargeControls: []
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		this.setState({
			bankStatementId: id
		});
		if (id) {
			this.props.onGetAllocations(id);
		}
		if (!this.props.companySettings) {
			this.props.onGetCompanySettings();
		}
	}

	handleSubmit = event => {
		event.preventDefault();

	}

	handleChange = event => {
		this.setState({
			file: event.target.files[0]
		});
	}

	commandButtons = (cell, row, rowIndex, formatExtraData) => {
		return (
			<React.Fragment>
				<Button
					size="sm"
					color="danger"
					hidden={row.bankStatementRecordStatus !== "NONE"}
					onClick={() => {
						if (window.confirm("Are you sure you want to delete this allocation?")) {
							const allocation = {
								bankStatementRecordId: formatExtraData.bankStatementRecordId,
								paymentBulkId: (formatExtraData.type === "payment" ? row.id : null),
								supplierInvoiceId: (formatExtraData.type === "supplierInvoice" ? row.id : null),
								customerInvoiceId: (formatExtraData.type === "customerInvoice" ? row.id : null),
								removeBankCharge: (formatExtraData.type === "bankCharge")
							}
							this.props.onDeleteAllocation(allocation);
						}
					}}
				>
					<i className="bx bx-x" />
				</Button>
			</React.Fragment>
		);
	};

	launchAllocateToPayments = (row) => {
		this.props.onSetAllocationRecord(row);
		this.setState({ showSelectPayment: true });
	}

	launchAllocateToSupplierInvoices = (row) => {
		this.props.onSetAllocationRecord(row);
		this.setState({ showSelectSupplierInvoices: true });
	}

	launchAllocateToCustomerInvoices = (row) => {
		this.props.onSetAllocationRecord(row);
		this.setState({ showSelectCustomerInvoices: true });
	}

	applyBankChargeControl = (thisBankChargeControl) => {
		const bankChargeControls = this.state.bankChargeControls
			.filter(bankChargeControl => bankChargeControl.id !== thisBankChargeControl.id)
			.concat(thisBankChargeControl);

		this.setState({
			bankChargeControls: bankChargeControls,
		})
	}

	getBankChargeControlById = (id) => {
		return this.state.bankChargeControls.filter(bankChargeControl => bankChargeControl.id == id)[0];
	}

	launchAllocateAsBankCharge = (row) => {
		const thisBankChargeControl = this.getBankChargeControlById(row.id);
		thisBankChargeControl.show = !thisBankChargeControl.show;
		this.applyBankChargeControl(thisBankChargeControl);
	}

	onChangeBankChargeValue = (id, amount) => {
		const thisBankChargeControl = this.getBankChargeControlById(id);
		thisBankChargeControl.amount = amount;
		this.applyBankChargeControl(thisBankChargeControl);
	}

	onAddBankCharge = (id) => {
		const thisBankChargeControl = this.getBankChargeControlById(id);
		if (thisBankChargeControl.amount > 0) {
			if (!window.confirm("Bank charge amount must be negative. Continue anyway?")) {
				return;
			}
		}
		this.props.onCreateAllocation({
			bankStatementRecordId: id,
			bankChargeAmount: thisBankChargeControl.amount
		});
		this.applyBankChargeControl(thisBankChargeControl);
	}

	onApplyAllocations = () => {
		if (window.confirm("Apply all allocations?")) {
			this.props.onApplyAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_APPLY_ALL);
		}
	}

	onApplyAllocations_Charges = () => {
		if (window.confirm("Apply all bank charges?")) {
			this.props.onApplyAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_APPLY_CHARGES);
		}
	}

	onApplyAllocations_Incoming = () => {
		if (window.confirm("Apply all incoming payments?")) {
			this.props.onApplyAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_APPLY_INCOMING);
		}
	}

	onApplyAllocations_IncomingAndCharges = () => {
		if (window.confirm("Apply all incoming payments and bank charges?")) {
			this.props.onApplyAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_APPLY_INCOMING_AND_CHARGES);
		}
	}

	onApplyAllocations_Outgoing = () => {
		if (window.confirm("Apply all outgoing payments?")) {
			this.props.onApplyAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_APPLY_OUTGOING);
		}
	}

	onApplyAllocations_OutgoingAndCharges = () => {
		if (window.confirm("Apply all outgoing payments and bank charges?")) {
			this.props.onApplyAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_APPLY_OUTGOING_AND_CHARGES);
		}
	}

	onAutoAllocate = () => {
		this.props.onAutoAllocate(this.state.bankStatementId, this.props.history);
	}

	onRevertAllAllocations = () => {
		const exportedToERPAllocations = this.props.bankStatementRecords.filter(bankStatementRecord => bankStatementRecord.status === "EXPORTED");
		let warningText;
		if (exportedToERPAllocations.length) {
			warningText = "Some of the bank statement records have already been exported, are you sure you want to revert their status?";
		} else {
			warningText = "Revert all allocations?";
		}
		if (window.confirm(warningText)) {
			this.props.onRevertAllAllocations(this.state.bankStatementId);
		}
	}

	onRevertOneAllocation = (row) => {
		if (row.status === "EXPORTED") {
			if (!window.confirm("This bank statement record has already been exported. Are you sure you want to revert its status?")) {
				return;
			}
		}
		const bankStatementRecordId = row.id;
		this.props.onRevertAllocation(bankStatementRecordId);

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.bankStatementRecords != this.props.bankStatementRecords) {
			const bankChargeControls = [];
			for (let i in this.props.bankStatementRecords) {
				bankChargeControls.push({
					id: this.props.bankStatementRecords[i].id,
					show: false,
					amount: 0
				});
				this.setState({
					bankChargeControls: bankChargeControls,
				})
			}
		}
	}

	onToggleApplyAllocationsDropdown = () => {
		this.setState({
			applyAllocationsDropdownOpen: !this.state.applyAllocationsDropdownOpen
		});
	}

	render() {

		const SelectPaymentForm =
			<SelectPayment
				onClose={() => this.setState({ showSelectPayment: false })}
			/>

		const SelectSupplierInvoicesForm =
			<SelectSupplierInvoices
				onClose={() => this.setState({ showSelectSupplierInvoices: false })}
			/>

		const SelectCustomerInvoicesForm =
			<SelectCustomerInvoices
				onClose={() => this.setState({ showSelectCustomerInvoices: false })}
			/>

		const pageTitle = "Bank Statements | " + config.AppName;
		const breadcrumbsTitle = "Bank statements";
		const breadcrumbsItem = "Reconcile bank statement";

		const columns = [
			columnsBankStatementRecord.criterionTrustLevel,
			columnsBankStatementRecord.debitCredit,
			columnsBankStatementRecord.beneficiaryName,
			columnsBankStatementRecord.beneficiaryRegNo,
			columnsBankStatementRecord.currencyCode,
			columnsBankStatementRecord.amount,
			columnsAllocation.unallocatedAmount,
			columnsBankStatementRecord.paymentDate,
			columnsBankStatementRecord.status
		];

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				//this.props.history.push(endpointsFrontend.BANK_STATEMENT_EDIT.replace(":id", row.id));
			}
		};

		const expandRow = {
			renderer: (row, rowIndex) => {

				const allocatedPayments = this.props.allocatedPayments.filter(payment => payment.bankStatementRecordId == row.id);
				const allocatedSupplierInvoices = this.props.allocatedSupplierInvoices.filter(invoice => invoice.bankStatementRecordId == row.id);
				const allocatedCustomerInvoices = this.props.allocatedCustomerInvoices.filter(invoice => invoice.bankStatementRecordId == row.id);
				const allocatedBankCharges = this.props.allocatedBankCharges.filter(bankCharge => bankCharge.bankStatementRecordId == row.id);

				const bankStatementRecordId = row.id;

				const allocatedPaymentTable = (
					<React.Fragment>
						<BootstrapTable
							keyField='id'
							data={allocatedPayments}
							columns={[
								columnsAllocatedPayment.criterionId,
								columnsAllocatedPayment.supplierName,
								columnsAllocatedPayment.paymentDetails,
								columnsAllocatedPayment.currencyCode,
								columnsAllocatedPayment.totalAmountToPay,
								columnsAllocatedPayment.allocatedAmountInfo,
								{
									dataField: "commandButtons",
									text: "",
									formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, {
										bankStatementRecordId: bankStatementRecordId,
										type: "payment"
									})
								}
							]}
							condensed
						/>
					</React.Fragment>
				);

				const allocatedSupplierInvoiceTable = (
					<React.Fragment>
						<BootstrapTable
							keyField='id'
							data={allocatedSupplierInvoices}
							columns={[
								columnsAllocatedSupplierInvoice.criterionId,
								columnsAllocatedSupplierInvoice.supplierName,
								columnsAllocatedSupplierInvoice.invoiceNo,
								columnsAllocatedSupplierInvoice.currencyCode,
								columnsAllocatedSupplierInvoice.invoiceAmount,
								columnsAllocatedSupplierInvoice.allocatedAmountInfo,
								{
									dataField: "commandButtons",
									text: "",
									formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, { bankStatementRecordId: bankStatementRecordId, type: "supplierInvoice" })
								}
							]}
							condensed
						/>
					</React.Fragment>
				);

				const allocatedSupplierTable = (
					<React.Fragment>
						<BootstrapTable
							keyField='id2'
							data={[
								{ id2: "1", accountNo: "1", currencyCode: "EUR", openingDate: "2022-07-08", closingDate: "2022-07-09" },
							]}
							columns={[
								{
									dataField: 'accountNo',
									text: 'Account No.',
								},
								{
									dataField: 'currencyCode',
									text: 'Currency',
								},
								{
									dataField: 'openingDate',
									text: 'From',
								},
								{
									dataField: 'closingDate',
									text: 'To',
								}
							]}
							condensed
						/>
					</React.Fragment>
				);

				const allocatedCustomerInvoiceTable = (
					<React.Fragment>
						<BootstrapTable
							keyField='id'
							data={allocatedCustomerInvoices}
							columns={[
								columnsAllocatedCustomerInvoice.criterionId,
								columnsAllocatedCustomerInvoice.customerName,
								columnsAllocatedCustomerInvoice.invoiceNo,
								columnsAllocatedCustomerInvoice.currencyCode,
								columnsAllocatedCustomerInvoice.amountWithVAT,
								columnsAllocatedCustomerInvoice.allocatedAmountInfo,
								{
									dataField: "commandButtons",
									text: "",
									formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, { bankStatementRecordId: bankStatementRecordId, type: "customerInvoice" })
								}
							]}
							condensed
						/>
					</React.Fragment>
				);

				const allocatedBankChargeTable = (
					<BootstrapTable
						keyField='id'
						data={allocatedBankCharges}
						columns={[
							columnsAllocatedBankCharge.criterionId,
							columnsAllocatedBankCharge.type,
							columnsAllocatedBankCharge.currencyCode,
							columnsAllocatedBankCharge.amount,
							{
								dataField: "commandButtons",
								text: "",
								formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, { bankStatementRecordId: bankStatementRecordId, type: "bankCharge" })
							}
						]}
						condensed
					/>
				);

				const allocateToPaymentButton =
					<React.Fragment>
						<Button
							onClick={() => this.launchAllocateToPayments(row)}
						>
							Allocate to a payment
						</Button>
						<br />
						<br />
					</React.Fragment>

				const allocateWithPaymentsControls =
					<React.Fragment>
						{(row.unallocatedAmount == 0 || row.status !== "NONE" ?
							null :
							allocateToPaymentButton)}

						{allocatedPayments && allocatedPayments.length ? allocatedPaymentTable : null}
					</React.Fragment>

				const allocateToSupplierInvoicesButton =
					<React.Fragment>
						<Button
							onClick={() => this.launchAllocateToSupplierInvoices(row)}
						>
							Allocate to supplier invoices
						</Button>
						<br />
						<br />
					</React.Fragment>

				const allocateWithSupplierInvoicesControls =
					<React.Fragment>
						{(row.unallocatedAmount == 0 || row.status !== "NONE" ?
							null :
							allocateToSupplierInvoicesButton)}

						{allocatedSupplierInvoices && allocatedSupplierInvoices.length ? allocatedSupplierInvoiceTable : null}
					</React.Fragment>

				const allocateWithSupplierControls =
					<React.Fragment>
						<Button
							disabled={row.unallocatedAmount == 0}
							hidden={row.unallocatedAmount == 0}
						>
							Allocate to a supplier
						</Button>

						<br />
						<br />

						{/*allocatedSupplierTable*/}
					</React.Fragment>

				const allocateToCustomerInvoicesButton =
					<React.Fragment>
						<Button
							onClick={() => this.launchAllocateToCustomerInvoices(row)}
						>
							Allocate to customer invoices
						</Button>
						<br />
						<br />
					</React.Fragment>

				const allocateWithCustomerInvoicesControls =
					<React.Fragment>
						{(row.unallocatedAmount == 0 || row.status !== "NONE" ?
							null :
							allocateToCustomerInvoicesButton)}

						{allocatedCustomerInvoices && allocatedCustomerInvoices.length ? allocatedCustomerInvoiceTable : null}
					</React.Fragment>

				const thisBankChargeControl = this.state.bankChargeControls.filter(bankChargeControl => bankChargeControl.id == row.id)[0];
				const thisBankChargeControlHTMLID = "bankChangeControl" + thisBankChargeControl.id;
				const addBankChargeButton =
					<React.Fragment>
						<Button
							onClick={() => this.launchAllocateAsBankCharge(row)}
						>
							Add bank charge
						</Button>
						<br />
						<br />
					</React.Fragment>

				const allocateAsBankChargeControls =
					<React.Fragment>
						{(row.unallocatedAmount == 0) || (row.status !== "NONE") || (allocatedBankCharges && allocatedBankCharges.length) ?
							null :
							addBankChargeButton}

						{thisBankChargeControl.show ? (
							<FormGroup className="mb-4" row>
								<Label
									htmlFor={thisBankChargeControlHTMLID}
									className="col-form-label col-lg-2"
								>
									Charge amount (negative)
								</Label>
								<Col lg="3">
									<Input
										id={thisBankChargeControlHTMLID}
										name={thisBankChargeControlHTMLID}
										type="number"
										step="0.01"
										className="form-control"
										placeholder="Enter bank charge amount"
										onChange={(e) => this.onChangeBankChargeValue(thisBankChargeControl.id, e.target.value)}
										value={thisBankChargeControl.amount}
									/>
								</Col>
								<Col>
									<Button
										onClick={(e) => this.onAddBankCharge(thisBankChargeControl.id, e.target.value)}
										disabled={(thisBankChargeControl.amount == 0)}
									>
										Add
									</Button>
								</Col>
							</FormGroup>
						) : null}

						{allocatedBankCharges && allocatedBankCharges.length ? allocatedBankChargeTable : null}
					</React.Fragment>

				const revertStatusControls =
					<React.Fragment>
						<Button
							color="danger"
							onClick={() => this.onRevertOneAllocation(row)}
						>
							Revert status
						</Button>
					</React.Fragment>

				return (
					<div className="react-bootstrap-table">
						<table className="table">
							<tbody style={{ borderColor: "#FFFFFF" }}>
								<tr>
									<td style={{ border: "0px transparent" }}></td>
									<td style={{ border: "0px transparent" }}>
										<div
											style={{
												position: "relative"
											}}
										>
											<OverlayWithSpinner
												show={this.props.updatingAllocations}
											/>

											{editFormControls.staticTextControlWithoutLabel("paymentDetails", row.paymentDetails)}
											<br />

											{row.debitCredit === "D" ? allocateWithPaymentsControls : null}
											{row.debitCredit === "D" ? allocateWithSupplierInvoicesControls : null}
											{/*allocateWithSupplierControls*/}
											{row.debitCredit === "C" ? allocateWithCustomerInvoicesControls : null}
											{/*row.debitCredit === "C" ?*/ allocateAsBankChargeControls/* : null*/}
											{row.status == "NONE" ? null : revertStatusControls}

										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				)
			},
			showExpandColumn: true,
			//expandByColumnOnly: true,
			expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
				expanded ? <i className="bx bxs-minus-square" /> : <i className="bx bxs-plus-square" />
			),
			expandHeaderColumnRenderer: ({ isAnyExpands }) => (
				isAnyExpands ? <i className="bx bxs-minus-square" /> : <i className="bx bxs-plus-square" />
			)
		};

		const table =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.bankStatementRecords}
					columns={columns}
					pagination={paginationFactory()}
					rowEvents={rowEvents}
					expandRow={expandRow}
					filter={filterFactory()}
				/>
			</div>

		let appliedBankStatementRecords = [];
		let notAppliedBankStatementRecords = [];
		if (this.props.bankStatementRecords) {
			appliedBankStatementRecords = this.props.bankStatementRecords.filter(bankStatementRecord => bankStatementRecord.status !== "NONE");
			notAppliedBankStatementRecords = this.props.bankStatementRecords.filter(bankStatementRecord => bankStatementRecord.status === "NONE");
		}
		const showApplyAllocationsButton = (this.state.bankStatementId && notAppliedBankStatementRecords.length);
		const showRevertAllocationsButton = (this.state.bankStatementId && appliedBankStatementRecords.length);
		const applyAllocationsButton =
			<Button
				color="primary"
				onClick={this.onApplyAllocations}
			>
				Apply allocations
				{" "}
				{this.props.applyingAllocations ? editFormControls.buttonSpinner() : null}
			</Button>

		const applyAllocationsDropdownItem_Charges = <DropdownItem onClick={this.onApplyAllocations_Charges}>Bank charges</DropdownItem>
		const applyAllocationsDropdownItem_Outgoing = <DropdownItem onClick={this.onApplyAllocations_Outgoing}>Outgoing payments</DropdownItem>
		const applyAllocationsDropdownItem_OutgoingAndCharges = <DropdownItem onClick={this.onApplyAllocations_OutgoingAndCharges}>Outgoing payments and bank charges</DropdownItem>
		const applyAllocationsDropdownItem_Incoming = <DropdownItem onClick={this.onApplyAllocations_Incoming}>Incoming payments</DropdownItem>
		const applyAllocationsDropdownItem_IncomingAndCharges = <DropdownItem onClick={this.onApplyAllocations_IncomingAndCharges}>Incoming payments and bank charges</DropdownItem>

		let hasStatementExportTemplate_Outgoing, hasStatementExportTemplate_OutgoingAndCharges, hasStatementExportTemplate_Incoming, hasStatementExportTemplate_IncomingAndCharges, hasStatementExportTemplate_Charges;
		if (this.props.companySettings) {
			hasStatementExportTemplate_Outgoing = this.props.companySettings.statementExportTemplate_Outgoing && this.props.companySettings.statementExportTemplate_Outgoing.length
			hasStatementExportTemplate_OutgoingAndCharges = this.props.companySettings.statementExportTemplate_OutgoingAndCharges && this.props.companySettings.statementExportTemplate_OutgoingAndCharges.length
			hasStatementExportTemplate_Incoming = this.props.companySettings.statementExportTemplate_Incoming && this.props.companySettings.statementExportTemplate_Incoming.length;
			hasStatementExportTemplate_IncomingAndCharges = this.props.companySettings.statementExportTemplate_IncomingAndCharges && this.props.companySettings.statementExportTemplate_IncomingAndCharges.length;
			hasStatementExportTemplate_Charges = this.props.companySettings.statementExportTemplate_Charges && this.props.companySettings.statementExportTemplate_Charges.length;
		}

		const applyAllocationsDropdown =
			<Dropdown isOpen={this.state.applyAllocationsDropdownOpen} toggle={this.onToggleApplyAllocationsDropdown}>
				<DropdownToggle
					color="primary"
					caret
				>
					Apply allocations...
				</DropdownToggle>
				<DropdownMenu>
					{hasStatementExportTemplate_Charges ? applyAllocationsDropdownItem_Charges : null}
					{hasStatementExportTemplate_Incoming ? applyAllocationsDropdownItem_Incoming : null}
					{hasStatementExportTemplate_IncomingAndCharges ? applyAllocationsDropdownItem_IncomingAndCharges : null}
					{hasStatementExportTemplate_Outgoing ? applyAllocationsDropdownItem_Outgoing : null}
					{hasStatementExportTemplate_OutgoingAndCharges ? applyAllocationsDropdownItem_OutgoingAndCharges : null}
				</DropdownMenu>
			</Dropdown>

		const hasStatementExportTemplates = hasStatementExportTemplate_Charges || hasStatementExportTemplate_Incoming || hasStatementExportTemplate_IncomingAndCharges || hasStatementExportTemplate_Outgoing || hasStatementExportTemplate_OutgoingAndCharges;
		// const hasStatementExportTemplates = false;

		const applyAllocationsControl = hasStatementExportTemplates ? applyAllocationsDropdown : applyAllocationsButton;

		const autoAllocationButton =
			<Button
				color="secondary"
				onClick={this.onAutoAllocate}
			>
				Automatically allocate
				{" "}
				{this.props.allocating ? editFormControls.buttonSpinner() : null}
			</Button>

		const revertAllocationsButton =
			<Button
				color="danger"
				onClick={this.onRevertAllAllocations}
			>
				Revert allocations
				{" "}
				{this.props.revertingAllocations ? editFormControls.buttonSpinner() : null}
			</Button>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>

					{this.state.showSelectPayment && SelectPaymentForm}
					{this.state.showSelectSupplierInvoices && SelectSupplierInvoicesForm}
					{this.state.showSelectCustomerInvoices && SelectCustomerInvoicesForm}

					<Container fluid>

						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.bankStatementRecords ?
											table
											: null}

										<div style={{ display: 'flex' }}>

											{showApplyAllocationsButton ? applyAllocationsControl : null}

											&nbsp;

											{showApplyAllocationsButton ? autoAllocationButton : null}

											&nbsp;

											{showRevertAllocationsButton ? revertAllocationsButton : null}

										</div>

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ allocation, companySettings: { companySettings } }) => ({
	applyingAllocations: allocation.applyingAllocations,
	allocatedPayments: allocation.allocatedPayments,
	allocatedSupplierInvoices: allocation.allocatedSupplierInvoices,
	allocatedCustomerInvoices: allocation.allocatedCustomerInvoices,
	allocatedBankCharges: allocation.allocatedBankCharges,
	allocating: allocation.allocating,
	bankStatementRecord: allocation.bankStatementRecord,
	bankStatementRecords: allocation.bankStatementRecords,
	companySettings: companySettings,
	error: allocation.error,
	loading: allocation.loading || !companySettings || companySettings.loading,
	revertingAllocations: allocation.revertingAllocations,
	updatingAllocations: allocation.updatingAllocations
})

const mapDispatchToProps = dispatch => ({
	onAutoAllocate: (bankStatementId, history) => dispatch(actions.allocationAutoAllocate(bankStatementId, history)),
	onApplyAllocations: (bankStatementId, filter) => dispatch(actions.allocationApply(bankStatementId, filter)),
	onGetAllocations: (id) => dispatch(actions.allocationGetAll(id)),
	onGetCompanySettings: () => dispatch(actionsCompanySettings.companySettingsGet()),
	onCreateAllocation: (allocation) => dispatch(actions.allocationCreate(allocation)),
	onDeleteAllocation: (allocation) => dispatch(actions.allocationDelete(allocation)),
	onRevertAllAllocations: (bankStatementId) => dispatch(actions.allocationRevertAll(bankStatementId)),
	onRevertAllocation: (bankStatementRecordId) => dispatch(actions.allocationRevert(bankStatementRecordId)),
	onSetAllocationRecord: (record) => dispatch(actions.allocationRecordSet(record))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(BankStatementReconcile));
