import React, { Component } from "react";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as actionsTimelogTimer from '../../store/TimelogTimer/actions';
import AddTimelogEntry from "components/Pages/AddTimelogEntry";
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import TimelogTimerStartPauseControl from "./TimelogTimerStartPauseControl";

class TimelogTimerSelectionControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // hasRunningTimer: 0,
            isOpen: false,
            skipToggleOpen: false,
            timelogTimers: [],
            frontendTimerHoursPassed: 0,
            showAddTimelogEntryForm: false,
            activeTimer: null
        }
        this.runningTimerOnFrontend = null;
    }

    componentDidMount() {
        this.props.onGetAllActiveTimers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.timelogTimers !== this.props.timelogTimers) {
            this.setState({
                timelogTimers: this.props.timelogTimers
            });
            // const startedTimers = this.props.timelogTimers.filter(timer => timer.status === "STARTED");
            // const hasRunningTimer = startedTimers && startedTimers.length;
            // this.setState({
            //     hasRunningTimer: hasRunningTimer
            // });
        }
    }

    toggleOpen = () => {
        if (this.state.skipToggleOpen) {
            this.setState({
                skipToggleOpen: false
            });
        } else {
            this.setState(prevState => ({
                isOpen: !prevState.isOpen,
            }));
        }
    }

    onChangeTimerStatus = (timer, action) => {
        this.props.onChangeTimerStatus(action, timer.taskId);
        this.setState({
            skipToggleOpen: true
        });
    }

    displayTimer = (timer) => {
        return (
            <span>
                <TimelogTimerStartPauseControl
                    hoursPassed={timer.hoursPassed}
                    timerStatus={timer.status}
                    startOrPauseTimer={() => this.startOrPauseTimer(timer)}
                    updating={this.props.updating}
                    onPauseTimer={() => this.onChangeTimerStatus(timer, "PAUSE")}
                    onStartTimer={() => this.onChangeTimerStatus(timer, "START")}
                    timerCallbackFunction={(frontendTimerHoursPassed) => this.setState({ frontendTimerHoursPassed: frontendTimerHoursPassed })}
                />
                &nbsp;
                &nbsp;
                <Link
                    to={endpointsFrontend.TASK_EDIT.replace(':id', timer.taskId)}
                >
                    {timer.taskTitle}
                </Link>
            </span>
        )
    }

    launchAddTimelogEntryForm = (timer) => {
        this.setState({
            activeTimer: timer,
            showAddTimelogEntryForm: true
        });
    }

    deleteTimer = (timer) => {
        if (window.confirm("Are you sure you want to delete this timer?")) {
            this.onChangeTimerStatus(timer, "DELETE");
        }
    }

    render() {
        const activeTimerAsArray = this.state.timelogTimers.filter(timer => timer.taskId === this.props.activeTimerTaskId);
        const activeTimer = activeTimerAsArray && activeTimerAsArray.length
            ? activeTimerAsArray[0]
            : this.state.timelogTimers && this.state.timelogTimers.length
                ? this.state.timelogTimers[0]
                : null;
        const activeTimerData = activeTimer ? this.displayTimer(activeTimer) : null;

        const LoadingSpinner = (
            <div className="spinnerbox">
                <div className="spinner">
                    {editFormControls.bigSpinner()}
                </div>
            </div>
        )

        const AddTimelogEntryForm =
            <AddTimelogEntry
                onClose={() => this.setState({ showAddTimelogEntryForm: false })}
                timer={this.state.activeTimer}
                frontendTimerHoursPassed={this.state.frontendTimerHoursPassed}
            />

        const ActiveTimerSelectionControl = (
            <Dropdown
                isOpen={!this.state.skipToggleOpen && this.state.isOpen}
                toggle={this.toggleOpen}
                className="d-inline-block"
            >
                {this.state.showAddTimelogEntryForm && AddTimelogEntryForm}

                <DropdownToggle
                    className="btn header-item"
                    tag="button"
                >
                    {activeTimerData}
                    {this.state.timelogTimers.length > 1 && <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />}
                </DropdownToggle>
                <DropdownMenu
                    className="language-switch dropdown-menu-end"
                >
                    {this.state.timelogTimers.map((timer, key) => (
                        <DropdownItem
                            key={key}
                            className={`notify-item ${this.props.activeTimerTaskId === timer.taskId ? "active" : "none"}`}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <div style={{ marginRight: 'auto' }}>
                                {this.displayTimer(timer)}
                                &nbsp;
                                &nbsp;
                            </div>
                            <Button
                                color="primary"
                                size="sm"
                                onClick={() => this.launchAddTimelogEntryForm(timer)}
                            >
                                Add entry
                            </Button>
                            &nbsp;
                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => this.deleteTimer(timer)}
                            >
                                Delete
                            </Button>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        )

        const NoActiveTimersControl = (
            <div>
            </div>
        )

        let controlToRender;
        if (this.props.loading) {
            controlToRender = LoadingSpinner;
        } else if (this.state.timelogTimers && this.state.timelogTimers.length) {
            controlToRender = ActiveTimerSelectionControl;
        } else {
            controlToRender = NoActiveTimersControl;
        }

        return (controlToRender);
    }
}

const mapStateToProps = ({ timelogTimer }) => ({
    activeTimerTaskId: timelogTimer.activeTimerTaskId,
    loading: timelogTimer.loading,
    timelogTimers: timelogTimer.timelogTimers,
    updating: timelogTimer.updating
});

const mapDispatchToProps = dispatch => ({
    onGetAllActiveTimers: () => dispatch(actionsTimelogTimer.timelogTimerFindAllActive()),
    onChangeTimerStatus: (action, taskId) => dispatch(actionsTimelogTimer.timelogTimerChangeStatus({ action, taskId })),
    onStartTimer: (taskId) => dispatch(actionsTimelogTimer.timelogTimerChangeStatus({ action: "START", taskId }))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimelogTimerSelectionControl);
