import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actions from '../../store/AllocationCriterion/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsAllocationCriterion from '../../definitions/columns/allocationCriterion';
import * as config from '../../config';
import EditAllocationCriterion from '../../components/Pages/EditAllocationCriterion';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import OverlayWithSpinner from "components/Common/OverlayWithSpinner";
import * as selectRowUtils from '../../helpers/selectRowUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class AllocationCriterionBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			allocationCriterionType: "",
			breadcrumbItem: "",
			selectedAllocationCriterion: null,
			selectedRows: [],
			showEditAllocationForm: false
		}
	}

	componentDidMount() {
		let allocationCriterionType;
		if (this.props.location.pathname === endpointsFrontend.ALLOCATION_CRITERION_BROWSE_BANK_CHARGE) {
			allocationCriterionType = "BANK_CHARGE";
			this.setState({
				breadcrumbItem: "Bank charges"
			});
		} else if (this.props.location.pathname === endpointsFrontend.ALLOCATION_CRITERION_BROWSE_CUSTOMER_INVOICE) {
			allocationCriterionType = "CUSTOMER_INVOICE";
			this.setState({
				breadcrumbItem: "Customer invoices"
			});
		} else if (this.props.location.pathname === endpointsFrontend.ALLOCATION_CRITERION_BROWSE_PAYMENT) {
			allocationCriterionType = "PAYMENT";
			this.setState({
				breadcrumbItem: "Payments"
			});
		} else if (this.props.location.pathname === endpointsFrontend.ALLOCATION_CRITERION_BROWSE_SUPPLIER_INVOICE) {
			allocationCriterionType = "SUPPLIER_INVOICE";
			this.setState({
				breadcrumbItem: "Supplier invoices"
			});
		}
		this.setState({
			allocationCriterionType: allocationCriterionType
		})
		this.props.onGetAllocationCriteria(allocationCriterionType);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.moving != this.props.moving) {
			this.setState({
				moving: this.props.moving
			});
		}
	}

	commandButtons = (cell, row, rowIndex, formatExtraData) => {
		// Could not disable or render the buttons differently on the basis of formatExtraData.movingLines, as apparently it does not get updated when needed
		return (
			<React.Fragment>
				<i
					className="bx bxs-up-arrow"
					onClick={() => {
						this.props.onMoveAllocationCriterionUp(row.id);
					}}
				/>
				{" "}
				<i
					className="bx bxs-down-arrow"
					onClick={() => {
						this.props.onMoveAllocationCriterionDown(row.id);
					}}
				/>
			</React.Fragment>
		);
	};

	newAllocationCriterion = () => {
		this.setState({
			selectedAllocationCriterion: {
				type: this.state.allocationCriterionType
			},
			showEditAllocationForm: true
		})
	}

	onDeleteAllocationCriteria = () => {
		if (window.confirm("Are you sure you want to delete the selected criteria?")) {
			this.props.onDeleteAllocationCriteria(this.state.selectedRows, this.state.allocationCriterionType);
		}
	}

	render() {

		const pageTitle = "Allocation Criteria | " + config.AppName;
		const breadcrumbsTitle = "Allocation criteria";

		const EditAllocationFormAsVar =
			<EditAllocationCriterion
				onClose={() => this.setState({ showEditAllocationForm: false })}
				allocationCriterion={this.state.selectedAllocationCriterion}
			/>

		let columns = [
			columnsAllocationCriterion.sequenceNo,
			columnsAllocationCriterion.trustLevel,
			columnsAllocationCriterion.description,
			// {
			// 	dataField: "commandButtons",
			// 	text: "",
			// 	formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, {})
			// }
		];

		const columnEvents = {
			onClick: (e, column, columnIndex, row, rowIndex) => {
				if (row.id) {
					// this.props.onSetAllocationRecord(row);
					this.setState({
						selectedAllocationCriterion: row,
						showEditAllocationForm: true
					});
				}
			}
		}

		for (let i in columns) {
			columns[i] = {
				...columns[i],
				events: columnEvents
			}
		}
		columns = columns.concat([
			{
				dataField: "commandButtons",
				text: "",
				formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, {})
			}
		]);

		const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
			},

			onSelectAll: (isSelect, rows, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
			}
		};

		const table =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.allocationCriteria}
					columns={columns}
					// rowEvents={rowEvents}
					filter={filterFactory()}
					selectRow={selectRow}
					sort={{
						dataField: 'sequenceNo',
						order: 'asc'
					}}
				/>
			</div>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>

					{this.state.showEditAllocationForm ? EditAllocationFormAsVar : null}

					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={this.state.breadcrumbItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										<div
											style={{
												position: "relative"
											}}
										>
											<OverlayWithSpinner
												show={this.state.moving}
											/>
											{!this.props.loading && this.props.allocationCriteria ? table : null}

											{editFormControls.newButton(this.props.saving, this.newAllocationCriterion)}

											{" "}

											{editFormControls.deleteButton(false, this.onDeleteAllocationCriteria, true, !this.state.selectedRows.length)}
										</div>

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ allocationCriterion }) => ({
	allocationCriteria: allocationCriterion.allocationCriteria,
	deleting: allocationCriterion.deleting,
	error: allocationCriterion.error,
	loading: allocationCriterion.loading,
	moving: allocationCriterion.moving,
	saving: allocationCriterion.saving
})

const mapDispatchToProps = dispatch => ({
	onDeleteAllocationCriteria: (idList, allocationCriterionType) => dispatch(actions.allocationCriterionDelete(idList, allocationCriterionType)),
	onMoveAllocationCriterionUp: (id) => dispatch(actions.allocationCriterionMoveUp(id)),
	onMoveAllocationCriterionDown: (id) => dispatch(actions.allocationCriterionMoveDown(id)),
	onGetAllocationCriteria: (allocationCriterionType) => dispatch(actions.allocationCriterionGetAll(allocationCriterionType))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(AllocationCriterionBrowse));
