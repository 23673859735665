import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Container, InputGroup, Row } from "reactstrap";

import * as actionsTag from '../../store/Tag/actions';
import * as actionsTask from '../../store/Task/actions';
import * as actionsTimelog from '../../store/Timelog/actions';
import * as actionsTimelogTimer from '../../store/TimelogTimer/actions';
import * as actionsUserAccount from '../../store/UserAccount/actions';
import AddTimelogEntry from "components/Pages/AddTimelogEntry";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as columnsTimelog from '../../definitions/columns/timelog';
import * as config from '../../config';
import * as dateAndTimeUtils from '../../helpers/dateAndTimeUtils';
import * as editFormControls from '../../helpers/editFormControls';
import { EditFormControlWithLabel } from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as formatUtils from '../../helpers/formatUtils';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
import { TaskStatus } from "definitions/enums/TaskStatus";
import TimelogTimerStartPauseControl from "components/CommonForBoth/TimelogTimerStartPauseControl";

import classes from './Pages.module.css';

class TaskEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            externalId: "",
            createdByUserAccountId: "",
            assignedToUserAccountId: "",
            createdOn: "",
            completedOn: "",
            taskStatus: "ACTIVE",
            startDate: "",
            dueDate: "",
            title: "",
            description: "",
            createdByUserAccountFirstName: "",
            createdByUserAccountLastName: "",
            createdByUserAccountUsername: "",
            assignedToUserAccountFirstName: "",
            assignedToUserAccountLastName: "",
            assignedToUserAccountUsername: "",
            totalHoursLogged: 0,
            isStarred: false,
            tags: [],

            hoveredTag: null,
            showAddNewTag: false,
            tagSearchInput: "",
            tasks: [],
            changed: false,
            frontendTimerHoursPassed: 0,
            showAddTimelogEntryForm: false,
            showTimelog: false,
            errors: {},
            timelogTimers: [],
            crmLexicalEditorInitialScrollUp: false
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        const thisObjectType = e.target.type;
        let thisValue;
        if (thisObjectType == "checkbox") {
            thisValue = e.target.checked;
        } else if (thisObjectType === "crm-lexical-editor") {
            thisValue = this.visualizeTask(e.target.value);
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            changed: true,
            [thisObjectName]: thisValue
        });
        // After all components have been loaded, crmLexicalEditor gets updated with a new value, which causes its own onChange() to get fired 
        // and the browser then scrolls down to the end of crmLexicalEitor. 
        // To workaround it, we scroll up after crmLexicalEditor's onChange() has been fired for the first time.
        if (thisObjectType === "crm-lexical-editor" && !this.state.crmLexicalEditorInitialScrollUp) {
            window.scrollBy(0, -document.body.scrollHeight);
            this.setState({
                crmLexicalEditorInitialScrollUp: true
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedTask = {
            externalId: this.state.externalId,
            createdByUserAccountId: this.state.createdByUserAccountId,
            assignedToUserAccountId: this.state.assignedToUserAccountId,
            createdOn: this.state.createdOn,
            completedOn: this.state.completedOn,
            taskStatus: this.state.taskStatus,
            startDate: this.state.startDate,
            dueDate: this.state.dueDate,
            title: this.state.title,
            description: this.state.description,
            createdByUserAccountFirstName: this.state.createdByUserAccountFirstName,
            createdByUserAccountLastName: this.state.createdByUserAccountLastName,
            createdByUserAccountUsername: this.state.createdByUserAccountUsername,
            assignedToUserAccountFirstName: this.state.assignedToUserAccountFirstName,
            assignedToUserAccountLastName: this.state.assignedToUserAccountLastName,
            assignedToUserAccountUsername: this.state.assignedToUserAccountUsername,
            isStarred: this.state.isStarred,
            tags: this.state.tags
        }
        if (this.state.id) {
            newOrUpdatedTask = {
                id: this.state.id,
                ...newOrUpdatedTask
            }
        }
        this.setState({
            changed: false
        }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
        this.props.onCreateTask(newOrUpdatedTask, this.props.history);
    }

    visualizeTask = (htmlString) => {
        // const taskLinkBase = [
        //     "https://app.cashmanager.lv" + endpointsFrontend.TASK_EDIT.replace(":id", ""),
        //     "https://app.garciems.lv" + endpointsFrontend.TASK_EDIT.replace(":id", ""),
        //     "http://localhost:3000" + endpointsFrontend.TASK_EDIT.replace(":id", "")
        // ];

        // console.log("visualize")
        // for (let i in taskLinkBase) {
        //     if (htmlString.includes(taskLinkBase[i])) {
        //         const regexPattern = '<a href="' + taskLinkBase[i] + '.*?>(.*?)<\/a>';
        //         console.log(htmlString.match(regexPattern));
        //     }
        // }

        return htmlString;
    }

    onDeleteTask = () => {
        if (window.confirm("Are you sure you want to delete this Task?")) {
            this.props.onDeleteTask(this.state.id, this.props.history);
        }
    }

    getTaskData = () => {
        const { id } = this.props.match.params;
        if (id) {
            this.props.onGetTaskById(id);
        }
    }

    getTimelogData = () => {
        const { id } = this.props.match.params;
        if (id) {
            this.props.onGetTimelogsByTaskId(id);
        }
    }

    componentDidMount() {
        this.props.onGetUserAccounts();
        this.props.onGetTags();
        this.getTaskData();
        // this.getTimelogData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.getTaskData();
            // this.getTimelogData();
        }

        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.setState({
                    errors: this.props.error
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

        if (prevProps.tasks !== this.props.tasks) {
            if (this.props.tasks && this.props.tasks[0]) {

                this.setState({
                    id: this.props.tasks[0].id,
                    externalId: this.props.tasks[0].externalId,
                    createdByUserAccountId: this.props.tasks[0].createdByUserAccountId,
                    assignedToUserAccountId: this.props.tasks[0].assignedToUserAccountId,
                    createdOn: this.props.tasks[0].createdOn,
                    completedOn: this.props.tasks[0].completedOn,
                    taskStatus: this.props.tasks[0].taskStatus,
                    startDate: this.props.tasks[0].startDate,
                    dueDate: this.props.tasks[0].dueDate,
                    title: this.props.tasks[0].title,
                    description: this.props.tasks[0].description,
                    createdByUserAccountFirstName: this.props.tasks[0].createdByUserAccountFirstName,
                    createdByUserAccountLastName: this.props.tasks[0].createdByUserAccountLastName,
                    createdByUserAccountUsername: this.props.tasks[0].createdByUserAccountUsername,
                    assignedToUserAccountFirstName: this.props.tasks[0].assignedToUserAccountFirstName,
                    assignedToUserAccountLastName: this.props.tasks[0].assignedToUserAccountLastName,
                    assignedToUserAccountUsername: this.props.tasks[0].assignedToUserAccountUsername,
                    totalHoursLogged: this.props.tasks[0].totalHoursLogged,
                    isStarred: this.props.tasks[0].isStarred,
                    tags: this.props.tasks[0].tags
                });
                // } else {
                //     this.setState({
                //         id: "",
                //         externalId: "",
                //         createdByUserAccountId: "",
                //         assignedToUserAccountId: "",
                //         createdOn: "",
                //         completedOn: "",
                //         taskStatus: "",
                //         startDate: "",
                //         dueDate: "",
                //         title: "",
                //         description: "",
                //         createdByUserAccountFirstName: "",
                //         createdByUserAccountLastName: "",
                //         createdByUserAccountUsername: "",
                //         assignedToUserAccountFirstName: "",
                //         assignedToUserAccountLastName: "",
                //         assignedToUserAccountUsername: "",
                //         totalHoursLogged: 0,
                //         isStarred: "",
                //         tags: []
                //     });
            }
        }

        if (prevProps.timelogTimers !== this.props.timelogTimers) {
            this.setState({
                timelogTimers: this.props.timelogTimers
            });
        }

        if (prevProps.timelogs !== this.props.timelogs && this.props.timelogs && this.props.timelogs.length) {
            let totalHoursLogged = 0;
            this.props.timelogs.map(timelog => totalHoursLogged += timelog.hoursLogged);
            this.setState({
                totalHoursLogged: totalHoursLogged
            })
            // if (this.state.showTimelog) {
            //     window.scrollBy(0, document.body.scrollHeight);
            // }
        }

        if (prevState.startDate != this.state.startDate && this.state.startDate) {
            if (this.state.dueDate && this.state.dueDate < this.state.startDate) {
                this.setState({
                    dueDate: this.state.startDate
                })
            }
        }

        if (prevState.dueDate != this.state.dueDate && this.state.dueDate) {
            if (this.state.startDate && this.state.dueDate < this.state.startDate) {
                this.setState({
                    startDate: this.state.dueDate
                })
            }
        }

        if (!prevState.showAddNewTag && this.state.showAddNewTag) {
            const tagSearchInput = document.getElementById("tagSearchInput");
            if (tagSearchInput) {
                tagSearchInput.focus();
            }
        }

        if ((prevProps.saveSuccess !== this.props.saveSuccess) || (prevProps.error !== this.props.error)) {
            window.scrollBy(0, -document.body.scrollHeight);
        }
    }

    toggleShowTimelog = () => {
        const showTimelog = !this.state.showTimelog;
        this.setState({
            showTimelog: showTimelog
        });
        if (showTimelog) {
            this.getTimelogData();
        }
    }

    toggleShowAddNewTag = () => {
        const showAddNewTag = !this.state.showAddNewTag;
        this.setState({
            showAddNewTag
        });
    }

    addTag = (tag) => {
        const tags = this.state.tags;
        tags.push(tag);

        this.setState({
            tags
        });
    }

    removeTag = (tag) => {
        const tags = this.state.tags.filter(filteredTag => filteredTag.id !== tag.id);

        this.setState({
            tags
        });
    }

    render() {
        const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " Task | " + config.AppName;
        const breadcrumbsTitle = "Task";
        const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " Task";

        const totalHoursLoggedAsInterval = dateAndTimeUtils.hoursToTimeInterval(this.state.totalHoursLogged);
        const totalHoursLoggedFormatted = totalHoursLoggedAsInterval.hours + ":" + formatUtils.formatNumberWith2Digits(totalHoursLoggedAsInterval.minutes) + ":" + formatUtils.formatNumberWith2Digits(totalHoursLoggedAsInterval.seconds);

        const taskTags = this.state.tags ? this.state.tags : [];
        const alreadyIncludedTagIds = taskTags.map(tag => tag.id);
        const tagsExceptAlreadyIncluded = this.props.tags && this.props.tags.filter(tag => !alreadyIncludedTagIds.includes(tag.id));

        const tagControls =
            <React.Fragment>
                {taskTags.map(
                    tag => {
                        let tagClassName = this.state.hoveredTag === tag.id ? "badge-soft-danger" : "badge-soft-secondary";
                        return (
                            <React.Fragment>
                                <span
                                    key={tag.id}
                                >
                                    <span
                                        className={"badge " + tagClassName + " font-size-12"}
                                        onMouseEnter={() => this.setState({ hoveredTag: tag.id })}
                                        onMouseLeave={() => this.setState({ hoveredTag: null })}
                                        onClick={() => this.removeTag(tag)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {tag.title.includes("/") && this.state.hoveredTag !== tag.id ? tag.title.substring(tag.title.lastIndexOf("/") + 1) : tag.title}
                                    </span>
                                    &nbsp;
                                    {" "}
                                </span>
                            </React.Fragment>)
                    }
                )}
                <span
                    style={{ cursor: "pointer" }}
                    onClick={this.toggleShowAddNewTag}
                >
                    +
                </span>
                {this.state.showAddNewTag &&
                    <div
                        className={classes.Background}
                        onClick={this.toggleShowAddNewTag}
                    >
                        <div
                            className={classes.TagSelectionContainer}
                        >
                            <Card
                                style={{ height: "100%" }}
                            >
                                <CardBody
                                    style={{ height: "100%" }}
                                >
                                    <input
                                        id="tagSearchInput"
                                        name="tagSearchInput"
                                        type="text"
                                        onChange={this.onChange}
                                        value={this.state.tagSearchInput}
                                        style={{ width: "100%" }}
                                    />
                                    <div
                                        className={classes.TagSelectionList}
                                    >
                                        {tagsExceptAlreadyIncluded.filter(tag => tag.title && tag.title.toLocaleLowerCase().includes(this.state.tagSearchInput.toLocaleLowerCase())).map(tag => (
                                            <div
                                                key={tag.id}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => this.addTag(tag)}
                                            >
                                                {tag.title}
                                            </div>
                                        ))}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>}
            </React.Fragment>

        let timer = {
            hoursPassed: 0,
            status: "PAUSED",
            taskId: this.state.id
        };
        this.state.timelogTimers.map(timelogTimer => {
            if (timelogTimer.taskId === this.state.id) {
                timer = timelogTimer;
            }
        });

        const columns = [
            columnsTimelog.date,
            columnsTimelog.hoursLogged,
            columnsTimelog.userLoggedInfo,
            columnsTimelog.comment
        ];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.props.history.push(endpointsFrontend.TIMELOG_EDIT.replace(":id", row.id));
            }
        };

        const table =
            <div className="mt-3">
                <BootstrapTable
                    keyField='id'
                    data={this.props.timelogs}
                    columns={columns}
                    pagination={paginationFactory()}
                    rowEvents={rowEvents}
                    rowStyle={{ cursor: "pointer" }}
                    filter={filterFactory()}
                    sort={{
                        dataField: 'date',
                        order: 'desc'
                    }}
                />
            </div>

        const userAccountOptionsPlusNull = inputSelectUtils.addNullOption(inputSelectUtils.generateOptionsFromData(this.props.userAccounts, userAccountRow => (
            userAccountRow.firstName ? (
                userAccountRow.lastName ? (
                    userAccountRow.firstName + " " + userAccountRow.lastName + " <" + userAccountRow.username + ">"
                ) :
                    userAccountRow.firstName + " <" + userAccountRow.username + ">"
            ) :
                userAccountRow.username
        )));

        const taskStatusOptions = inputSelectUtils.generateOptionsFromData(TaskStatus, row => row.description);

        const AddTimelogEntryForm =
            <AddTimelogEntry
                onClose={() => this.setState({ showAddTimelogEntryForm: false })}
                timer={timer}
                frontendTimerHoursPassed={this.state.frontendTimerHoursPassed}
            />

        const timelogControls =
            <React.Fragment>
                <div>
                    <Button
                        type="button"
                        size="sm"
                        color="secondary"
                        onClick={this.toggleShowTimelog}
                    >
                        {this.props.loadingTimelogs && editFormControls.buttonSpinner()}
                        &nbsp;
                        {(this.state.showTimelog ? "Hide" : "Show") + " timelog"}
                    </Button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <TimelogTimerStartPauseControl
                        hoursPassed={timer.hoursPassed}
                        timerStatus={timer.status}
                        startOrPauseTimer={() => this.startOrPauseTimer(timer)}
                        updating={this.props.updating}
                        onPauseTimer={() => this.props.onChangeTimerStatus("PAUSE", this.state.id)}
                        onStartTimer={() => this.props.onChangeTimerStatus("START", this.state.id)}
                        timerCallbackFunction={(frontendTimerHoursPassed) => this.setState({ frontendTimerHoursPassed: frontendTimerHoursPassed })}
                    />
                    &nbsp;
                    /
                    &nbsp;
                    {totalHoursLoggedFormatted}
                    &nbsp;
                    &nbsp;
                    <Button
                        color="primary"
                        size="sm"
                        onClick={() => this.setState({ showAddTimelogEntryForm: true })}
                    >
                        Add entry
                    </Button>
                </div>
                {this.props.timelogs && this.state.showTimelog ? table : <br />}
                <br />
            </React.Fragment>

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                        <InputGroup>
                                            {editFormControls.textControlWithoutLabel("title", this.onChange, this.state.title, "Task title")}
                                            <Button
                                                color={this.state.isStarred ? "primary" : "secondary"}
                                                onClick={() => this.setState({ isStarred: !this.state.isStarred })}
                                            >
                                                <i className={"bx " + (this.state.isStarred ? "bxs-star" : "bx-star")} />
                                            </Button>
                                        </InputGroup>
                                        <br />
                                        {tagControls}
                                        <br />
                                        <br />
                                        {editFormControls.selectControl("assignedToUserAccountId", "Assigned to", this.onChange, this.state.assignedToUserAccountId, userAccountOptionsPlusNull)}
                                        {editFormControls.selectControl("taskStatus", "Status", this.onChange, this.state.taskStatus, taskStatusOptions)}
                                        {editFormControls.dateControl("startDate", "Start date", this.onChange, this.state.startDate)}
                                        {editFormControls.dateControl("dueDate", "Due date", this.onChange, this.state.dueDate)}

                                    </div>
                                </div>

                                {this.state.id ? timelogControls : null}

                                {editFormControls.richTextControlWithoutLabel("description", "Description", this.onChange, this.state.description)}
                                {/*editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)*/}

                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                        <span> </span>
                                        {editFormControls.deleteButton(this.props.deleting, this.onDeleteTask, this.state.id)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        const loading = this.props.loading || this.props.loadingUserAccounts;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>

                    {this.state.showAddTimelogEntryForm && AddTimelogEntryForm}

                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.props.error)}
                        {editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "Task", "Browse tasks", endpointsFrontend.TASK_BROWSE)}
                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading ? editForm : null}

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ tag, task, timelog, timelogTimer, userAccount }) => ({
    error: task.error,
    deleting: task.deleting,
    loading: task.loading,
    loadingTimelogs: timelog.loading,
    loadingUserAccounts: userAccount.loading,
    saveSuccess: task.saveSuccess,
    saving: task.saving,
    tags: tag.tags,
    tasks: task.tasks,
    timelogs: timelog.timelogs,
    timelogTimers: timelogTimer.timelogTimers,
    updating: timelogTimer.updating,
    userAccounts: userAccount.userAccounts
})

const mapDispatchToProps = dispatch => ({
    onChangeTimerStatus: (action, taskId) => dispatch(actionsTimelogTimer.timelogTimerChangeStatus({ action, taskId })),
    onCreateTask: (task, history) => dispatch(actionsTask.taskCreate(task, history)),
    onGetTags: (id) => dispatch(actionsTag.tagGetAll(id)),
    onGetTaskById: (id) => dispatch(actionsTask.taskGetById(id)),
    onGetTimelogsByTaskId: (taskId) => dispatch(actionsTimelog.timelogGetByTask(taskId)),
    onGetUserAccounts: () => dispatch(actionsUserAccount.userAccountGetAll()),
    onDeleteTask: (id, history) => dispatch(actionsTask.taskDelete(id, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskEdit);
