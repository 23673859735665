import * as formatUtils from 'helpers/formatUtils';

export const criterionId = {
	dataField: 'criterion.id',
	text: 'Criterion',
	formatter: (cell, row, rowIndex) => formatUtils.formatAllocationCriterion(row.criterion, rowIndex)
};
export const customerName = {
	dataField: 'customerName',
	text: 'Customer',
};
export const invoiceNo = {
	dataField: 'invoiceNo',
	text: 'Invoice No.',
};
export const currencyCode = {
	dataField: 'currencyCode',
	text: 'Currency',
};
export const amountWithVAT = {
	dataField: 'amountWithVAT',
	text: 'Invoice amount',
	align: "right",
	headerStyle: { textAlign: "right" },
	formatter: (cell, row) => formatUtils.formatAmountWithDCSign(cell)

};
export const allocatedAmountInfo = {
	dataField: 'allocatedAmountInfo',
	text: 'Allocated amount',
	align: "right",
	headerStyle: { textAlign: "right" },
};