import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from '../../helpers/sagaHelper';

function* createScheduledTaskSaga({ scheduledTask }) {
    try {
        let response;
        if (scheduledTask.type === "BANK_FILE_RECEIVE") {
            response = yield call(
                rest.post,
                endpointsBackend.SCHEDULED_BANK_FILE_RECEIVE_CREATE_OR_UPDATE,
                scheduledTask
            );
        } else if (scheduledTask.type === "EXTERNAL_DATA_IMPORT_REQUEST") {
            response = yield call(
                rest.post,
                endpointsBackend.SCHEDULED_EXTERNAL_DATA_IMPORT_REQUEST_CREATE_OR_UPDATE,
                scheduledTask
            );
        } else {
            response = yield call(
                rest.post,
                endpointsBackend.SCHEDULED_STATEMENT_REQUEST_CREATE_OR_UPDATE,
                scheduledTask
            );
        }
        yield put(actions.scheduledTaskCreateSuccess(response.data));
    } catch (e) {
        yield put(actions.scheduledTaskCreateError(e));
    }
}

function* deleteScheduledTaskSaga({ idList }) {
    try {
        const response = yield call(
            rest.del,
            endpointsBackend.SCHEDULED_TASK_GET_ALL,
            { idList: idList }
        );
        yield put(actions.scheduledTaskDeleteSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
    } catch (e) {
        yield put(actions.scheduledTaskDeleteError(e));
    }
}

function* executeScheduledTaskSaga({ id }) {
    try {
        yield call(
            rest.post,
            endpointsBackend.SCHEDULED_TASK_EXECUTE.replace("{scheduledTaskId}", id)
        );
        yield put(actions.scheduledTaskExecuteSuccess());
    } catch (e) {
        yield put(actions.scheduledTaskExecuteError(e));
    }
    yield call(getAllScheduledTasksSaga);   // Regardless how the task finished, we need to refresh its last execution data on the frontend
}

function* getAllScheduledTasksSaga() {
    try {
        const response = yield call(
            rest.get,
            endpointsBackend.SCHEDULED_TASK_GET_ALL
        );

        yield put(actions.scheduledTaskGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
    } catch (e) {
        yield put(actions.scheduledTaskGetError(e));
    }
}

function* getScheduledTaskByIdSaga({ id }) {
    try {
        const response = yield call(
            rest.get,
            endpointsBackend.SCHEDULED_TASK_GET_BY_ID.replace("{scheduledTaskId}", id)
        );

        yield put(actions.scheduledTaskGetSuccess([{ ...response.data }]));
    } catch (e) {
        yield put(actions.scheduledTaskGetError(e));
    }
}

function* scheduledTaskSaga() {
    yield (takeLatest(actionTypes.SCHEDULED_TASK_CREATE, createScheduledTaskSaga));
    yield (takeLatest(actionTypes.SCHEDULED_TASK_DELETE, deleteScheduledTaskSaga));
    yield (takeLatest(actionTypes.SCHEDULED_TASK_EXECUTE, executeScheduledTaskSaga));
    yield (takeLatest(actionTypes.SCHEDULED_TASK_GET_ALL, getAllScheduledTasksSaga));
    yield (takeLatest(actionTypes.SCHEDULED_TASK_GET_BY_ID, getScheduledTaskByIdSaga));
}

export default scheduledTaskSaga;