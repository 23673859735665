import cronParser from 'cron-parser';

import * as formatUtils from './formatUtils';

export const parseCronExpression = (cronExpression) => {
    const interval = cronParser.parseExpression(cronExpression);

    let scheduleType;
    let selectedDays_Mon = false;
    let selectedDays_Tue = false;
    let selectedDays_Wed = false;
    let selectedDays_Thu = false;
    let selectedDays_Fri = false;
    let selectedDays_Sat = false;
    let selectedDays_Sun = false;
    let selectedTime = '';
    let regularityInHours = 0;
    let regularityInDays = 0;
    let nextExecutionTime = formatUtils.formatNamedFieldsDatetimeObject(interval.next()._date.c);

    if (interval.fields.hour && interval.fields.hour.length && interval.fields.minute && interval.fields.minute.length) {
        selectedTime = formatUtils.formatNumberWith2Digits(interval.fields.hour[0]) + ":" + formatUtils.formatNumberWith2Digits(interval.fields.minute[0]);
    }

    const cronExpressionParts = cronExpression.split(' ');
    if (cronExpressionParts[2].includes('/')) {
        regularityInHours = cronExpressionParts[2].split('/')[1];
    }
    if (cronExpressionParts[3].includes('/')) {
        regularityInDays = cronExpressionParts[3].split('/')[1];
    }

    if (regularityInHours) {
        scheduleType = 'hourly';
    } else if (regularityInDays) {
        scheduleType = 'daily';
    } else if (interval.fields.dayOfWeek && interval.fields.dayOfWeek.length < 7) {
        scheduleType = 'weekly';
    } else if (interval.fields.dayOfMonth && interval.fields.dayOfMonth.length < 31) {
        scheduleType = 'monthly';
    } else {
        scheduleType = 'daily';
    }

    for (let i in interval.fields.dayOfWeek) {
        switch (interval.fields.dayOfWeek[i]) {
            case 1: selectedDays_Mon = true; break;
            case 2: selectedDays_Tue = true; break;
            case 3: selectedDays_Wed = true; break;
            case 4: selectedDays_Thu = true; break;
            case 5: selectedDays_Fri = true; break;
            case 6: selectedDays_Sat = true; break;
            case 0: selectedDays_Sun = true; break;
        }
    }

    return {
        interval,
        scheduleType,
        selectedDays_Mon,
        selectedDays_Tue,
        selectedDays_Wed,
        selectedDays_Thu,
        selectedDays_Fri,
        selectedDays_Sat,
        selectedDays_Sun,
        selectedTime,
        regularityInHours,
        regularityInDays,
        nextExecutionTime
    };
}

function translateSingleValue(value, translateFn) {
    // Translate a single value (weekday or range) using the provided translation function
    if (value.includes('-')) {
        // Handle ranges
        const rangeValues = value.split('-');
        return translateFn(rangeValues[0]) + '-' + translateFn(rangeValues[1]);
    } else {
        // Handle single values
        return translateFn(value);
    }
}

function translateRanges(inputString) {
    // Regular expression to match dash-separated ranges
    const rangeRegex = /(\d+)-(\d+)/g;

    // Replace dash-separated ranges with comma-separated values
    const translatedString = inputString.replace(rangeRegex, (match, start, end) => {
        const startNum = parseInt(start, 10);
        const endNum = parseInt(end, 10);

        // Ensure that start and end are valid numbers between 1 and 7
        if (startNum >= 1 && startNum <= 7 && endNum >= 1 && endNum <= 7) {
            // Generate the comma-separated values within the range
            const rangeValues = Array.from({ length: endNum - startNum + 1 }, (_, index) => startNum + index);
            return rangeValues.join(',');
        } else {
            // Return the original match if the range is invalid
            return match;
        }
    });

    return translatedString;
}

export function frontendToBackend(expression) {
    const parts = expression.split(' ');

    if (parts[5] === "?" || parts[5] === "*") {
        return expression;
    } else {
        // Translate the frontend weekday specification to backend format
        const frontendWeekdays = translateRanges(parts[5]).split(',');
        const backendWeekdays = frontendWeekdays.map(value => translateSingleValue(value, frontendToBackendTranslate));

        // Replace the original frontend weekday with the translated backend values
        parts[5] = backendWeekdays.join(',');

        // Join the parts back into a cron expression
        const translatedExpression = parts.join(' ');

        return translatedExpression;
    }
}

export function backendToFrontend(expression) {
    const parts = expression.split(' ');

    if (parts[5] === "?" || parts[5] === "*") {
        return expression;
    } else {
        // Translate the backend weekday specification to frontend format
        const backendWeekdays = translateRanges(parts[5]).split(',');
        const frontendWeekdays = backendWeekdays.map(value => translateSingleValue(value, backendToFrontendTranslate));

        // Replace the original backend weekday with the translated frontend values
        parts[5] = frontendWeekdays.join(',');

        // Join the parts back into a cron expression
        const translatedExpression = parts.join(' ');

        return translatedExpression;
    }
}

function frontendToBackendTranslate(frontendValue) {
    // Translate a single frontend weekday value to backend format
    const frontendWeekday = parseInt(frontendValue, 10);
    const backendWeekday = frontendWeekday === 7 ? 1 : frontendWeekday + 1;
    return backendWeekday.toString();
}

function backendToFrontendTranslate(backendValue) {
    // Translate a single backend weekday value to frontend format
    const backendWeekday = parseInt(backendValue, 10);
    const frontendWeekday = backendWeekday === 1 ? 7 : backendWeekday - 1;
    return frontendWeekday.toString();
}
