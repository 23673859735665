import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as allocationDataTools from "../../helpers/allocationDataTools";
import * as downloadHelper from '../../helpers/downloadHelper';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as formatUtils from "helpers/formatUtils";
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaHelper";

function* autoAllocateSaga({ bankStatementId, history }) {
	// try {
		yield fork(
			rest.post,
			endpointsBackend.ALLOCATION_CREATE_AUTO.replace("{bankStatementId}", bankStatementId)
		);
		yield fork(
			history.push,
			endpointsFrontend.BANK_STATEMENT_BROWSE
		);
	// } catch (e) {

	// }
}

function* applyAllocationsSaga({ bankStatementId, filter }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.ALLOCATION_APPLY,
			{
				bankStatementId: bankStatementId,
				filter: filter,
				decimalSeparator: formatUtils.detectDecimalSeparator()
			}
		);
		
		downloadHelper.downloadHelper(response);
		const transformedAllocationDTOList = allocationDataTools.transformAllocationDTOList(response.data.arrayOfAllocationDTO);

		yield put(actions.allocationApplySuccess(
			transformedAllocationDTOList.bankStatementRecords, 
			transformedAllocationDTOList.allocatedPayments,
			transformedAllocationDTOList.allocatedSupplierInvoices,
			transformedAllocationDTOList.allocatedCustomerInvoices,
			transformedAllocationDTOList.allocatedBankCharges));
	} catch (e) {
		yield put(actions.allocationApplyError(e));
	}
}

function* createAllocationSaga({ allocation }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.ALLOCATION_CREATE,
			allocation
		);
		yield put(actions.allocationCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.allocationCreateError(e));
	}
}

function* deleteAllocationSaga({ allocation }) {
	try {
		const response = yield call(
			rest.del,
			endpointsBackend.ALLOCATION_CREATE,
			allocation
		);
		yield put(actions.allocationDeleteSuccess(response.data));
	} catch (e) {
		yield put(actions.allocationDeleteError(e));
	}
}

function* getAllAllocationsSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.ALLOCATION_GET_BY_STATEMENT.replace("{bankStatementId}", id)
		);

		const transformedAllocationDTOList = allocationDataTools.transformAllocationDTOList(response.data);

		yield put(actions.allocationGetSuccess(
			transformedAllocationDTOList.bankStatementRecords, 
			transformedAllocationDTOList.allocatedPayments,
			transformedAllocationDTOList.allocatedSupplierInvoices,
			transformedAllocationDTOList.allocatedCustomerInvoices,
			transformedAllocationDTOList.allocatedBankCharges));
	} catch (e) {
		yield put(actions.allocationGetError(e));
	}
}

function* getAllocationsByCustomerInvoiceSaga({ customerInvoiceId }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.ALLOCATION_GET_BY_CUSTOMER_INVOICE.replace("{customerInvoiceId}", customerInvoiceId)
		);

		yield put(actions.allocationGetSuccess(
			sagaHelper.getEntriesFromResponseData(response.data), 
			null,
			null,
			null,
			null));
	} catch (e) {
		yield put(actions.allocationGetError(e));
	}
}

function* revertAllAllocationsSaga({ bankStatementId }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.ALLOCATION_REVERT_ALL.replace("{bankStatementId}", bankStatementId)
		);
		
		const transformedAllocationDTOList = allocationDataTools.transformAllocationDTOList(response.data);

		yield put(actions.allocationRevertAllSuccess(
			transformedAllocationDTOList.bankStatementRecords, 
			transformedAllocationDTOList.allocatedPayments,
			transformedAllocationDTOList.allocatedSupplierInvoices,
			transformedAllocationDTOList.allocatedCustomerInvoices,
			transformedAllocationDTOList.allocatedBankCharges));
	} catch (e) {
		yield put(actions.allocationRevertAllError(e));
	}
}

function* revertAllocationSaga({ bankStatementRecordId }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.ALLOCATION_REVERT_BY_ID.replace("{bankStatementRecordId}", bankStatementRecordId)
		);
		yield put(actions.allocationRevertSuccess(response.data));
	} catch (e) {
		yield put(actions.allocationRevertError(e));
	}
}

function* allocationSaga() {
	yield (takeLatest(actionTypes.ALLOCATION_AUTO_ALLOCATE, autoAllocateSaga));
	yield (takeLatest(actionTypes.ALLOCATION_APPLY, applyAllocationsSaga));
	yield (takeLatest(actionTypes.ALLOCATION_CREATE, createAllocationSaga));
	yield (takeLatest(actionTypes.ALLOCATION_DELETE, deleteAllocationSaga));
	yield (takeLatest(actionTypes.ALLOCATION_GET_ALL, getAllAllocationsSaga));
	yield (takeLatest(actionTypes.ALLOCATION_GET_BY_CUSTOMER_INVOICE, getAllocationsByCustomerInvoiceSaga));
	yield (takeLatest(actionTypes.ALLOCATION_REVERT, revertAllocationSaga));
	yield (takeLatest(actionTypes.ALLOCATION_REVERT_ALL, revertAllAllocationsSaga));
}

export default allocationSaga;	