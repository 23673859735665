export const EXTERNAL_DATA_SYNC_REQUEST_CREATE = "EXTERNAL_DATA_SYNC_REQUEST_CREATE";
export const EXTERNAL_DATA_SYNC_REQUEST_CREATE_ERROR = "EXTERNAL_DATA_SYNC_REQUEST_CREATE_ERROR";
export const EXTERNAL_DATA_SYNC_REQUEST_CREATE_SUCCESS = "EXTERNAL_DATA_SYNC_REQUEST_CREATE_SUCCESS";

export const EXTERNAL_DATA_SYNC_REQUEST_DELETE = "EXTERNAL_DATA_SYNC_REQUEST_DELETE";
export const EXTERNAL_DATA_SYNC_REQUEST_DELETE_ERROR = "EXTERNAL_DATA_SYNC_REQUEST_DELETE_ERROR";
export const EXTERNAL_DATA_SYNC_REQUEST_DELETE_SUCCESS = "EXTERNAL_DATA_SYNC_REQUEST_DELETE_SUCCESS";

export const EXTERNAL_DATA_SYNC_REQUEST_GET_ALL = "EXTERNAL_DATA_SYNC_REQUEST_GET_ALL";
export const EXTERNAL_DATA_SYNC_REQUEST_GET_ALL_WITHOUT_SPINNER = "EXTERNAL_DATA_SYNC_REQUEST_GET_ALL_WITHOUT_SPINNER";
export const EXTERNAL_DATA_SYNC_REQUEST_GET_ERROR = "EXTERNAL_DATA_SYNC_REQUEST_GET_ERROR";
export const EXTERNAL_DATA_SYNC_REQUEST_GET_SUCCESS = "EXTERNAL_DATA_SYNC_REQUEST_GET_SUCCESS";
