import * as actionTypes from "./actionTypes";

export const counterGetAll = () => {
	return {
		type: actionTypes.COUNTER_GET_ALL
	}
}

export const counterGetAndUpdate = (counterType) => {
	return {
		type: actionTypes.COUNTER_GET_AND_UPDATE,
		counterType: counterType
	}
}

export const counterGetById = (id) => {
	return {
		type: actionTypes.COUNTER_GET_BY_ID,
		id: id
	}
}

export const counterGetInProcess = () => {
	return {
		type: actionTypes.COUNTER_GET_IN_PROCESS
	}
}

export const counterGetValueInProcess = () => {
	return {
		type: actionTypes.COUNTER_GET_VALUE_IN_PROCESS
	}
}

export const counterGetError = (thisError) => {
	return {
		type: actionTypes.COUNTER_GET_ERROR,
		error: thisError
	}
}

export const counterGetValueError = (thisError) => {
	return {
		type: actionTypes.COUNTER_GET_VALUE_ERROR,
		error: thisError
	}
}

export const counterGetSuccess = (data) => {
	return {
		type: actionTypes.COUNTER_GET_SUCCESS,
		Counters: data
	}
}

export const counterGetValueSuccess = (value) => {
	return {
		type: actionTypes.COUNTER_GET_VALUE_SUCCESS,
		value: value
	}
}

export const counterDelete = (id, history) => {
	return {
		type: actionTypes.COUNTER_DELETE,
		id: id,
		history: history
	}
}

export const counterDeleteError = (thisError) => {
	return {
		type: actionTypes.COUNTER_DELETE_ERROR,
		error: thisError
	}
}

