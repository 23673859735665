import React, { Component } from "react";

import "./TimelogTimerControl.css";
import * as dateAndTimeUtils from '../../helpers/dateAndTimeUtils';
import * as editFormControls from '../../helpers/editFormControls';
import * as formatUtils from '../../helpers/formatUtils';

class TimelogTimerStartPauseControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frontendTimerHoursPassed: 0,
        }
        this.frontendTimer = null;
    }

    setRunningTimer = () => {
        this.setState({
            frontendTimerHoursPassed: 0
        });
        this.frontendTimer = setInterval(() => {
            const frontendTimerHoursPassed = this.state.frontendTimerHoursPassed + 1 / 3600;
            this.setState({
                frontendTimerHoursPassed: frontendTimerHoursPassed
            });
            if (this.props.timerCallbackFunction) {
                this.props.timerCallbackFunction(frontendTimerHoursPassed);
            }
        }, 1000);
    }

    clearRunningTimer = () => {
        this.setState({
            frontendTimerHoursPassed: 0
        });
        clearInterval(this.frontendTimer);
    }

    setTimerIfApplicable() {
        if (this.props.timerStatus === "STARTED") {
            this.setRunningTimer();
        } else {
            this.clearRunningTimer();
        }
    }

    componentDidMount() {
        this.setTimerIfApplicable();
    }

    componentWillUnmount() {
        this.clearRunningTimer();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.timerStatus !== this.props.timerStatus) {
            this.setTimerIfApplicable();
        }
    }

    startOrPauseTimer = () => {
        switch (this.props.timerStatus) {
            case "PAUSED":
                this.props.onStartTimer();
                break;
            case "STARTED":
                this.props.onPauseTimer();
                break;
        }
    }

    render() {
        const iconControl =
            <i
                className={"bx " + (this.props.timerStatus === "STARTED" ? "bx-pause" : "bx-play")}
                onClick={this.startOrPauseTimer}
                style={{ cursor: "pointer" }}
            />
        const hoursPassed = this.props.hoursPassed ? this.props.hoursPassed : 0;
        const timeInterval = dateAndTimeUtils.hoursToTimeInterval(hoursPassed + this.state.frontendTimerHoursPassed);
        const colon =
            <span
                className={this.props.timerStatus === "STARTED" ? "blinkingFont" : "normalFont"}
            >
                :
            </span>
        const timeIntervalFormatted =
            <React.Fragment>
                &nbsp;
                {timeInterval.hours}
                {colon}
                {formatUtils.formatNumberWith2Digits(timeInterval.minutes)}
                {colon}
                {formatUtils.formatNumberWith2Digits(timeInterval.seconds)}
            </React.Fragment>
        return (
            <span>
                {this.props.updating ? editFormControls.buttonSpinner() : iconControl}
                {timeIntervalFormatted}
            </span >
        )
    }
}

export default TimelogTimerStartPauseControl;
