export const USER_ACCOUNT_RESEND_ACTIVATION = "USER_ACCOUNT_RESEND_ACTIVATION";
export const USER_ACCOUNT_RESEND_ACTIVATION_ERROR = "USER_ACCOUNT_RESEND_ACTIVATION_ERROR";
export const USER_ACCOUNT_RESEND_ACTIVATION_SUCCESS = "USER_ACCOUNT_RESEND_ACTIVATION_SUCCESS";

export const USER_ACCOUNT_UPDATE = "USER_ACCOUNT_UPDATE";
export const USER_ACCOUNT_UPDATE_ERROR = "USER_ACCOUNT_UPDATE_ERROR";
export const USER_ACCOUNT_UPDATE_SUCCESS = "USER_ACCOUNT_UPDATE_SUCCESS";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_CHECK = "USER_LOGIN_CHECK";
export const USER_LOGIN_CHECK_FAIL = "USER_LOGIN_CHECK_FAIL";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";

export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";