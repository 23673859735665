import * as actionTypes from "./actionTypes";

export const externalDataSyncRequestCreate = (externalDataSyncRequest, history) => {
	return {
		type: actionTypes.EXTERNAL_DATA_SYNC_REQUEST_CREATE,
		externalDataSyncRequest: externalDataSyncRequest, 
		history: history
	}
}

export const externalDataSyncRequestCreateError = (thisError) => {
	return {
		type: actionTypes.EXTERNAL_DATA_SYNC_REQUEST_CREATE_ERROR,
		error: thisError
	}
}

export const externalDataSyncRequestCreateSuccess = (createdRequest) => {
	return {
		type: actionTypes.EXTERNAL_DATA_SYNC_REQUEST_CREATE_SUCCESS,
		createdRequest: createdRequest
	}
}

export const externalDataSyncRequestDelete = (id) => {
	return {
		type: actionTypes.EXTERNAL_DATA_SYNC_REQUEST_DELETE,
		id: id
	}
}

export const externalDataSyncRequestDeleteError = (thisError) => {
	return {
		type: actionTypes.EXTERNAL_DATA_SYNC_REQUEST_DELETE_ERROR,
		error: thisError
	}
}

export const externalDataSyncRequestDeleteSuccess = (updatedRequest) => {
	return {
		type: actionTypes.EXTERNAL_DATA_SYNC_REQUEST_DELETE_SUCCESS,
		updatedRequest: updatedRequest
	}
}

export const externalDataSyncRequestGetAll = () => {
	return {
		type: actionTypes.EXTERNAL_DATA_SYNC_REQUEST_GET_ALL
	}
}

export const externalDataSyncRequestGetAllWithoutSpinner = () => {
	return {
		type: actionTypes.EXTERNAL_DATA_SYNC_REQUEST_GET_ALL_WITHOUT_SPINNER
	}
}

export const externalDataSyncRequestGetError = (thisError) => {
	return {
		type: actionTypes.EXTERNAL_DATA_SYNC_REQUEST_GET_ERROR,
		error: thisError
	}
}

export const externalDataSyncRequestGetSuccess = (data) => {
	return {
		type: actionTypes.EXTERNAL_DATA_SYNC_REQUEST_GET_SUCCESS,
		externalDataSyncRequests: data
	}
}

