import * as actionTypes from "./actionTypes";

const initialState = {
	counters: null,
	value: null,
	loading: false,
	uploading: false,
	error: null
}

export default function counterReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.COUNTER_GET_ALL:
		case actionTypes.COUNTER_GET_AND_UPDATE:
		case actionTypes.COUNTER_GET_BY_ID:
			return {
				...state,
				error: null
			}
		case actionTypes.COUNTER_GET_VALUE_IN_PROCESS:
		case actionTypes.COUNTER_GET_IN_PROCESS:
			return {
				...state,
				loading: true
			}
		case actionTypes.COUNTER_GET_SUCCESS:
			return {
				...state,
				loading: false,
				counters: action.Counters
			}
		case actionTypes.COUNTER_GET_VALUE_SUCCESS:
			return {
				...state,
				loading: false,
				value: action.value
			}
		case actionTypes.COUNTER_GET_ERROR:
		case actionTypes.COUNTER_GET_VALUE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.COUNTER_UPLOAD:
			return {
				...state,
				uploading: true,
				error: null
			}
		case actionTypes.COUNTER_UPLOAD_ERROR:
			return {
				...state,
				uploading: false,
				error: action.error
			}
		case actionTypes.COUNTER_UPLOAD_SUCCESS:
			return {
				...state,
				uploading: false
			}
		default:
			return state;
	}
}