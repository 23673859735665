import React, { Component } from 'react';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import { connect } from "react-redux";

import * as actionsBankStatement from '../../store/BankStatement/actions';
import Backdrop from '../Common/Backdrop';
import * as editFormControls from '../../helpers/editFormControls';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';

import classes from './Pages.module.css';

class RequestBankStatement extends Component {

    constructor(props) {
        super(props);
        const defaultDate = new Date();					// today's date

        this.state = {
            bankAccountId: null,
            dateFrom: defaultDate.toJSON().substring(0, 10),
            dateTo: defaultDate.toJSON().substring(0, 10),
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        if (thisObjectName === "dateFrom" && thisValue > this.state.dateTo) {
            this.setState({
                dateTo: thisValue
            });
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    updateValuesFromProps = () => {
        if (this.props.bankAccounts.length) {
            this.setState({
                bankAccountId: this.props.bankAccounts[0].id
            })
        }
        if (this.props.defaultDateFrom) {
            this.setState({
                dateFrom: this.props.defaultDateFrom
            });
        }
        if (this.props.defaultDateTo) {
            this.setState({
                dateTo: this.props.defaultDateTo
            });
        }
    }

    componentDidMount() {
        this.updateValuesFromProps();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.bankAccounts !== this.props.bankAccounts
            || prevProps.defaultDateFrom !== this.props.defaultDateFrom
            || prevProps.defaultDateTo !== this.props.defaultDateTo) {

            this.updateValuesFromProps();
        }
        if (prevProps.requesting && !this.props.requesting) {
            this.props.onClose();
        }
    }

    onRequestBankStatement = () => {
        const request = {
            bankAccountId: this.state.bankAccountId,
            openingDate: this.state.dateFrom.length < 20 ? this.state.dateFrom.substring(0, 10) + " 00:00:00" : this.state.dateFrom,
            closingDate: this.state.dateTo.length < 20 ? this.state.dateTo.substring(0, 10) + " 23:59:59" : this.state.dateTo
        }
        this.props.onRequestBankStatement(request);
    }

    render() {

        const bankAccountOptions = inputSelectUtils.generateOptionsFromData(this.props.bankAccounts, bankAccountRow => bankAccountRow.accountNo + " (" + bankAccountRow.currencyCode + ")");

        return (

            <div>
                <Backdrop
                    show
                    onClick={this.props.onClose}
                >
                </Backdrop>
                <div
                    className={classes.RequestBankStatementContainer}
                >
                    <Card
                        className={classes.PageCard}
                    >
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    Request bank statement
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.props.onClose}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody>

                            {editFormControls.selectControl("bankAccountId", "Bank account", this.onChange, this.state.bankAccountId, bankAccountOptions)}
                            {editFormControls.dateControl("dateFrom", "From date", this.onChange, this.state.dateFrom)}
                            {editFormControls.dateControl("dateTo", "To date", this.onChange, this.state.dateTo)}

                            <Button
                                color="primary"
                                onClick={this.onRequestBankStatement}
                            >
                                Request
                                {" "}
                                {this.props.requesting ? editFormControls.buttonSpinner() : null}
                            </Button>

                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ bankAccount, bankStatement }) => ({
    bankAccounts: bankAccount.bankAccounts,
    error: bankAccount.error,
    loading: bankAccount.loading,
    requesting: bankStatement.requesting
});

const mapDispatchToProps = dispatch => ({
    onRequestBankStatement: (request) => dispatch(actionsBankStatement.bankStatementRequest(request))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestBankStatement);