import { combineReducers } from "redux";

import allocationCriterion from "./AllocationCriterion/reducer";
import availableModule from "./AvailableModule/reducer";
import bankAccount from "./BankAccount/reducer";
import bankStatement from "./BankStatement/reducer";
import allocation from "./Allocation/reducer";
import cm20Payment from './CM20Payment/reducer';
import cm20PaymentFile from "./CM20PaymentFile/reducer";
import company from "./Company/reducer";
import companySettings from "./CompanySettings/reducer";
import counter from "./Counter/reducer";
import country from "./Country/reducer";
import crmBankImplementation from "./CRMBankImplementation/reducer";
import crmMaintenanceNotice from "./CRMMaintenance/reducer"
import currency from "./Currency/reducer";
import customer from "./Customer/reducer";
import customerAgreement from "./CustomerAgreement/reducer";
import customerInvoice from "./CustomerInvoice/reducer";
import customerQuote from "./CustomerQuote/reducer";
import dashboardData from "./DashboardData/reducer";
import exchRate from "./CurrencyExchangeRate/reducer";
import externalDataSync from "./ExternalDataSync/reducer";
import payment from "./Payment/reducer";
import paymentBulk from "./PaymentBulk/reducer";
import paymentFile from "./PaymentFile/reducer";
import product from "./Product/reducer";
import scheduledTask from "./ScheduledTask/reducer";
import smartId from "./SmartId/reducer";
import supplier from "./Supplier/reducer";
import supplierBankAccount from "./SupplierBankAccount/reducer";
import supplierInvoice from "./SupplierInvoice/reducer";
import tag from "./Tag/reducer";
import task from "./Task/reducer";
import timelog from "./Timelog/reducer";
import timelogTimer from "./TimelogTimer/reducer";
import tenant from "./Tenant/reducer";
import tenantLicense from "./TenantLicense/reducer";
import unit from "./Unit/reducer";
import userAccount from "./UserAccount/reducer";
import userActivate from "./UserActivate/reducer";
import userLogin from "./UserAccountLogin/reducer";
import userPasswordReset from "./UserPasswordReset/reducer";
import userRegister from "./UserRegister/reducer";
import vatRate from "./VATRate/reducer";

import authProfile from "./unused/auth-profile/reducer";
import calendar from "./unused/calendar/reducer";
import contacts from "./unused/contacts/reducer";
import crypto from "./unused/crypto/reducer";
import ecommerce from "./unused/e-commerce/reducer";
import invoices from "./unused/invoices/reducer";
import layout from "./layout/reducer";
import mails from "./unused/mails/reducer";
import projects from "./unused/projects/reducer";
import tasks from "./unused/tasks/reducer";

const rootReducer = combineReducers({
	allocationCriterion,
	availableModule,
	bankAccount,
	allocation,
	bankStatement,
	cm20Payment,
	cm20PaymentFile,
	company,
	companySettings,
	counter,
	country,
	crmBankImplementation,
	crmMaintenanceNotice,
	currency,
	customer,
	customerAgreement,
	customerInvoice,
	customerQuote,
	dashboardData,
	exchRate,
	externalDataSync,
	payment,
	paymentBulk,
	paymentFile,
	product,
	scheduledTask,
	smartId,
	supplier,
	supplierBankAccount,
	supplierInvoice,
	tag,
	task,
	tenant,
	tenantLicense,
	timelog,
	timelogTimer,
	unit,
	userAccount,
	userActivate,
	userLogin,
	userPasswordReset,
	userRegister,
	vatRate,

	Layout: layout,
	authProfile,
	ecommerce,
	calendar,
	mails,
	crypto,
	invoices,
	projects,
	tasks,
	contacts,
});

export default rootReducer;
