export const ScheduledTaskType = [
	{ code: 10, id: "BANK_FILE_RECEIVE", description: "Bank file receive" }, 
	{ code: 15, id: "STATEMENT_REQUEST", description: "Statement request" }, 
	{ code: 30, id: "EXTERNAL_DATA_IMPORT_REQUEST", description: "External data import" }, 
];

export const ScheduledTaskTypeAsSelectFilterOptions = {
	"BANK_FILE_RECEIVE": "Bank file receive",
	"STATEMENT_REQUEST": "Statement request",
	"EXTERNAL_DATA_IMPORT_REQUEST": "External data import",
};