import React, { Component } from "react";
import ReactDrawer from 'react-drawer';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

import CompanyDropdown from "../CommonForBoth/CompanyDropdown";
import TimelogTimerSelectionControl from "../CommonForBoth/TimelogTimerSelectionControl";
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/ProfileMenu";
import RightSidebar from '../CommonForBoth/RightSidebar';
import { toggleRightSidebar } from "../../store/actions";
import * as actionsCRMMaintenanceNotice from "../../store/CRMMaintenance/actions";
import sidebarExpandIcon from "assets/images/icons/expand_sidebar.png";
import sidebarCollapseIcon from "assets/images/icons/collapse_sidebar.png";

const widthThreshold = 992;

class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isCollapsed: false,
			isSearch: false,
			open: false,
			position: 'right',
			maintenanceAlertDismissed: false,
			showExpandCollapseButtonAsBurger: window.innerWidth < widthThreshold
		}
		this.toggleMenu = this.toggleMenu.bind(this);
		this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
		this.toggleFullscreen = this.toggleFullscreen.bind(this);
		this.closeDrawer = this.closeDrawer.bind(this);
		this.onDrawerClose = this.onDrawerClose.bind(this);

		this.props.onGetPendingMaintenanceNotices();
	}

	setIsCollapsed() {
		const isCollapsed = document.body.classList.contains("vertical-collpsed") || document.body.classList.contains("sidebar-enable");
		this.setState({
			isCollapsed: isCollapsed
		});
	}

	componentDidMount() {
		this.setIsCollapsed();
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	toggleMenu() {
		this.props.toggleMenuCallback();
		this.setIsCollapsed();
	}

	dismissMaintenanceNotice(maintenanceId) {
		localStorage.setItem("maintenanceDismissed_" + maintenanceId, "1");
		this.setState({
			maintenanceAlertDismissed: true
		});
	}

	toggleRightDrawer() {
		this.setState({ position: 'right' });
		this.setState({ open: !this.state.open });
	}

	closeDrawer() {
		this.setState({ open: false });
	}

	onDrawerClose() {
		this.setState({ open: false });
	}

	toggleRightbar() {
		this.props.toggleRightSidebar();
	}

	toggleFullscreen() {
		if (
			!document.fullscreenElement &&
			/* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				)
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	handleResize = () => {
		const shouldHide = window.innerWidth < widthThreshold;
		this.setState({ showExpandCollapseButtonAsBurger: shouldHide });
	};

	render() {
		let maintenanceDates = "", maintenanceAlert = null, maintenanceId = null;
		if (this.props.crmMaintenanceNotices) {
			for (let key in this.props.crmMaintenanceNotices) {
				const thisNotice = this.props.crmMaintenanceNotices[key];
				maintenanceDates += (maintenanceDates ? " and " : "");
				maintenanceDates += "from " + thisNotice.plannedDateTimeFrom + " to " + thisNotice.plannedDateTimeTo;
				maintenanceId = thisNotice.id;
			}
		}
		const alertAlreadyDismissed = localStorage.getItem("maintenanceDismissed_" + maintenanceId);
		if (maintenanceDates && !alertAlreadyDismissed && !this.state.maintenanceAlertDismissed) {
			maintenanceAlert = (
				<Alert
					color="warning"
					toggle={() => this.dismissMaintenanceNotice(maintenanceId)}
				>
					Please note that CashManager will be undergoing regular maintenance {maintenanceDates}. Please do not use the application during this time. Thank you.
				</Alert>
			);
		}
		return (
			<React.Fragment>
				{maintenanceAlert	/* The first call is to reserve the height for the alert (otherwise the header will overlap with Breadcrumbs that display the page info) */}
				<header id="page-topbar">
					{maintenanceAlert /* The second call is to display the alert itself (otherwise it will be hidden under the header) */}
					<div className="navbar-header">
						<div className="d-flex"
							style={{
								height: "100%",
								marginLeft: "0px",
								paddingLeft: "0px"
							}}
						>
							<div
								style={{
									display: this.state.showExpandCollapseButtonAsBurger ? 'none' : 'block',
									height: "50%",
									marginTop: "0px",
									marginLeft: "0px",
									paddingLeft: "0px"
								}}
							>
								<img
									onClick={this.toggleMenu}
									src={this.state.isCollapsed ? sidebarExpandIcon : sidebarCollapseIcon}
									style={{
										cursor: "pointer",
										height: "100%",
										marginLeft: "0px",
										paddingLeft: "0px"
									}}
								/>
							</div>

							<button
								type="button"
								onClick={this.toggleMenu}
								className="btn btn-sm px-3 font-size-20 header-item"
								id="vertical-menu-btn"
								style={{
									display: this.state.showExpandCollapseButtonAsBurger ? 'block' : 'none',
								}}
							>
								<i className="bx bx-menu" />
							</button>

							{/*<form className="app-search d-none d-lg-block">
								<div className="position-relative">
									<input
										type="text"
										className="form-control"
										placeholder={this.props.t("Search") + "..."}
									/>
									<span className="bx bx-search-alt"></span>
								</div>
							</form>*/}

						</div>


						<div className="d-flex">

							{/*<div className="dropdown d-inline-block d-lg-none ms-2">
								<button
									onClick={() => {
										this.setState({ isSearch: !this.state.isSearch })
									}}
									type="button"
									className="btn header-item noti-icon"
									id="page-header-search-dropdown"
								>
									<i className="mdi mdi-magnify"></i>
								</button>
								<div
									className={
										this.state.isSearch
											? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
											: "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
									}
									aria-labelledby="page-header-search-dropdown"
								>
									<form className="p-3">
										<div className="form-group m-0">
											<div className="input-group">
												<input
													type="text"
													className="form-control"
													placeholder="Search ..."
													aria-label="Recipient's username"
												/>
												<div className="input-group-append">
													<button className="btn btn-primary" type="submit">
														<i className="mdi mdi-magnify"></i>
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>*/}

							<TimelogTimerSelectionControl />

							<CompanyDropdown />
							{/*<LanguageDropdown />*/}

							{/*
							// Fullscreen button. Does work correctly, but its vertical alignment is not perfect and it's not really needed at this point
							<div className="dropdown d-none d-lg-inline-block ms-1">
								<button
									type="button"
									onClick={this.toggleFullscreen}
									className="btn header-item noti-icon"
									data-toggle="fullscreen"
								>
									<i className="bx bx-fullscreen"></i>
								</button>
							</div> */}

							{/*<NotificationDropdown />*/}
							<ProfileMenu />

							{/*<div
								onClick={this.toggleRightDrawer} disabled={this.state.open}
								className="dropdown d-inline-block"
							>
								<button
									type="button"
									className="btn header-item noti-icon right-bar-toggle"
								>
									<i className="bx bx-cog bx-spin"></i>
								</button>
							</div>*/}
						</div>
					</div>
				</header>
				<ReactDrawer
					open={this.state.open}
					position={this.state.position}
					onClose={this.onDrawerClose}
				>
					<RightSidebar onClose={this.onDrawerClose} />
				</ReactDrawer>
			</React.Fragment>
		)
	}
}

const mapStatetoProps = ({ availableModule, crmMaintenanceNotice, Layout }) => ({
	availableModules: availableModule.availableModules,
	crmMaintenanceNotices: crmMaintenanceNotice.crmMaintenanceNotices,
	layoutType: Layout.layoutType,
});

const mapDispatchToProps = dispatch => ({
	toggleRightSidebar,
	onGetPendingMaintenanceNotices: () => dispatch(actionsCRMMaintenanceNotice.crmMaintenanceNoticeGetPending())
});

export default connect(mapStatetoProps, mapDispatchToProps)(
	withTranslation()(Header)
);
