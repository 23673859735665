import * as formatUtils from 'helpers/formatUtils';

export const criterionId = {
	dataField: 'criterion.id',
	text: 'Criterion',
	formatter: (cell, row, rowIndex) => formatUtils.formatAllocationCriterion(row.criterion)
};
export const type = {
	dataField: 'type',
	text: 'Type'
};
export const currencyCode = {
	dataField: 'currencyCode',
	text: 'Currency',
};
export const amount = {
	dataField: 'amount',
	text: 'Amount',
	align: "right",
	headerStyle: { textAlign: "right" },
	formatter: (cell) => formatUtils.formatAmountWithDCSign(cell)
};
