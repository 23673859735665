export const USER_ACCOUNT_LOGIN = "/login";
export const USER_ACCOUNT_LOGOUT = "/logout";
export const USER_ACCOUNT_PROFILE_EDIT = "/profile-edit";
export const USER_ACCOUNT_REGISTER = "/register";
export const USER_ACCOUNT_REGISTER_FROM_INVITATION = "/register-from-invitation/:token/:email";
export const USER_ACCOUNT_REGISTER_SUCCESS = "/register-success";
export const USER_ACCOUNT_REGISTER_SUCCESS_ACTIVATION_PENDING = "/register-success-activation-pending/:email";
export const USER_ACCOUNT_ACTIVATE = "/activate/:token";
export const USER_PASSWORD_RESET_REQUEST = "/password-reset-request";
export const USER_PASSWORD_RESET = "/password-reset/:token";

export const PLACEHOLDER = "/#";

export const ALLOCATION_CRITERION_BROWSE_PAYMENT = '/allocation-criterion-browse-payment';
export const ALLOCATION_CRITERION_BROWSE_CUSTOMER_INVOICE = '/allocation-criterion-browse-customer-invoice';
export const ALLOCATION_CRITERION_BROWSE_SUPPLIER_INVOICE = '/allocation-criterion-browse-supplier-invoice';
export const ALLOCATION_CRITERION_BROWSE_BANK_CHARGE = '/allocation-criterion-browse-bank-charge';
// export const ALLOCATION_CRITERION_NEW = '/allocation-criterion-new';
// export const ALLOCATION_CRITERION_EDIT = '/allocation-criterion-edit/:id';

export const BANK_ACCOUNT_BROWSE = '/bank-account-browse';
export const BANK_ACCOUNT_NEW = '/bank-account-new';
export const BANK_ACCOUNT_EDIT = '/bank-account-edit/:id';

export const BANK_STATEMENT_BROWSE = '/bank-statement-browse';
export const BANK_STATEMENT_RECONCILE = '/bank-statement-reconcile/:id';
// export const BANK_STATEMENT_NEW = '/bank-statement-new';
// export const BANK_STATEMENT_EDIT = '/bank-statement-edit/:id';

export const CM20PAYMENT_BROWSE_ERRONEOUS = '/cm20-payment-browse-erroneous';
export const CM20PAYMENT_BROWSE_SIGNED = '/cm20-payment-browse-signed';
export const CM20PAYMENT_BROWSE_UNSIGNED = '/cm20-payment-browse-unsigned';
export const CM20PAYMENT_SIGN_SUCCESS = '/cm20-payment-sign-success';

export const COMPANY_BROWSE = '/company-browse';
export const COMPANY_EDIT = '/company-edit/:id';
export const COMPANY_NEW = '/company-new';

export const COMPANY_SETTINGS_EDIT = '/company-settings-edit';

export const CURRENCY_BROWSE = '/currency-browse';
export const CURRENCY_NEW = '/currency-new';
export const CURRENCY_EDIT = '/currency-edit/:id';

export const CURRENCY_EXCHRATE_BROWSE = '/currency-exch-rate-browse';
export const CURRENCY_EXCHRATE_NEW = '/currency-exch-rate-new';
export const CURRENCY_EXCHRATE_EDIT = '/currency-exch-rate-edit/:id';

export const COUNTRY_BROWSE = '/country-browse';
export const COUNTRY_NEW = '/country-new';
export const COUNTRY_EDIT = '/country-edit/:id';

export const CUSTOMER_BROWSE = '/customer-browse';
export const CUSTOMER_NEW = '/customer-new';
export const CUSTOMER_EDIT = '/customer-edit/:id';

export const CUSTOMER_AGREEMENT_BROWSE = '/customer-greement-browse';
export const CUSTOMER_AGREEMENT_NEW = '/customer-agreement-new';
export const CUSTOMER_AGREEMENT_EDIT = '/customer-agreement-edit/:id';

export const CUSTOMER_INVOICE_BROWSE_ALL = '/customer-invoice-browse-all';
export const CUSTOMER_INVOICE_BROWSE_UNPAID = '/customer-invoice-browse-unpaid';
export const CUSTOMER_INVOICE_BROWSE_OVERDUE = '/customer-invoice-browse-overdue';
export const CUSTOMER_INVOICE_NEW = '/customer-invoice-new';
export const CUSTOMER_INVOICE_EDIT = '/customer-invoice-edit/:id';

export const CUSTOMER_INVOICE_LINE_NEW = '/customer-invoice-line-new/:invoiceid';
export const CUSTOMER_INVOICE_LINE_EDIT = '/customer-invoice-line-edit/:id';

export const CUSTOMER_QUOTE_BROWSE = '/customer-quote-browse';
export const CUSTOMER_QUOTE_NEW = '/customer-quote-new';
export const CUSTOMER_QUOTE_EDIT = '/customer-quote-edit/:id';

export const CUSTOMER_QUOTE_LINE_NEW = '/customer-quote-line-new/:quoteid';
export const CUSTOMER_QUOTE_LINE_EDIT = '/customer-quote-line-edit/:id';

export const DASHBOARD = "/dashboard";

export const EXTERNAL_DATA_SYNC_REQUEST_BROWSE = "/external-data-sync-requests";

export const PAYMENT_BROWSE_PREPARED = '/payment-browse-prepared';
export const PAYMENT_BROWSE_ERRONEOUS = '/payment-browse-erroneous';
export const PAYMENT_BROWSE_EXPORTED_TO_BANK_AS_FILE = '/payment-browse-exported-to-bank-as-file';
export const PAYMENT_BROWSE_SENT_TO_BANK_UNSIGNED = '/payment-browse-sent-to-bank-unsigned';
export const PAYMENT_BROWSE_SENT_TO_BANK_SIGNED = '/payment-browse-sent-to-bank-signed';
export const PAYMENT_BROWSE_PROCESSED = '/payment-browse-processed';
export const PAYMENT_BROWSE_UNSIGNED = '/payment-browse-unsigned';
export const PAYMENT_NEW = '/payment-new';
export const PAYMENT_EDIT = '/payment-edit/:id';
export const PAYMENT_SIGN_SUCCESS = '/payment-sign-success';

export const PRODUCT_BROWSE = '/product-browse';
export const PRODUCT_NEW = '/product-new';
export const PRODUCT_EDIT = '/product-edit/:id';

export const SCHEDULED_TASK_BROWSE = '/scheduled-task-browse';

export const START_PAGE_REDIRECT = "/start-page-redirect";

export const SUPPLIER_BROWSE = '/supplier-browse';
export const SUPPLIER_NEW = '/supplier-new';
export const SUPPLIER_EDIT = '/supplier-edit/:id';

export const SUPPLIER_BANK_ACCOUNT_BROWSE = '/supplier-bank-account-browse';
export const SUPPLIER_BANK_ACCOUNT_NEW = '/supplier-bank-account-new';
export const SUPPLIER_BANK_ACCOUNT_EDIT = '/supplier-bank-account-edit/:id';

export const SUPPLIER_INVOICE_BROWSE_ALL = '/supplier-invoice-browse-all';
export const SUPPLIER_INVOICE_BROWSE_UNPAID = '/supplier-invoice-browse-unpaid';
export const SUPPLIER_INVOICE_NEW = '/supplier-invoice-new';
export const SUPPLIER_INVOICE_EDIT = '/supplier-invoice-edit/:id';

export const TAG_BROWSE = '/tag-browse';
export const TAG_NEW = '/tag-new';
export const TAG_EDIT = '/tag-edit/:id';

export const TASK_BROWSE = '/task-browse';
export const TASK_NEW = '/task-new';
export const TASK_EDIT = '/task-edit/:id';

export const TENANT_CREATE_ADMIN = '/tenant-create-admin/:id';
export const TENANT_BROWSE = '/tenant-browse';
export const TENANT_NEW = '/tenant-new';
export const TENANT_EDIT = '/tenant-edit/:id';

export const TIMELOG_BROWSE = '/timelog-browse';
export const TIMELOG_NEW = '/timelog-new';
export const TIMELOG_EDIT = '/timelog-edit/:id';

export const UNIT_BROWSE = '/unit-browse';
export const UNIT_NEW = '/unit-new';
export const UNIT_EDIT = '/unit-edit/:id';

export const USER_ACCOUNT_BROWSE = '/user-account-browse';
export const USER_ACCOUNT_EDIT = '/user-account-edit/:id';
export const USER_ACCOUNT_INVITE = '/user-account-invite';

export const VAT_RATE_BROWSE = '/vat-rate-browse';
export const VAT_RATE_NEW = '/vat-rate-new';
export const VAT_RATE_EDIT = '/vat-rate-edit/:id';



//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"
export const ADD_NEW_PROJECT = "/add/product"
export const UPDATE_PROJECT = "/update/product"
export const DELETE_PROJECT = "/delete/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"