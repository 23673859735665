import React, { Component } from 'react';
import { Container, Row, Col, Button, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import cronParser from 'cron-parser';

import * as cronHelper from '../../helpers/cronHelper';
import * as editFormControls from '../../helpers/editFormControls';

class ScheduleSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: null,
            scheduleType: 'daily',
            selectedDays_Mon: false,
            selectedDays_Tue: false,
            selectedDays_Wed: false,
            selectedDays_Thu: false,
            selectedDays_Fri: false,
            selectedDays_Sat: false,
            selectedDays_Sun: false,
            selectedTime: '',
            regularityInHours: 0,
            regularityInDays: 0,
            nextExecutionTime: ''
        };

        this.onChange = this.onChange.bind(this);
        // this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type === "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        if (thisObjectName === "scheduleType") {
            if (thisValue === "weekly") {
                this.initializeStateFromCronExpression('0 0 0 * * 1-5', thisValue);
            } else {
                this.initializeStateFromCronExpression('0 0 0 * * ?', thisValue);
            }
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    componentDidMount() {
        this.initializeStateFromCronExpression(this.props.cronExpression);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cronExpression !== this.props.cronExpression) {
            this.initializeStateFromCronExpression(this.props.cronExpression);
        }

        if (prevState.selectedDays_Mon !== this.state.selectedDays_Mon
            || prevState.selectedDays_Tue !== this.state.selectedDays_Tue
            || prevState.selectedDays_Wed !== this.state.selectedDays_Wed
            || prevState.selectedDays_Thu !== this.state.selectedDays_Thu
            || prevState.selectedDays_Fri !== this.state.selectedDays_Fri
            || prevState.selectedDays_Sat !== this.state.selectedDays_Sat
            || prevState.selectedDays_Sun !== this.state.selectedDays_Sun
        ) {
            const dayOfWeek = [];
            if (this.state.selectedDays_Mon) dayOfWeek.push(1);
            if (this.state.selectedDays_Tue) dayOfWeek.push(2);
            if (this.state.selectedDays_Wed) dayOfWeek.push(3);
            if (this.state.selectedDays_Thu) dayOfWeek.push(4);
            if (this.state.selectedDays_Fri) dayOfWeek.push(5);
            if (this.state.selectedDays_Sat) dayOfWeek.push(6);
            if (this.state.selectedDays_Sun) dayOfWeek.push(0);

            const interval = {
                ...this.state.interval,
                fields: {
                    ...this.state.interval.fields,
                    dayOfWeek: dayOfWeek
                }
            }

            this.setState({
                interval
            });
        }

        if (prevState.scheduleType === this.state.scheduleType && (
            (prevState.interval && this.state.interval && prevState.interval.fields !== this.state.interval.fields)
            || (prevState.regularityInHours != this.state.regularityInHours || prevState.regularityInDays != this.state.regularityInDays
                || prevState.selectedTime != this.state.selectedTime))
        ) {
            this.props.onApply(this.renderCronExpression());
        }

    }

    initializeStateFromCronExpression(cronExpression, scheduleType) {
        if (!cronExpression) {
            return;
        }
        try {

            const parsedCronExpression = cronHelper.parseCronExpression(cronExpression);

            this.setState({
                interval: parsedCronExpression.interval,
                scheduleType: (scheduleType ? scheduleType : parsedCronExpression.scheduleType),
                selectedDays_Mon: parsedCronExpression.selectedDays_Mon,
                selectedDays_Tue: parsedCronExpression.selectedDays_Tue,
                selectedDays_Wed: parsedCronExpression.selectedDays_Wed,
                selectedDays_Thu: parsedCronExpression.selectedDays_Thu,
                selectedDays_Fri: parsedCronExpression.selectedDays_Fri,
                selectedDays_Sat: parsedCronExpression.selectedDays_Sat,
                selectedDays_Sun: parsedCronExpression.selectedDays_Sun,
                selectedTime: parsedCronExpression.selectedTime,
                regularityInHours: parsedCronExpression.regularityInHours,
                regularityInDays: parsedCronExpression.regularityInDays,
                nextExecutionTime: parsedCronExpression.nextExecutionTime
            });
        } catch (error) {
            this.initializeStateFromCronExpression('0 0 * * * ?');
            console.error('Error parsing cron expression:', error.message);
        }
    }

    determineScheduleType = (fields) => {
        if (fields.hour && fields.hour.length > 1 && fields.hour.length < 24) {
            return 'hourly';
        } else if (fields.dayOfWeek && fields.dayOfWeek.length < 7) {
            return 'weekly';
        } else if (fields.dayOfMonth && fields.dayOfMonth.length < 31) {
            return 'monthly';
        } else {
            return 'daily';
        }
    }


    renderCronExpression = () => {
        if (!this.state.interval || !this.state.interval.fields) {
            return;
        }

        const cronExpression = cronParser.fieldsToExpression(this.state.interval.fields).stringify(true);
        const cronExpressionParts = cronExpression.split(' ');

        if (this.state.selectedTime) {
            cronExpressionParts[1] = parseInt(this.state.selectedTime.split(':')[1]);
            cronExpressionParts[2] = parseInt(this.state.selectedTime.split(':')[0]);
        }

        if (this.state.regularityInHours) {
            cronExpressionParts[2] = '*/' + this.state.regularityInHours;
        }

        if (this.state.regularityInDays && this.state.regularityInDays > 0) {
            cronExpressionParts[3] = '*/' + this.state.regularityInDays;
        }

        if (cronExpressionParts[3] === '*') {
            // org.apache.logging.log4j.core.util.CronExpression does not support the '*' value for both Day-of-Month and Day-of-Week
            if (cronExpressionParts[5] === '*') {
                cronExpressionParts[5] = '?';
            } else if (cronExpressionParts[5] !== '?') {
                cronExpressionParts[3] = '?';
            }
        }

        return '*'  // Always ignoring the seconds part
            + ' ' + cronExpressionParts[1]
            + ' ' + cronExpressionParts[2]
            + ' ' + cronExpressionParts[3]
            + ' ' + cronExpressionParts[4]
            + ' ' + cronExpressionParts[5];

    }

    render() {
        const selectedTimeControl =
            <Row>
                <Col xs="auto">
                    <Label className="col-form-label">
                        Starts on
                    </Label>
                </Col>
                <Col>
                    <Input
                        id="selectedTime"
                        name="selectedTime"
                        type="time"
                        value={this.state.selectedTime}
                        onChange={this.onChange}
                    />
                </Col>
            </Row>

        const hourlyScheduleControls =
            <React.Fragment>
                <Row>
                    <Col xs="auto">
                        <Label className="col-form-label">
                            Repeats every
                        </Label>
                    </Col>
                    <Col>
                        {editFormControls.numberControlWithMinMaxWithoutLabel("regularityInHours", "hour(s)", this.onChange, this.state.regularityInHours, 1, 1, 24)}
                    </Col>
                    <Col>
                        <Label className="col-form-label">
                            hour(s)
                        </Label>
                    </Col>
                </Row>
            </React.Fragment>


        const dailyScheduleControls =
            <React.Fragment>
                {selectedTimeControl}
                <br />
                <Row>
                    <Col xs="auto">
                        <Label className="col-form-label">
                            Repeats every
                        </Label>
                    </Col>
                    <Col>
                        {editFormControls.numberControlWithMinMaxWithoutLabel("regularityInDays", "day(s)", this.onChange, this.state.regularityInDays, 1, 1, 365)}
                    </Col>
                    <Col>
                        <Label className="col-form-label">
                            day(s)
                        </Label>
                    </Col>
                </Row>
            </React.Fragment>

        const weeklyScheduleControls =
            <React.Fragment>
                {selectedTimeControl}
                <br />
                <Container>
                    <Row>
                        <Col>
                            {editFormControls.miniCheckboxControl("selectedDays_Mon", "Monday", this.onChange, this.state.selectedDays_Mon)}
                            {editFormControls.miniCheckboxControl("selectedDays_Tue", "Tuesday", this.onChange, this.state.selectedDays_Tue)}
                            {editFormControls.miniCheckboxControl("selectedDays_Wed", "Wednesday", this.onChange, this.state.selectedDays_Wed)}
                            {editFormControls.miniCheckboxControl("selectedDays_Thu", "Thursday", this.onChange, this.state.selectedDays_Thu)}
                        </Col>
                        <Col>
                            {editFormControls.miniCheckboxControl("selectedDays_Fri", "Friday", this.onChange, this.state.selectedDays_Fri)}
                            {editFormControls.miniCheckboxControl("selectedDays_Sat", "Saturday", this.onChange, this.state.selectedDays_Sat)}
                            {editFormControls.miniCheckboxControl("selectedDays_Sun", "Sunday", this.onChange, this.state.selectedDays_Sun)}
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>

        const scheduleTypeOptions =
            <React.Fragment>
                <option value="hourly">Hourly</option>
                {/* <option value="daily">Daily</option> */ /* Commented out as it is unclear how cron will check "every x days" without the starting date */}
                <option value="weekly">Weekly</option>
                {/* <option value="monthly">Monthly</option> */}
            </React.Fragment>

        return (
            <React.Fragment>
                {editFormControls.selectControlWithoutLabel("scheduleType", this.onChange, this.state.scheduleType, scheduleTypeOptions)}

                <br />
                <Row>
                    <Col>
                        <FormGroup>
                            {this.state.scheduleType === 'hourly' && hourlyScheduleControls}
                            {this.state.scheduleType === 'daily' && dailyScheduleControls}
                            {this.state.scheduleType === 'weekly' && weeklyScheduleControls}
                        </FormGroup>
                    </Col>
                </Row>

                <br />
                <br />
                <Row>
                    <Col xs="auto">
                        <Label>Next execution</Label>
                    </Col>
                    <Col>
                        {this.state.nextExecutionTime}
                    </Col>
                </Row>

            </React.Fragment>
        );
    }

}

export default ScheduleSelector;
