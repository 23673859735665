import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as downloadHelper from '../../helpers/downloadHelper';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaHelper";

function* createPaymentBulksFromInvoicesSaga({ selectedInvoices, bankAccountId, currencyId, doGroupPayments, urgency, chargeBearer, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.PAYMENT_CREATE_BULKS_FROM_INVOICES,
			{
				supplierInvoiceIds: selectedInvoices,
				bankAccountId: bankAccountId,
				currencyId: currencyId,
				doGroupPayments: doGroupPayments,
				chargeBearer: chargeBearer,
				urgency: urgency
			}
		);
		history.push(endpointsFrontend.PAYMENT_BROWSE_PREPARED);

		let nonPositivePayments = [];
		for (let key in response.data) {
			if (response.data[key].totalAmountToPay <= 0) {
				nonPositivePayments.push(response.data[key]);
			}
		}
		if (nonPositivePayments.length > 0) {
			yield put(actions.paymentBulkCreatePartialSuccess(nonPositivePayments));
		} else {
			yield put(actions.paymentBulkCreateSuccess(response.data));
		}
	} catch (e) {
		yield put(actions.paymentBulkCreateError(e));
	}
}

function* createPaymentFilesSaga({ paymentBulksToCreateFiles, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.PAYMENT_BULK_CREATE_FILES,
			{ idList: paymentBulksToCreateFiles }
		);
		let redirectTo = "";
		if (response.data && response.data.redirectTo) {
			redirectTo = response.data.redirectTo;
		}
		downloadHelper.downloadHelper(response);
		switch (redirectTo) {
			case "to-sign":
				history.push(endpointsFrontend.PAYMENT_BROWSE_UNSIGNED);
				break;
			case "sent-to-bank":
				history.push(endpointsFrontend.PAYMENT_BROWSE_PROCESSED);
				break;
			case "exported-to-bank":
				history.push(endpointsFrontend.PAYMENT_BROWSE_EXPORTED_TO_BANK_AS_FILE);
				break;
		}
		yield put(actions.paymentBulkCreateFilesSuccess(response));
	} catch (e) {
		yield put(actions.paymentBulkCreateFilesError(e));
	}
}

function* deletePaymentBulkSaga({ id, history }) {
	yield call(deletePaymentBulkListSaga, { paymentBulksToDelete: [ id ], history});
}

function* deletePaymentBulkListSaga({ paymentBulksToDelete, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.PAYMENT_BULK,
			{ idList: paymentBulksToDelete }
		);
		yield put(actions.paymentBulkDeleteSuccess());
		yield call(getAllPreparedPaymentBulksSaga);
		if (history) {
			history.push(endpointsFrontend.PAYMENT_BROWSE_PREPARED);
		}
	} catch (e) {
		yield put(actions.paymentBulkDeleteError(e));
	}
}

function* editPaymentBulkSaga({ paymentBulk, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.PAYMENT_BULK_UPDATE,
			paymentBulk
		);
		yield put(actions.paymentBulkEditSuccess());
		history.push(endpointsFrontend.PAYMENT_BROWSE_PREPARED);
	} catch (e) {
		yield put(actions.paymentBulkEditError(e));
	}
}

function* getAllPreparedPaymentBulksSaga() {
	yield call(getPaymentBulksSaga, endpointsBackend.PAYMENT_BULK_GET_PREPARED);
}

function* getPaymentBulksSaga(url) {
	try {
		const response = yield call(
			rest.get,
			url
		);
		yield put(actions.paymentBulkGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.paymentBulkGetError(e));
	}
}

function* getPaymentBulkByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_BULK_GET_BY_ID.replace("{paymentBulkId}", id)
		);
		yield put(actions.paymentBulkGetSuccess([response.data]));
	} catch (e) {
		yield put(actions.paymentBulkGetError(e));
	}
}

function* getPaymentBulkTotalSaga({ selectedPaymentBulks }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.PAYMENT_BULK_GET_TOTAL_BY_ID_LIST,
			{ idList: selectedPaymentBulks }
		);

		yield put(actions.paymentBulkGetTotalSuccess(response.data));
	} catch (e) {
		yield put(actions.paymentBulkGetTotalError(e));
	}
}

function* getUnallocatedPaymentBulksSaga() {
	yield call(getPaymentBulksSaga, endpointsBackend.PAYMENT_BULK_GET_UNALLOCATED);
}

function* PaymentBulkSaga() {
	yield (takeEvery(actionTypes.PAYMENT_BULK_CREATE_FILES, createPaymentFilesSaga));
	yield (takeEvery(actionTypes.PAYMENT_BULK_CREATE_FROM_INVOICES, createPaymentBulksFromInvoicesSaga));
	yield (takeEvery(actionTypes.PAYMENT_BULK_DELETE, deletePaymentBulkSaga));
	yield (takeEvery(actionTypes.PAYMENT_BULK_DELETE_LIST, deletePaymentBulkListSaga));
	yield (takeEvery(actionTypes.PAYMENT_BULK_EDIT, editPaymentBulkSaga));
	yield (takeEvery(actionTypes.PAYMENT_BULK_GET_BY_ID, getPaymentBulkByIdSaga));
	yield (takeEvery(actionTypes.PAYMENT_BULK_GET_PREPARED, getAllPreparedPaymentBulksSaga));
	yield (takeEvery(actionTypes.PAYMENT_BULK_GET_TOTAL, getPaymentBulkTotalSaga));
	yield (takeEvery(actionTypes.PAYMENT_BULK_GET_UNALLOCATED, getUnallocatedPaymentBulksSaga));
}

export default PaymentBulkSaga;