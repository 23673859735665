import React, { Component } from "react";
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import SimpleBar from "simplebar-react";

import * as dateUtils from "../../helpers/dateAndTimeUtils";
import * as endpointsFrontend from "../../definitions/endpoints/endpoints-frontend";
import * as formatUtils from "../../helpers/formatUtils";
import * as taskHelper from "../../helpers/taskHelper";

class TaskList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			activeTab: 1,
			tasksDueToday: [],
			tasksDueThisWeek: [],
			tasksDueThisMonth: [],
			hoveredTag: null
		}
		this.toggleTab = this.toggleTab.bind(this)
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			})
		}
	}

	taskItem = (taskTitle, taskId, dueDate, link, tags) => {
		const overdueMarker =
			<React.Fragment>
				<span
					className={"badge badge-soft-warning font-size-12"}
				>
					Overdue
				</span>
				<span>
					&nbsp;
					&nbsp;
				</span>
			</React.Fragment>

		const dueDateAsJSDate = new Date(Date.parse(dueDate));

		const tagItem = (tag) => {
			const tagTitleSimplified = tag.title && tag.title.includes("/") && this.state.hoveredTag !== tag.id ? tag.title.substring(tag.title.lastIndexOf("/") + 1) : tag.title;

			return (
				<React.Fragment>
					<span
						className={"badge badge-soft-secondary font-size-12"}
						onMouseEnter={() => this.setState({ hoveredTag: tag.id })}
						onMouseLeave={() => this.setState({ hoveredTag: null })}
					>
						{tagTitleSimplified}
					</span>
					<span>
						&nbsp;
					</span>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<Link
					to={link}
					style={{
						overflow: "hidden"
					}}
				>
					<div style={{
						paddingBottom: "15px"
					}}>
						<h5 className="text-truncate font-size-14 mb-1">
							{taskTitle}
						</h5>
						<span>
							&nbsp;
						</span>
						{tags.map(tag => tagItem(tag))}
					</div>
				</Link>
				<Link to={link}>
					<div>
						<p
							className="text-muted mb-0"
							style={{ textAlign: "right" }}
						>
							{dateUtils.compareAsDates(dueDateAsJSDate, new Date()) === -1 && overdueMarker}
							{dueDate && formatUtils.formatJSDate(dueDateAsJSDate)}
						</p>
					</div>
				</Link>
			</React.Fragment >
		);
	}

	convertTaskListToTaskItems = (taskList) =>
		taskList.map(task =>
			this.taskItem(task.title, task.id, task.dueDate, endpointsFrontend.TASK_EDIT.replace(":id", task.id), task.tags)
		);


	render() {

		const tasksSplitByDueDate = taskHelper.splitTasksPerDueDate(this.props.taskList);

		const taskItemsDueToday = this.convertTaskListToTaskItems(tasksSplitByDueDate.tasksDueToday);
		const taskItemsDueThisWeek = this.convertTaskListToTaskItems(tasksSplitByDueDate.tasksDueThisWeek);
		const taskItemsDueThisMonth = this.convertTaskListToTaskItems(tasksSplitByDueDate.tasksDueThisMonth);

		const tasksStarred = this.props.starredTaskList ? this.convertTaskListToTaskItems(this.props.starredTaskList) : [];
		const tasksRecent = this.props.recentTaskList ? this.convertTaskListToTaskItems(this.props.recentTaskList) : [];

		const navItem = (tabIndex, tabTitle) =>
			<NavItem>
				<NavLink
					className={classnames({
						active: this.state.activeTab === tabIndex
					})}
					onClick={() => {
						this.toggleTab(tabIndex)
					}}
				>
					{tabTitle}
				</NavLink>
			</NavItem>

		return (
			<React.Fragment>
				<Card>
					<CardBody>
						<h4 className="card-title mb-4">Tasks</h4>

						<Nav pills className="bg-light rounded">
							{navItem(1, "Today")}
							{navItem(2, "This week")}
							{navItem(3, "This month")}
							{navItem(4, "Starred")}
							{navItem(5, "Recent")}
						</Nav>

						<div className="mt-4">
							<SimpleBar style={{ maxHeight: "250px" }}>
								<div style={{
									display: "grid",
									gridTemplateColumns: "1fr auto",
									width: "100%"
								}}>
									{this.state.activeTab === 1
										? taskItemsDueToday
										: this.state.activeTab === 2
											? taskItemsDueThisWeek
											: this.state.activeTab === 3
												? taskItemsDueThisMonth
												: this.state.activeTab === 4
													? tasksStarred
													: tasksRecent}
								</div>
							</SimpleBar>
						</div>
					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

export default TaskList;