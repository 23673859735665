import React, { Component } from "react";
import MetisMenu from "metismenujs";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import SimpleBar from "simplebar-react";

import * as editFormControls from "../../helpers/editFormControls";
import * as endpointsFrontend from "../../definitions/endpoints/endpoints-frontend";

class SidebarContent extends Component {
	constructor(props) {
		super(props);
		this.refDiv = React.createRef();
	}

	componentDidMount() {
		this.initMenu();
	}

	componentDidUpdate(prevProps) {
		if (this.props.type !== prevProps.type) {
			this.initMenu();
		}
	}

	initMenu() {
		try {
			new MetisMenu("#side-menu");

			let matchingMenuItem = null;
			const ul = document.getElementById("side-menu");
			const items = ul.getElementsByTagName("a");
			for (let i = 0; i < items.length; ++i) {
				if (this.props.location.pathname === items[i].pathname) {
					matchingMenuItem = items[i];
					break;
				}
			}
			if (matchingMenuItem) {
				this.activateParentDropdown(matchingMenuItem);
			}
		} catch (e) {
			// Sometimes new MetisMenu("#side-menu") falls with "this.element() is null"
		}
	}

	scrollElement = item => {
		setTimeout(() => {
			if (this.refDiv.current !== null) {
				if (item) {
					const currentPosition = item.offsetTop;
					if (currentPosition > window.innerHeight) {
						if (this.refDiv.current)
							this.refDiv.current.getScrollElement().scrollTop = currentPosition - 300;
					}
				}
			}
		}, 300);
	}

	activateParentDropdown = item => {
		item.classList.add("active");
		const parent = item.parentElement;

		const parent2El = parent.childNodes[1];
		if (parent2El && parent2El.id !== "side-menu") {
			parent2El.classList.add("mm-show");
		}

		if (parent) {
			parent.classList.add("mm-active");
			const parent2 = parent.parentElement;

			if (parent2) {
				parent2.classList.add("mm-show"); // ul tag

				const parent3 = parent2.parentElement; // li tag

				if (parent3) {
					parent3.classList.add("mm-active"); // li
					parent3.childNodes[0].classList.add("mm-active"); //a
					const parent4 = parent3.parentElement; // ul
					if (parent4) {
						parent4.classList.add("mm-show"); // ul
						const parent5 = parent4.parentElement;
						if (parent5) {
							parent5.classList.add("mm-show"); // li
							parent5.childNodes[0].classList.add("mm-active"); // a tag
						}
					}
				}
			}
			this.scrollElement(item);
			return false;
		}
		this.scrollElement(item);
		return false;
	}

	render() {
		const availableModules = [];
		if (this.props.availableModules) {
			this.props.availableModules.map(availableModule => {
				availableModules.push(availableModule.name);
			})
		}

		const itemCategory = (text, icon) => {
			return (
				<Link to={endpointsFrontend.PLACEHOLDER} className="has-arrow">
					<i className={"bx " + icon} />
					<span>{this.props.t(text)}</span>
				</Link>
			)
		}

		const itemCategoryWithoutChildren = (text, icon, link) => {
			return (
				<Link to={link}>
					<i className={"bx " + icon} />
					<span>{this.props.t(text)}</span>
				</Link>
			)
		}

		const subItem = (text, link, isEditLink) => {
			const pathname = this.props.location.pathname;
			if (isEditLink) {
				const id = this.props.match.params.id;
				link = link.replace(":id", id)
			}
			const renderableItem = (
				<li>
					<Link to={link}>
						{this.props.t(text)}
					</Link>
				</li>
			)
			return (
				(!isEditLink || pathname === link) ? renderableItem : null
			)
		}

		const DashboardMenu =
			<li>
				{itemCategoryWithoutChildren("Dashboard", "bxs-home-circle", endpointsFrontend.DASHBOARD)}
			</li>

		const CM20PaymentsMenu =
			<li>
				{itemCategory("Bank payments (CM2)", "bxs-credit-card")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Unsigned payments", endpointsFrontend.CM20PAYMENT_BROWSE_UNSIGNED)}
					{subItem("Processed payments", endpointsFrontend.CM20PAYMENT_BROWSE_SIGNED)}
					{subItem("Failed payments", endpointsFrontend.CM20PAYMENT_BROWSE_ERRONEOUS)}
				</ul>
			</li>

		const PaymentMenu =
			<li>
				{itemCategory("Bank payments", "bx-credit-card")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Prepared payments", endpointsFrontend.PAYMENT_BROWSE_PREPARED)}
					{availableModules.includes("PAYMENT_SIGNABLE_IN_CRM") && subItem("Payments to sign", endpointsFrontend.PAYMENT_BROWSE_UNSIGNED)}
					{/* {availableModules.includes("PAYMENT_SIGNABLE_IN_BANK") && subItem("Payments to sign in bank", endpointsFrontend.PAYMENT_BROWSE_SENT_TO_BANK_UNSIGNED)} */}
					{/* {availableModules.includes("PAYMENT_SIGNABLE_IN_CRM") && subItem("Payments signed and sent to bank", endpointsFrontend.PAYMENT_BROWSE_SENT_TO_BANK_SIGNED)} */}
					{/* {availableModules.includes("PAYMENT_EXPORTED_TO_BANK") && subItem("Payments exported to bank as file", endpointsFrontend.PAYMENT_BROWSE_EXPORTED_TO_BANK_AS_FILE)} */}
					{subItem("Processed payments", endpointsFrontend.PAYMENT_BROWSE_PROCESSED)}
					{subItem("Failed payments", endpointsFrontend.PAYMENT_BROWSE_ERRONEOUS)}
					{subItem("Edit payment", endpointsFrontend.PAYMENT_EDIT, true)}
					{subItem("New payment", endpointsFrontend.PAYMENT_NEW)}
				</ul>
			</li>

		const BankStatementMenu =
			<li>
				{itemCategory("Bank statements", "bxs-bank")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse statements", endpointsFrontend.BANK_STATEMENT_BROWSE)}
					{subItem("Reconcile statement", endpointsFrontend.BANK_STATEMENT_RECONCILE, true)}
				</ul>
			</li>

		const CustomerAgreementMenu =
			<li>
				{itemCategory("Customer agreements", "bxs-buildings")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse agreements", endpointsFrontend.CUSTOMER_AGREEMENT_BROWSE)}
					{subItem("Edit agreement", endpointsFrontend.CUSTOMER_AGREEMENT_EDIT, true)}
					{subItem("New agreement", endpointsFrontend.CUSTOMER_AGREEMENT_NEW)}
				</ul>
			</li>;

		const CustomerInvoiceMenu =
			<li>
				{itemCategory("Customer invoices", "bxs-file")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Overdue invoices", endpointsFrontend.CUSTOMER_INVOICE_BROWSE_OVERDUE)}
					{subItem("Unpaid invoices", endpointsFrontend.CUSTOMER_INVOICE_BROWSE_UNPAID)}
					{subItem("All invoices", endpointsFrontend.CUSTOMER_INVOICE_BROWSE_ALL)}
					{subItem("Edit invoice", endpointsFrontend.CUSTOMER_INVOICE_EDIT, true)}
					{subItem("New invoice", endpointsFrontend.CUSTOMER_INVOICE_NEW)}
				</ul>
			</li>;

		const CustomerQuoteMenu =
			<li>
				{itemCategory("Customer quotes", "bxs-file-image")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse quotes", endpointsFrontend.CUSTOMER_QUOTE_BROWSE)}
					{subItem("Edit quote", endpointsFrontend.CUSTOMER_QUOTE_EDIT, true)}
					{subItem("New quote", endpointsFrontend.CUSTOMER_QUOTE_NEW)}
				</ul>
			</li>;

		const SupplierInvoiceMenu =
			<li>
				{itemCategory("Supplier invoices", "bx-file")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Unpaid invoices", endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID)}
					{subItem("All invoices", endpointsFrontend.SUPPLIER_INVOICE_BROWSE_ALL)}
					{subItem("Edit invoice", endpointsFrontend.SUPPLIER_INVOICE_EDIT, true)}
					{subItem("New invoice", endpointsFrontend.SUPPLIER_INVOICE_NEW)}
				</ul>
			</li>;

		const TaskMenu =
			<li>
				{itemCategory("Tasks", "bx-task")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse tasks", endpointsFrontend.TASK_BROWSE)}
					{subItem("Edit task", endpointsFrontend.TASK_EDIT, true)}
					{subItem("New task", endpointsFrontend.TASK_NEW)}
					{subItem("Browse timelogs", endpointsFrontend.TIMELOG_BROWSE)}
					{subItem("Edit timelog", endpointsFrontend.TIMELOG_EDIT, true)}
					{subItem("New timelog", endpointsFrontend.TIMELOG_NEW)}
					{subItem("Browse tags", endpointsFrontend.TAG_BROWSE)}
					{subItem("Edit tag", endpointsFrontend.TAG_EDIT, true)}
					{subItem("New tag", endpointsFrontend.TAG_NEW)}
				</ul>
			</li>

		/* Data */

		const BankAccountMenu =
			<li>
				{itemCategory("Bank accounts", "bxs-wallet")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse accounts", endpointsFrontend.BANK_ACCOUNT_BROWSE)}
					{subItem("Edit account", endpointsFrontend.BANK_ACCOUNT_EDIT, true)}
					{subItem("New account", endpointsFrontend.BANK_ACCOUNT_NEW)}
				</ul>
			</li>;

		const CountryMenu =
			<li>
				{itemCategory("Countries", "bx-globe")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse countries", endpointsFrontend.COUNTRY_BROWSE)}
					{subItem("Edit country", endpointsFrontend.COUNTRY_EDIT, true)}
					{subItem("New country", endpointsFrontend.COUNTRY_NEW)}
				</ul>
			</li>;

		const CurrencyMenu =
			<li>
				{itemCategory("Currencies", "bx-euro")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse currencies", endpointsFrontend.CURRENCY_BROWSE)}
					{subItem("Edit currency", endpointsFrontend.CURRENCY_EDIT, true)}
					{subItem("New currency", endpointsFrontend.CURRENCY_NEW)}
					{subItem("Browse exchange rates", endpointsFrontend.CURRENCY_EXCHRATE_BROWSE)}
					{subItem("Edit exchange rate", endpointsFrontend.CURRENCY_EXCHRATE_EDIT, true)}
					{subItem("New exchange rate", endpointsFrontend.CURRENCY_EXCHRATE_NEW)}
				</ul>
			</li>;

		const CustomerMenu =
			<li>
				{itemCategory("Customers", "bxs-buildings")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse customers", endpointsFrontend.CUSTOMER_BROWSE)}
					{subItem("Edit customer", endpointsFrontend.CUSTOMER_EDIT, true)}
					{subItem("New customer", endpointsFrontend.CUSTOMER_NEW)}
				</ul>
			</li>;

		const ProductMenu =
			<li>
				{itemCategory("Products", "bx-package")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse products", endpointsFrontend.PRODUCT_BROWSE)}
					{subItem("Edit product", endpointsFrontend.PRODUCT_EDIT, true)}
					{subItem("New product", endpointsFrontend.PRODUCT_NEW)}
				</ul>
			</li>

		const SupplierMenu =
			<li>
				{itemCategory("Suppliers", "bx-buildings")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse suppliers", endpointsFrontend.SUPPLIER_BROWSE)}
					{subItem("Edit supplier", endpointsFrontend.SUPPLIER_EDIT, true)}
					{subItem("New supplier", endpointsFrontend.SUPPLIER_NEW)}
					{subItem("Browse bank accounts", endpointsFrontend.SUPPLIER_BANK_ACCOUNT_BROWSE)}
					{subItem("Edit bank account", endpointsFrontend.SUPPLIER_BANK_ACCOUNT_EDIT, true)}
					{subItem("New bank account", endpointsFrontend.SUPPLIER_BANK_ACCOUNT_NEW)}
				</ul>
			</li>;

		const UnitMenu =
			<li>
				{itemCategory("Units", "bx-ruler")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse units", endpointsFrontend.UNIT_BROWSE)}
					{subItem("Edit unit", endpointsFrontend.UNIT_EDIT, true)}
					{subItem("New unit", endpointsFrontend.UNIT_NEW)}
				</ul>
			</li>

		const VATRateMenu =
			<li>
				{itemCategory("VAT rates", "bx-calculator")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse VAT rates", endpointsFrontend.VAT_RATE_BROWSE)}
					{subItem("Edit VAT rate", endpointsFrontend.VAT_RATE_EDIT, true)}
					{subItem("New VAT rate", endpointsFrontend.VAT_RATE_NEW)}
				</ul>
			</li>

		/* Settings */

		const AllocationCriterionMenu =
			<li>
				{itemCategory("Allocation criteria", "bx-bullseye")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Bank charges", endpointsFrontend.ALLOCATION_CRITERION_BROWSE_BANK_CHARGE)}
					{subItem("Customer invoices", endpointsFrontend.ALLOCATION_CRITERION_BROWSE_CUSTOMER_INVOICE)}
					{subItem("Payments", endpointsFrontend.ALLOCATION_CRITERION_BROWSE_PAYMENT)}
					{subItem("Supplier invoices", endpointsFrontend.ALLOCATION_CRITERION_BROWSE_SUPPLIER_INVOICE)}
				</ul>
			</li>;

		const CompanyMenu =
			<li>
				{itemCategory("Companies", "bxs-business")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse companies", endpointsFrontend.COMPANY_BROWSE)}
					{subItem("New company", endpointsFrontend.COMPANY_NEW)}
					{subItem("Edit company", endpointsFrontend.COMPANY_NEW, true)}
				</ul>
			</li>;

		const CompanySettingsMenu =
			<li>
				{itemCategory("Company settings", "bx-cog")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Edit company settings", endpointsFrontend.COMPANY_SETTINGS_EDIT)}
				</ul>
			</li>

		const ExternalDataSyncRequestMenu =
			<li>
				{itemCategory("External data sync", "bx-sync")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Status", endpointsFrontend.EXTERNAL_DATA_SYNC_REQUEST_BROWSE)}
				</ul>
			</li>

		const ScheduledTaskMenu =
			<li>
				{itemCategory("Scheduled tasks", "bx-time")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse scheduled tasks", endpointsFrontend.SCHEDULED_TASK_BROWSE)}
				</ul>
			</li>


		const UserAccountMenu =
			<li>
				{itemCategory("User accounts", "bx-user-circle")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse users", endpointsFrontend.USER_ACCOUNT_BROWSE)}
					{subItem("Edit user", endpointsFrontend.USER_ACCOUNT_EDIT, true)}
					{subItem("Invite new user", endpointsFrontend.USER_ACCOUNT_INVITE)}
				</ul>
			</li>;

		const TenantMenu =
			<li>
				{itemCategory("Tenants", "bxs-factory")}
				<ul className="sub-menu" aria-expanded="false">
					{subItem("Browse tenants", endpointsFrontend.TENANT_BROWSE)}
					{subItem("New tenant", endpointsFrontend.TENANT_NEW)}
					{subItem("Edit tenant", endpointsFrontend.TENANT_EDIT, true)}
				</ul>
			</li>;

		const LoadingSpinner = (
			<div align="center">
				<br />
				<br />
				{editFormControls.bigSpinner()}
			</div>
		)

		const renderableMenu = (
			< React.Fragment >
				<SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
					<div id="sidebar-menu">
						<ul className="metismenu list-unstyled" id="side-menu">

							{availableModules.includes("DASHBOARD") && DashboardMenu}

							<li className="menu-title">{this.props.t("Modules")}</li>

							{availableModules.includes("CM20PAYMENTS") && CM20PaymentsMenu}
							{availableModules.includes("PAYMENT") && PaymentMenu}
							{availableModules.includes("BANK_STATEMENT") && BankStatementMenu}
							{availableModules.includes("CUSTOMER_AGREEMENT") && CustomerAgreementMenu}
							{availableModules.includes("CUSTOMER_INVOICE") && CustomerInvoiceMenu}
							{availableModules.includes("CUSTOMER_QUOTE") && CustomerQuoteMenu}
							{availableModules.includes("SUPPLIER_INVOICE") && SupplierInvoiceMenu}
							{availableModules.includes("TASK") && TaskMenu}

							<li className="menu-title">{this.props.t("Data")}</li>

							{availableModules.includes("BANK_ACCOUNT") && BankAccountMenu}
							{availableModules.includes("COUNTRY") && CountryMenu}
							{availableModules.includes("CURRENCY") && CurrencyMenu}
							{availableModules.includes("CUSTOMER") && CustomerMenu}
							{availableModules.includes("PRODUCT") && ProductMenu}
							{availableModules.includes("SUPPLIER") && SupplierMenu}
							{availableModules.includes("UNIT") && UnitMenu}
							{availableModules.includes("VAT_RATE") && VATRateMenu}

							<li className="menu-title">{this.props.t("Settings")}</li>

							{availableModules.includes("AUTO_ALLOCATION") && AllocationCriterionMenu}
							{availableModules.includes("COMPANY") && CompanyMenu}
							{availableModules.includes("COMPANY_SETTINGS") && CompanySettingsMenu}
							{availableModules.includes("EXTERNAL_DATA_SYNC") && ExternalDataSyncRequestMenu}
							{availableModules.includes("SCHEDULED_TASK") && ScheduledTaskMenu}
							{availableModules.includes("USER_ACCOUNT") && UserAccountMenu}

							{availableModules.includes("TENANT") && (<li className="menu-title">{this.props.t("Administration")}</li>)}

							{availableModules.includes("TENANT") && TenantMenu}

							{/* <li className="menu-title">{this.props.t("Unused - Skote")}</li>
							<li>
								<Link to="/calendar" className="">
									<i className="bx bx-calendar" />
									<span>{this.props.t("Calendar")}</span>
								</Link>
							</li>
							<li>
								<Link to="/#" className="has-arrow">
									<i className="bx bx-receipt" />
									<span>{this.props.t("E-invoicing")}</span>
								</Link>
								<ul className="sub-menu" aria-expanded="false">
									<li>
										<Link to="/invoices-list">
											{this.props.t("Invoice List")}
										</Link>
									</li>
									<li>
										<Link to="/invoices-detail">
											{this.props.t("Invoice Detail")}
										</Link>
									</li>
								</ul>
							</li>
							<li>
								<Link to="/#" className="has-arrow">
									<i className="bx bx-spreadsheet"></i>
									<span>{this.props.t("Excel imports")}</span>
								</Link>
								<ul className="sub-menu" aria-expanded="false">
									<li>
										<Link to="/email-inbox">{this.props.t("Inbox")}</Link>
									</li>
									<li>
										<Link to="/email-read">{this.props.t("Read Email")} </Link>
									</li>
									<li>
										<Link to="/#">
											<span
												className="badge rounded-pill badge-soft-success float-end"
												key="t-new"
											>
												{this.props.t("New")}
											</span>
											<span key="t-email-templates">
												{this.props.t("Templates")}
											</span>
										</Link>
										<ul className="sub-menu" aria-expanded="false">
											<li>
												<Link to="/email-template-basic">
													{this.props.t("Basic Action")}
												</Link>
											</li>
											<li>
												<Link to="/email-template-alert">
													{this.props.t("Alert Email")}{" "}
												</Link>
											</li>
											<li>
												<Link to="/email-template-billing">
													{this.props.t("Billing Email")}{" "}
												</Link>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li>
								<Link to="/#" className="has-arrow">
									<i className="bx bx-briefcase-alt-2" />
									<span>{this.props.t("Projects")}</span>
								</Link>
								<ul className="sub-menu" aria-expanded="false">
									<li>
										<Link to="/projects-grid">
											{this.props.t("Projects Grid")}
										</Link>
									</li>
									<li>
										<Link to="/projects-list">
											{this.props.t("Projects List")}
										</Link>
									</li>
									<li>
										<Link to="/projects-overview">
											{this.props.t("Project Overview")}
										</Link>
									</li>
									<li>
										<Link to="/projects-create">
											{this.props.t("Create New")}
										</Link>
									</li>
								</ul>
							</li>
							<li>
								<Link to="/#" className="has-arrow">
									<i className="bx bx-task" />
									<span>{this.props.t("Tasks")}</span>
								</Link>
								<ul className="sub-menu" aria-expanded="false">
									<li>
										<Link to="/tasks-list">{this.props.t("Task List")}</Link>
									</li>
									<li>
										<Link to="/tasks-kanban">
											{this.props.t("Kanban Board")}
										</Link>
									</li>
									<li>
										<Link to="/tasks-create">
											{this.props.t("Create Task")}
										</Link>
									</li>
								</ul>
							</li>
							<li>
								<Link to="/#" className="has-arrow">
									<i className="bx bxs-user-detail" />
									<span>{this.props.t("Contacts")}</span>
								</Link>
								<ul className="sub-menu" aria-expanded="false">
									<li>
										<Link to="/contacts-grid">{this.props.t("User Grid")}</Link>
									</li>
									<li>
										<Link to="/contacts-list">{this.props.t("User List")}</Link>
									</li>
									<li>
										<Link to="/contacts-profile">
											{this.props.t("Profile")}
										</Link>
									</li>
								</ul>
							</li>
							<li>
								<Link to="/#">
									<i className="bx bx-user-circle" />
									<span>{this.props.t("Authentication")}</span>
								</Link>
								<ul className="sub-menu">
									<li>
										<Link to="/pages-login">{this.props.t("Login")}</Link>
									</li>
									<li>
										<Link to="/pages-login-2">{this.props.t("Login 2")}</Link>
									</li>
									<li>
										<Link to="/pages-register">{this.props.t("Register")}</Link>
									</li>
									<li>
										<Link to="/pages-register-2">
											{this.props.t("Register 2")}
										</Link>
									</li>
									<li>
										<Link to="/page-recoverpw">
											{this.props.t("Recover Password")}
										</Link>
									</li>
									<li>
										<Link to="/pages-recoverpw-2">
											{this.props.t("Recover Password 2")}
										</Link>
									</li>
									<li>
										<Link to="/auth-lock-screen">
											{this.props.t("Lock Screen")}
										</Link>
									</li>
									<li>
										<Link to="/auth-lock-screen-2">
											{this.props.t("Lock Screen 2")}
										</Link>
									</li>
									<li>
										<Link to="/page-confirm-mail">
											{this.props.t("Confirm Mail")}
										</Link>
									</li>
									<li>
										<Link to="/page-confirm-mail-2">
											{this.props.t("Confirm Mail 2")}
										</Link>
									</li>
									<li>
										<Link to="/auth-email-verification">
											{this.props.t("Email verification")}
										</Link>
									</li>
									<li>
										<Link to="/auth-email-verification-2">
											{this.props.t("Email verification 2")}
										</Link>
									</li>
									<li>
										<Link to="/auth-two-step-verification">
											{this.props.t("Two step verification")}
										</Link>
									</li>
									<li>
										<Link to="/auth-two-step-verification-2">
											{this.props.t("Two step verification 2")}
										</Link>
									</li>
								</ul>
							</li>
							<li>
								<Link to="/#" className="has-arrow">
									<i className="bx bx-file" />
									<span>{this.props.t("Utility")}</span>
								</Link>
								<ul className="sub-menu" aria-expanded="false">
									<li>
										<Link to="/pages-starter">
											{this.props.t("Starter Page")}
										</Link>
									</li>
									<li>
										<Link to="/pages-maintenance">
											{this.props.t("Maintenance")}
										</Link>
									</li>
									<li>
										<Link to="/pages-comingsoon">
											{this.props.t("Coming Soon")}
										</Link>
									</li>
									<li>
										<Link to="/pages-timeline">{this.props.t("Timeline")}</Link>
									</li>
									<li>
										<Link to="/pages-faqs">{this.props.t("FAQs")}</Link>
									</li>
									<li>
										<Link to="/pages-pricing">{this.props.t("Pricing")}</Link>
									</li>
									<li>
										<Link to="/pages-404">{this.props.t("Error 404")}</Link>
									</li>
									<li>
										<Link to="/pages-500">{this.props.t("Error 500")}</Link>
									</li>
								</ul>
							</li> */}

						</ul>
					</div>
				</SimpleBar>
			</React.Fragment >
		);

		return (
			this.props.loadingCompanies || this.props.loadingAvailableModules
				? LoadingSpinner
				: renderableMenu
		);
	}
}

const mapStateToProps = ({ availableModule, company }) => ({
	availableModules: availableModule.availableModules,
	loadingAvailableModules: availableModule.loading,
	loadingCompanies: company.loading
});

export default connect(
	mapStateToProps
)(withRouter(withTranslation()(SidebarContent)));
