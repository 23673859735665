import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as rest from "../../helpers/restHelper";
import * as formatUtils from "../../helpers/formatUtils";
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as actionTypes from './actionTypes';

function* getAllCountersSaga() {
	yield put(actions.counterGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COUNTER_GET_ALL
		);

		const entries = [];
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
		}

		yield put(actions.counterGetSuccess(entries));
	} catch (e) {
		yield put(actions.counterGetError(e));
	}
}

function* getAndUpdateCounterSaga({ counterType }) {
	yield put(actions.counterGetValueInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COUNTER_GET_AND_UPDATE.replace("{counterType}", counterType)
		);

		yield put(actions.counterGetValueSuccess(response.data));
	} catch (e) {
		yield put(actions.counterGetValueError(e));
	}
}

function* getCounterByIdSaga({ id }) {
	yield put(actions.counterGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COUNTER_GET_BY_ID.replace("{counterId}", id)
		);

		yield put(actions.counterGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.counterGetError(e));
	}
}

function* deleteCounterSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.COUNTER_GET_BY_ID.replace("{counterId}", id)
		);
		//history.push(endpointsFrontend.COUNTER_BROWSE);
	} catch (e) {
		yield put(actions.counterDeleteError(e));
	}
}

function* counterSaga() {
	yield (takeLatest(actionTypes.COUNTER_GET_AND_UPDATE, getAndUpdateCounterSaga));
	yield (takeLatest(actionTypes.COUNTER_GET_ALL, getAllCountersSaga));
	yield (takeLatest(actionTypes.COUNTER_GET_BY_ID, getCounterByIdSaga));
	yield (takeLatest(actionTypes.COUNTER_DELETE, deleteCounterSaga));
}

export default counterSaga;