import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actions from '../../store/ScheduledTask/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsScheduledTask from '../../definitions/columns/scheduledTask';
import * as config from '../../config';
import EditScheduledTask from '../../components/Pages/EditScheduledTask';
import * as editFormControls from '../../helpers/editFormControls';
import OverlayWithSpinner from "components/Common/OverlayWithSpinner";
import * as selectRowUtils from '../../helpers/selectRowUtils';
import * as ScheduledTaskType from '../../definitions/enums/ScheduledTaskType';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class ScheduledTaskBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			scheduledTaskType: "",
			breadcrumbItem: "",
			executing: false,
			selectedScheduledTask: null,
			selectedRows: [],
			showEditForm: false
		}
	}

	componentDidMount() {
		this.props.onGetScheduledTasks();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.moving != this.props.moving) {
			this.setState({
				moving: this.props.moving
			});
		}

		if (prevProps.executing != this.props.executing) {
			this.setState({
				executing: this.props.executing
			})
		}
	}

	commandButtons = (cell, row, rowIndex, formatExtraData) => {
		// Could not disable or render the buttons differently on the basis of formatExtraData.movingLines, as apparently it does not get updated when needed
		return (
			<React.Fragment>
				<Button
					color="primary"
					onClick={() => {
						this.props.onExecuteScheduledTask(row.id, ScheduledTaskType.ScheduledTaskType.filter(enumRow => enumRow.id === row.type)[0].description)
					}}
				>
					Execute
				</Button>
			</React.Fragment>
		);
	};

	newScheduledTask = () => {
		this.setState({
			selectedScheduledTask: {
				type: this.state.scheduledTaskType
			},
			showEditForm: true
		})
	}

	onDeleteScheduledTasks = () => {
		if (window.confirm("Are you sure you want to delete the selected tasks?")) {
			this.props.onDeleteScheduledTasks(this.state.selectedRows, this.state.scheduledTaskType);
		}
	}

	render() {

		const pageTitle = "Scheduled Tasks | " + config.AppName;
		const breadcrumbsTitle = "Scheduled tasks";
		const breadcrumbItem = "Browse scheduled tasks";

		const EditAllocationFormAsVar =
			<EditScheduledTask
				onClose={() => this.setState({ showEditForm: false })}
				scheduledTask={this.state.selectedScheduledTask}
			/>

		let columns = [
			columnsScheduledTask.type,
			// columnsScheduledTask.cronExpression,
			columnsScheduledTask.scheduleType,
			columnsScheduledTask.lastExecutionStartDateTime,
			columnsScheduledTask.lastExecutionStatus,
			columnsScheduledTask.nextExecutionTime
		];

		const columnEvents = {
			onClick: (e, column, columnIndex, row, rowIndex) => {
				if (row.id) {
					this.setState({
						selectedScheduledTask: row,
						showEditForm: true
					});
				}
			}
		}

		for (let i in columns) {
			columns[i] = {
				...columns[i],
				events: columnEvents
			}
		}
		columns = columns.concat([
			{
				dataField: "commandButtons",
				text: "",
				formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, {})
			}
		]);

		const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
			},

			onSelectAll: (isSelect, rows, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
			}
		};

		const expandRow = {
			renderer: (row, rowIndex) => {

				return (
					<div className="react-bootstrap-table">
						<table className="table">
							<tbody style={{ borderColor: "#FFFFFF" }}>
								<tr>
									<td style={{ border: "0px transparent" }}></td>
									<td style={{ border: "0px transparent" }}>
										{editFormControls.staticTextControl("lastExecutionStartDateTime", "Last execution start", row.lastExecutionStartDateTime)}
										{row.lastExecutionStatus === "COMPLETED" && editFormControls.staticTextControl("lastExecutionFinishDateTime", "Last execution finish", row.lastExecutionFinishDateTime)}
										{row.lastExecutionStatus === "FAILED" && editFormControls.staticTextControl("lastErrorMessage", "Last error message", row.lastErrorMessage)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				)
			},
			showExpandColumn: true,
			//expandByColumnOnly: true,
			expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
				expanded ? <i className="bx bxs-minus-square" /> : <i className="bx bxs-plus-square" />
			),
			expandHeaderColumnRenderer: ({ isAnyExpands }) => (
				isAnyExpands ? <i className="bx bxs-minus-square" /> : <i className="bx bxs-plus-square" />
			)
		};

		const table =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.scheduledTasks}
					columns={columns}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory()}
					selectRow={selectRow}
					expandRow={expandRow}
					sort={{
						dataField: 'nextExecutionTime',
						order: 'asc'
					}}
				/>
			</div>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>

					{this.state.showEditForm ? EditAllocationFormAsVar : null}

					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										<div
											style={{
												position: "relative"
											}}
										>
											<OverlayWithSpinner
												show={this.state.executing}
												caption={"Executing: " + this.props.executedTaskName}
											/>
											{!this.props.loading && this.props.scheduledTasks ? table : null}

											{editFormControls.newButton(this.props.saving, this.newScheduledTask)}

											{" "}

											{editFormControls.deleteButton(false, this.onDeleteScheduledTasks, true, !this.state.selectedRows.length)}
										</div>

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ scheduledTask }) => ({
	scheduledTasks: scheduledTask.scheduledTasks,
	deleting: scheduledTask.deleting,
	error: scheduledTask.error,
	executedTaskName: scheduledTask.executedTaskName,
	executing: scheduledTask.executing,
	loading: scheduledTask.loading,
	moving: scheduledTask.moving,
	saving: scheduledTask.saving
})

const mapDispatchToProps = dispatch => ({
	onDeleteScheduledTasks: (idList) => dispatch(actions.scheduledTaskDelete(idList)),
	onExecuteScheduledTask: (id, taskName) => dispatch(actions.scheduledTaskExecute(id, taskName)),
	onGetScheduledTasks: () => dispatch(actions.scheduledTaskGetAll())
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ScheduledTaskBrowse));
