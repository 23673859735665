import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
//import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, InputGroup, InputGroupText, Label, Row } from "reactstrap";

import * as actions from '../../store/SupplierInvoice/actions';
import * as actionsBankAccount from '../../store/BankAccount/actions';
import * as actionsCompanySettings from '../../store/CompanySettings/actions';
import * as actionsCurrency from '../../store/Currency/actions';
import * as actionsPayment from '../../store/Payment/actions';
import * as actionsPaymentBulk from '../../store/PaymentBulk/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as browseFormControls from '../../helpers/browseFormControls';
import * as ChargeBearer from "definitions/enums/ChargeBearer";
import ColumnSelectionForm from "components/Pages/ColumnSelectionForm";
import * as columnsSupplierInvoice from '../../definitions/columns/supplierInvoice';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as inputSelectUtils from '../../helpers/inputSelectUtils'
import { PaymentUrgency } from "definitions/enums/PaymentUrgency";
import * as selectRowUtils from '../../helpers/selectRowUtils';
import * as tableUtils from '../../helpers/tableUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class SupplierInvoiceBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			bankAccountId: "",
			chargeBearer: "DEBT",
			currencyId: "",
			doGroupPayments: false,
			paymentTotal: null,
			selectedInvoices: [],
			startDate: new Date(),
			showPayControls: false,
			urgency: "NURG",

			columns: null,
			showColumnSelectionForm: false,
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	setCurrencyFromBankAccount = bankAccountId => {
		const filteredBankAccounts = this.props.bankAccounts.filter((bankAccount) => (bankAccount.id == bankAccountId));
		const thisBankAccount = filteredBankAccounts ? filteredBankAccounts[0] : null;
		if (thisBankAccount && thisBankAccount.currencyId) {
			this.setState({
				currencyId: thisBankAccount.currencyId
			});
		}
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		if (e.target.name === "bankAccountId") {
			this.setCurrencyFromBankAccount(thisValue);
		}
		this.setState({
			[thisObjectName]: thisValue,
			changed: true
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
	}

	initData = () => {
		if (this.props.location.pathname === endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID) {
			this.props.onGetUnpaidSupplierInvoices();
			this.setState({
				showPayControls: true
			})
		} else {
			this.props.onGetAllSupplierInvoices();
			this.setState({
				showPayControls: false
			})
		}
		this.props.onGetBankAccounts();
		this.props.onGetCompanySettings();
		this.props.onGetCurrencies();
	}

	componentDidMount() {
		if (this.props.selectedCompany) {
			this.initData();
		}
		const defaultColumnSetup = "";
		this.setState({
			columns: tableUtils.getSavedColumnSetup(columnsSupplierInvoice, this.props.location.pathname, defaultColumnSetup)
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		// 2023.11.27: fix: 231123-CompanyId
		if (prevProps.selectedCompany != this.props.selectedCompany) {
			this.initData();
		}

		if (prevProps.bankAccounts !== this.props.bankAccounts) {
			if (!this.state.bankAccountId && this.props.bankAccounts[0]) {
				this.setState({
					bankAccountId: this.props.bankAccounts[0].id
				})
				this.setCurrencyFromBankAccount(this.props.bankAccounts[0].id);
			}
		}

		if (prevProps.currencies !== this.props.currencies) {
			if (!this.state.currencyId && this.props.currencies[0]) {
				this.setState({
					currencyId: this.props.currencies[0].id
				})
			}
		}

		if (prevProps.companySettings !== this.props.companySettings) {
			this.setState({
				doGroupPayments: (this.props.companySettings.paymentsDoGroup ? true : false)
			})
		}

		if (prevProps.total != this.props.total) {
			this.setState({
				paymentTotal: this.props.total
			});
		}

        if ((prevProps.error !== this.props.error) || (prevProps.errorPayments !== this.props.errorPayments)) {
            window.scrollBy(0, -document.body.scrollHeight);
        }
	}

	handleChange = date => {
		this.setState({
			startDate: date,
		});
	}

	launchColumnSelectionForm = () => {
		this.setState({
			showColumnSelectionForm: true
		});
	}

	applySelectedColumns = (selectedColumns) => {
		tableUtils.setSavedColumnSetup(selectedColumns, this.props.location.pathname);
		this.setState({
			columns: tableUtils.applySavedColumnSetup(columnsSupplierInvoice, selectedColumns)
		});
	}

	render() {

		const pageTitle = "Supplier Invoices | " + config.AppName;
		const breadcrumbsTitle = "Supplier invoice";
		const breadcrumbsItem = "Browse supplier invoices";

		const bankAccountOptions = inputSelectUtils.generateOptionsFromData(this.props.bankAccounts, bankAccountRow => bankAccountRow.accountNo + " (" + bankAccountRow.currencyCode + ")");
		const chargeBearerOptions = inputSelectUtils.generateOptionsFromData(ChargeBearer.ChargeBearer, row => row.longDescription);
		const currencyOptions = inputSelectUtils.generateOptionsFromData(this.props.currencies, currencyRow => (currencyRow.code + (currencyRow.name ? " (" + currencyRow.name + ")" : "")));
		const urgencyOptions = inputSelectUtils.generateOptionsFromData(PaymentUrgency, row => row.description);

		let columns;
		if (this.props.location.pathname === endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID) {
			columns = [
				columnsSupplierInvoice.supplierName,
				columnsSupplierInvoice.invoiceNo,
				columnsSupplierInvoice.currencyCode,
				columnsSupplierInvoice.invoiceAmount,
				columnsSupplierInvoice.unpaidAmount,
				columnsSupplierInvoice.invoiceDate,
				columnsSupplierInvoice.dueDate
			];
		} else {
			columns = [
				columnsSupplierInvoice.supplierName,
				columnsSupplierInvoice.invoiceNo,
				columnsSupplierInvoice.currencyCode,
				columnsSupplierInvoice.invoiceAmount,
				columnsSupplierInvoice.invoiceDate,
				columnsSupplierInvoice.dueDate
			];
		}

		const keysColumnsSupplierInvoice = Object.keys(columnsSupplierInvoice);
		for (let i in keysColumnsSupplierInvoice) {
			const thisColumn = columnsSupplierInvoice[keysColumnsSupplierInvoice[i]];
			if (this.state.columns && this.state.columns.includes(thisColumn)) {
				columns.push(thisColumn);
			}
		}

		const ColumnSelectionFormWithProps =
			<ColumnSelectionForm
				availableColumns={[
					columnsSupplierInvoice.supplierExternalId,
					columnsSupplierInvoice.invoiceNoAlt,
					columnsSupplierInvoice.category,
					columnsSupplierInvoice.type,
					columnsSupplierInvoice.discountDueDate1,
					columnsSupplierInvoice.discountDueDate2,
					columnsSupplierInvoice.discountDueDate3,
					columnsSupplierInvoice.discountPercent1,
					columnsSupplierInvoice.discountPercent2,
					columnsSupplierInvoice.discountPercent3
				]}
				selectedColumns={this.state.columns}
				applySelectedColumns={(selectedColumns) => this.applySelectedColumns(selectedColumns)}
				onClose={() => this.setState({ showColumnSelectionForm: false })}
			/>

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				this.props.history.push(endpointsFrontend.SUPPLIER_INVOICE_EDIT.replace(":id", row.id));
			}
		};

		const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedInvoices, row, isSelect);
				this.setState({
					selectedInvoices: selectedRows
				});
				this.props.onGetTotal(selectedRows, this.state.bankAccountId, this.state.currencyId, this.state.doGroupPayments, this.props.history);
			},

			onSelectAll: (isSelect, rows, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedInvoices, rows, isSelect)
				this.setState({
					selectedInvoices: selectedRows
				});
				this.props.onGetTotal(selectedRows, this.state.bankAccountId, this.state.currencyId, this.state.doGroupPayments, this.props.history);
			}
		};

		let table;
		if (this.state.showPayControls) {
			table =
				<BootstrapTable
					keyField='id'
					data={this.props.supplierInvoices}
					columns={columns}
					pagination={paginationFactory({
						sizePerPageList: [{
							text: '10', value: 10
						}, {
							text: '25', value: 25
						}, {
							text: '50', value: 50
						}, {
							text: 'All', value: this.props.supplierInvoices && this.props.supplierInvoices.length ? this.props.supplierInvoices.length : 100
						}]
					})}
					rowEvents={rowEvents}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory()}
					selectRow={selectRow}
				/>
		} else {
			table =
				<BootstrapTable
					keyField='id'
					data={this.props.supplierInvoices}
					columns={columns}
					pagination={paginationFactory()}
					rowEvents={rowEvents}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory()}
				/>
		}

		const tableDiv =
			<React.Fragment>
				{browseFormControls.columnSelectionButton(this.launchColumnSelectionForm)}
				<div className="mt-3">
					{table}
				</div>
			</React.Fragment>

		const payControls =
			<React.Fragment>
				<Row>
					<Col>
						{editFormControls.selectControlWithoutLabel("bankAccountId", this.onChange, this.state.bankAccountId, bankAccountOptions)}
					</Col>
					<Col>
						{editFormControls.selectControlWithoutLabel("currencyId", this.onChange, this.state.currencyId, currencyOptions)}
					</Col>
					<Col>
						<InputGroup>
							<InputGroupText
								style={{
									backgroundColor: "#FFFFFF"
								}}
							>
								{editFormControls.checkboxControlWithoutLabel("doGroupPayments", this.onChange, this.state.doGroupPayments)}
							</InputGroupText>
							<InputGroupText
								style={{
									backgroundColor: "#FFFFFF"
								}}
							>
								Group payments
							</InputGroupText>
						</InputGroup>
					</Col>
					<Col>
						{editFormControls.selectControlWithoutLabel("urgency", this.onChange, this.state.urgency, urgencyOptions)}
					</Col>
					<Col>
						{editFormControls.selectControlWithoutLabel("chargeBearer", this.onChange, this.state.chargeBearer, chargeBearerOptions)}
					</Col>

				</Row>
				<br />
				<Row>
					<Col
						lg="2"
					>
						<Label>
							{this.state.paymentTotal ? this.state.paymentTotal.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null}
							{" "}
							{this.state.paymentTotal ? this.state.paymentTotal.currencyCode : null}
						</Label>
					</Col>
					<Col>
						<Button
							color="primary"
							type="submit"
							disabled={this.state.selectedInvoices.length === 0}
							onClick={() => this.props.onPayInvoices(this.state.selectedInvoices, this.state.bankAccountId, this.state.currencyId, this.state.doGroupPayments, this.state.urgency, this.state.chargeBearer, this.props.history)}
						>
							Prepare payments
							{" "}
							{this.props.saving ? editFormControls.buttonSpinner() : null}
						</Button>
					</Col>
				</Row>
			</React.Fragment>

		const loading = this.props.loading || this.props.loadingBankAccounts || this.props.loadingCurrencies || this.props.loadingCompanySettings;

		return (

			<React.Fragment>
				{this.state.showColumnSelectionForm && ColumnSelectionFormWithProps}
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error, this.props.errorPayments)}

										{editFormControls.formLoadingSpinner(loading)}

										{/*!loading && this.props.supplierInvoices ? (
											<ToolkitProvider
												keyField="id"
												data={this.props.supplierInvoices}
												columns={columns}
												exportCSV={{
													fileName: 'custom.csv',
													separator: ';',
													ignoreHeader: true,
													noAutoBOM: false,
													onlyExportFiltered: true
												}}
											>
												{props => (
													<React.Fragment>
														<CSVExport.ExportCSVButton
															{...props.csvProps}
														>
															Export
														</CSVExport.ExportCSVButton>
														{table}
													</React.Fragment>
												)}
											</ToolkitProvider>
												) : null*/}

										{!loading && this.props.supplierInvoices ? tableDiv : null}

										<br />

										{!loading && this.state.showPayControls ? payControls : null}

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ bankAccount, company, companySettings, currency, payment, paymentBulk, supplierInvoice }) => ({
	bankAccounts: bankAccount.bankAccounts,
	companySettings: companySettings.companySettings,
	currencies: currency.currencies,
	error: supplierInvoice.error,
	errorPayments: paymentBulk.error,
	loading: supplierInvoice.loading,
	loadingBankAccounts: bankAccount.loading,
	loadingCompanySettings: companySettings.loading,
	loadingCurrencies: currency.loading,
	saving: paymentBulk.saving,
	selectedCompany: company.selectedCompany,
	supplierInvoices: supplierInvoice.supplierInvoices,
	total: payment.total
});

const mapDispatchToProps = dispatch => ({
	onGetBankAccounts: () => dispatch(actionsBankAccount.bankAccountGetAll()),
	onGetCompanySettings: () => dispatch(actionsCompanySettings.companySettingsGet()),
	onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
	onGetAllSupplierInvoices: () => dispatch(actions.supplierInvoiceGetAll()),
	onGetUnpaidSupplierInvoices: () => dispatch(actions.supplierInvoiceGetUnpaid()),
	onPayInvoices: (selectedInvoices, bankAccountId, currencyId, doGroupPayments, urgency, chargeBearer, history) =>
		dispatch(actionsPaymentBulk.paymentBulkCreateFromInvoices(selectedInvoices, bankAccountId, currencyId, doGroupPayments, urgency, chargeBearer, history)),
	onGetTotal: (selectedInvoices, bankAccountId, currencyId, doGroupPayments, history) =>
		dispatch(actionsPayment.paymentGetTotalFromSimulated(selectedInvoices, bankAccountId, currencyId, doGroupPayments, history))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SupplierInvoiceBrowse));
