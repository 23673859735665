import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";

import * as actionsCompanySettings from '../../store/CompanySettings/actions';
import * as actionsCountry from '../../store/Country/actions';
import * as actionsCurrency from '../../store/Currency/actions';
import * as actionsVATRate from '../../store/VATRate/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as chargeBearer from "../../definitions/enums/ChargeBearer";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
import * as mergeStatementPeriod from '../../definitions/enums/MergeStatementPeriod';
import timezones from '../../definitions/timezones.json';

class CompanySettingsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            localCurrencyId: "",
            addressCountryId: "",
            addressCountryCode: "",
            addressRegion: "",
            addressCity: "",
            addressPostCode: "",
            addressStreet: "",
            phoneNo: "",
            websiteUrl: "",
            dateFrom: "",
            paymentDetailsTemplateIntlPmt: "",
            paymentDetailsTemplateLocalPmt: "",
            paymentDateToUse: "",
            paymentsConvertToAccountCurrency: "",
            paymentsDoGroup: "",
            defaultVATRateIdForLocalCompanies: "",
            ccAllEmailsTo: "",
            paymentIdPrefix: "",
            defaultChargeBearer: "",
            paymentsDefaultPurposeCode: "",
            paymentsUsePurposeCodeFromInvoice: false,
            paymentsAutoSetSHARToSEPA: "",
            exportStatementToFile: "",
            statementExportTemplate_Outgoing: "",
            statementExportTemplate_OutgoingAndCharges: "",
            statementExportTemplate_Incoming: "",
            statementExportTemplate_IncomingAndCharges: "",
            statementExportTemplate_Charges: "",
            statementExportFilename_Outgoing: "PL.TXT",
            statementExportFilename_OutgoingAndCharges: "PL_GL.TXT",
            statementExportFilename_Incoming: "SL.TXT",
            statementExportFilename_IncomingAndCharges: "SL_GL.TXT",
            statementExportFilename_Charges: "GL.TXT",
            mergeStatementPeriod: "",
            localTimezone: "",

            changed: false,
            companySettings: [],
            error: null,
            warningText: "",
            warningLink: "",
            warningLinkText: ""
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            changed: true,
            [thisObjectName]: thisValue
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedCompanySettings = {
            localCurrencyId: this.state.localCurrencyId,
            addressCountryId: this.state.addressCountryId,
            addressRegion: this.state.addressRegion,
            addressCity: this.state.addressCity,
            addressPostCode: this.state.addressPostCode,
            addressStreet: this.state.addressStreet,
            phoneNo: this.state.phoneNo,
            websiteUrl: this.state.websiteUrl,
            paymentDetailsTemplateIntlPmt: this.state.paymentDetailsTemplateIntlPmt,
            paymentDetailsTemplateLocalPmt: this.state.paymentDetailsTemplateLocalPmt,
            paymentDateToUse: this.state.paymentDateToUse,
            paymentsConvertToAccountCurrency: this.state.paymentsConvertToAccountCurrency,
            paymentsDoGroup: this.state.paymentsDoGroup,
            defaultVATRateIdForLocalCompanies: this.state.defaultVATRateIdForLocalCompanies,
            ccAllEmailsTo: this.state.ccAllEmailsTo,
            paymentIdPrefix: this.state.paymentIdPrefix,
            defaultChargeBearer: this.state.defaultChargeBearer,
            paymentsDefaultPurposeCode: this.state.paymentsDefaultPurposeCode,
            paymentsUsePurposeCodeFromInvoice: this.state.paymentsUsePurposeCodeFromInvoice,
            paymentsAutoSetSHARToSEPA: this.state.paymentsAutoSetSHARToSEPA,
            exportStatementToFile: this.state.exportStatementToFile,
            statementExportTemplate_Outgoing: this.state.statementExportTemplate_Outgoing,
            statementExportTemplate_OutgoingAndCharges: this.state.statementExportTemplate_OutgoingAndCharges,
            statementExportTemplate_Incoming: this.state.statementExportTemplate_Incoming,
            statementExportTemplate_IncomingAndCharges: this.state.statementExportTemplate_IncomingAndCharges,
            statementExportTemplate_Charges: this.state.statementExportTemplate_Charges,
            statementExportFilename_Outgoing: this.state.statementExportFilename_Outgoing,
            statementExportFilename_OutgoingAndCharges: this.state.statementExportFilename_OutgoingAndCharges,
            statementExportFilename_Incoming: this.state.statementExportFilename_Incoming,
            statementExportFilename_IncomingAndCharges: this.state.statementExportFilename_IncomingAndCharges,
            statementExportFilename_Charges: this.state.statementExportFilename_Charges,
            mergeStatementPeriod: this.state.mergeStatementPeriod,
            localTimezone: this.state.localTimezone,
        };
        if (this.state.id) {
            newOrUpdatedCompanySettings = {
                id: this.state.id,
                ...newOrUpdatedCompanySettings
            }
        }
        this.setState({
            changed: false
        }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
        this.props.onCreateCompanySettings(newOrUpdatedCompanySettings, this.props.history);
    }

    componentDidMount() {
        this.props.onGetCompanySettings();
        this.props.onGetCountries();
        this.props.onGetCurrencies();
        this.props.onGetVATRates();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.error !== this.props.error) {
            let error = this.props.error;
            this.setState({
                error: error
            });
        }

        // VAT rates, countries and currencies are checked in the reverse order to the priorities 
        // in which the warning messages about their absense should be displayed.
        // E.g. if neither currencies, countries or VAT rates are entered, the user should see the message that currencies are empty,
        // and only if some currencies are present, they should see the message that countries are empty, and so on.

        if (prevProps.vatRates !== this.props.vatRates) {
            if (this.props.vatRates && this.props.vatRates.length) {
                if (!this.state.defaultVATRateIdForLocalCompanies) {
                    this.setState({
                        defaultVATRateIdForLocalCompanies: this.props.vatRates[0].id
                    })
                }
            } else {
                this.setState({
                    warningText: "No VAT rates available.",
                    warningLink: endpointsFrontend.VAT_RATE_NEW,
                    warningLinkText: "Click here to enter a new VAT rate."
                });
            }
        }

        if (prevProps.countries !== this.props.countries) {
            if (this.props.countries && this.props.countries.length) {
                if (!this.state.addressCountryId) {
                    this.setState({
                        addressCountryId: this.props.countries[0].id
                    });
                }
            } else {
                this.setState({
                    warningText: "No countries available.",
                    warningLink: endpointsFrontend.COUNTRY_NEW,
                    warningLinkText: "Click here to enter a new country."
                });
            }
        }

        if (prevProps.currencies !== this.props.currencies) {
            if (this.props.currencies && this.props.currencies.length) {
                if (!this.state.localCurrencyId) {
                    this.setState({
                        localCurrencyId: this.props.currencies[0].id
                    });
                }
            } else {
                this.setState({
                    warningText: "No currencies available.",
                    warningLink: endpointsFrontend.CURRENCY_NEW,
                    warningLinkText: "Click here to enter a new currency."
                });
            }
        }

        if (prevProps.companySettings !== this.props.companySettings) {
            if (this.props.companySettings) {
                this.setState({
                    id: this.props.companySettings.id,
                    addressCountryCode: this.props.companySettings.addressCountryCode,
                    addressRegion: this.props.companySettings.addressRegion,
                    addressCity: this.props.companySettings.addressCity,
                    addressPostCode: this.props.companySettings.addressPostCode,
                    addressStreet: this.props.companySettings.addressStreet,
                    phoneNo: this.props.companySettings.phoneNo,
                    websiteUrl: this.props.companySettings.websiteUrl,
                    dateFrom: this.props.companySettings.dateFrom,
                    paymentDetailsTemplateIntlPmt: this.props.companySettings.paymentDetailsTemplateIntlPmt,
                    paymentDetailsTemplateLocalPmt: this.props.companySettings.paymentDetailsTemplateLocalPmt,
                    paymentDateToUse: this.props.companySettings.paymentDateToUse,
                    paymentsConvertToAccountCurrency: this.props.companySettings.paymentsConvertToAccountCurrency,
                    paymentsDoGroup: this.props.companySettings.paymentsDoGroup,
                    ccAllEmailsTo: this.props.companySettings.ccAllEmailsTo,
                    paymentIdPrefix: this.props.companySettings.paymentIdPrefix,
                    defaultChargeBearer: this.props.companySettings.defaultChargeBearer,
                    paymentsDefaultPurposeCode: this.props.companySettings.paymentsDefaultPurposeCode,
                    paymentsUsePurposeCodeFromInvoice: this.props.companySettings.paymentsUsePurposeCodeFromInvoice,
                    paymentsAutoSetSHARToSEPA: this.props.companySettings.paymentsAutoSetSHARToSEPA,
                    exportStatementToFile: this.props.companySettings.exportStatementToFile,
                    statementExportTemplate_Outgoing: this.props.companySettings.statementExportTemplate_Outgoing,
                    statementExportTemplate_OutgoingAndCharges: this.props.companySettings.statementExportTemplate_OutgoingAndCharges,
                    statementExportTemplate_Incoming: this.props.companySettings.statementExportTemplate_Incoming,
                    statementExportTemplate_IncomingAndCharges: this.props.companySettings.statementExportTemplate_IncomingAndCharges,
                    statementExportTemplate_Charges: this.props.companySettings.statementExportTemplate_Charges,
                    statementExportFilename_Outgoing: this.props.companySettings.statementExportFilename_Outgoing,
                    statementExportFilename_OutgoingAndCharges: this.props.companySettings.statementExportFilename_OutgoingAndCharges,
                    statementExportFilename_Incoming: this.props.companySettings.statementExportFilename_Incoming,
                    statementExportFilename_IncomingAndCharges: this.props.companySettings.statementExportFilename_IncomingAndCharges,
                    statementExportFilename_Charges: this.props.companySettings.statementExportFilename_Charges,
                    mergeStatementPeriod: this.props.companySettings.mergeStatementPeriod,
                    localTimezone: this.props.companySettings.localTimezone,
                });
                if (this.props.companySettings.localCurrencyId) {
                    this.setState({
                        localCurrencyId: this.props.companySettings.localCurrencyId
                    });
                }
                if (this.props.companySettings.addressCountryId) {
                    this.setState({
                        addressCountryId: this.props.companySettings.addressCountryId
                    });
                }
                if (this.props.companySettings.defaultVATRateIdForLocalCompanies) {
                    this.setState({
                        defaultVATRateIdForLocalCompanies: this.props.companySettings.defaultVATRateIdForLocalCompanies
                    });
                }
            } else {
                this.setState({
                    id: "",
                    localCurrencyId: "",
                    addressCountryId: "",
                    addressCountryCode: "",
                    addressRegion: "",
                    addressCity: "",
                    addressPostCode: "",
                    addressStreet: "",
                    phoneNo: "",
                    websiteUrl: "",
                    dateFrom: "",
                    paymentDetailsTemplateIntlPmt: "",
                    paymentDetailsTemplateLocalPmt: "",
                    paymentDateToUse: "",
                    paymentsConvertToAccountCurrency: "",
                    paymentsDoGroup: "",
                    defaultVATRateIdForLocalCompanies: "",
                    ccAllEmailsTo: "",
                    paymentIdPrefix: "",
                    defaultChargeBearer: "",
                    paymentsDefaultPurposeCode: "",
                    paymentsUsePurposeCodeFromInvoice: "",
                    paymentsAutoSetSHARToSEPA: "",
                    exportStatementToFile: "",
                    statementExportTemplate_Outgoing: "",
                    statementExportTemplate_OutgoingAndCharges: "",
                    statementExportTemplate_Incoming: "",
                    statementExportTemplate_IncomingAndCharges: "",
                    statementExportTemplate_Charges: "",
                    statementExportFilename_Outgoing: "PL.TXT",
                    statementExportFilename_Incoming: "SL.TXT",
                    statementExportFilename_IncomingAndCharges: "SL_GL.TXT",
                    statementExportFilename_Charges: "GL.TXT",
                    mergeStatementPeriod: "",
                    localTimezone: "",
                });
            }
        }

        if ((prevProps.saveSuccess !== this.props.saveSuccess) || (prevProps.error !== this.props.error)) {
            window.scrollBy(0, -document.body.scrollHeight);
        }
    }

    render() {
        const pageTitle = "Edit company settings | " + config.AppName;
        const breadcrumbsTitle = "Company settings";
        const breadcrumbsItem = "Edit company settings";

        const countryOptions = inputSelectUtils.generateOptionsFromData(this.props.countries, countryRow => (countryRow.code + (countryRow.nameLang0 ? " (" + countryRow.nameLang0 + ")" : "")));

        const currencyOptions = inputSelectUtils.generateOptionsFromData(this.props.currencies, currencyRow => (currencyRow.code + (currencyRow.name ? " (" + currencyRow.name + ")" : "")));

        const vatRateOptions = inputSelectUtils.generateOptionsFromData(this.props.vatRates, vatRateRow => (vatRateRow.rate + "%"));

        const paymentDateOptionArray = [
            { id: "TODAY", description: "Current day" },
            { id: "TOMORROW", description: "Next day" },
            { id: "FROM_DUE_DATE_EARLIEST", description: "From due date (earliest date)" },
            { id: "FROM_DUE_DATE_LATEST", description: "From due date (latest date)" }
        ];
        const paymentDateOptions = inputSelectUtils.generateOptionsFromData(paymentDateOptionArray, paymentDateRow => paymentDateRow.description);

        const chargeBearerOptions = inputSelectUtils.generateOptionsFromData(chargeBearer.ChargeBearer, chargeBearerRow => chargeBearerRow.description);

        const mergeStatementPeriodOptions = inputSelectUtils.generateOptionsFromData(mergeStatementPeriod.MergeStatementPeriod, row => row.description);

        const timezoneOptions = inputSelectUtils.generateOptionsFromData(timezones, row => row.TZ, (row1, row2) => (row1.TZ > row2.TZ ? 1 : row1.TZ === row2.TZ ? 0 : -1), "TZ");

        const loading = this.props.loadingCompanySettings || this.props.loadingCountries || this.props.loadingCurrencies || this.props.loadingVATRates;

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                        {editFormControls.selectControl("localCurrencyId", "Local currency", this.onChange, this.state.localCurrencyId, currencyOptions)}
                                        {editFormControls.selectControl("addressCountryId", "Country", this.onChange, this.state.addressCountryId, countryOptions)}
                                        {editFormControls.selectControl("localTimezone", "Timezone", this.onChange, this.state.localTimezone, timezoneOptions)}

                                        <hr />

                                        {editFormControls.textControl("addressRegion", "Region", this.onChange, this.state.addressRegion)}
                                        {editFormControls.textControl("addressCity", "City", this.onChange, this.state.addressCity)}
                                        {editFormControls.textControl("addressPostCode", "Postal code", this.onChange, this.state.addressPostCode)}
                                        {editFormControls.textControl("addressStreet", "Street address", this.onChange, this.state.addressStreet)}
                                        {editFormControls.textControl("phoneNo", "Phone No.", this.onChange, this.state.phoneNo)}
                                        {editFormControls.textControl("websiteUrl", "Website URL", this.onChange, this.state.websiteUrl)}

                                        <hr />

                                        {editFormControls.textAreaControl("paymentDetailsTemplateIntlPmt", "Payment details template (international payments)", this.onChange, this.state.paymentDetailsTemplateIntlPmt)}
                                        {editFormControls.textAreaControl("paymentDetailsTemplateLocalPmt", "Payment details template (local payments)", this.onChange, this.state.paymentDetailsTemplateLocalPmt)}
                                        {editFormControls.selectControl("paymentDateToUse", "Payment date to use", this.onChange, this.state.paymentDateToUse, paymentDateOptions)}
                                        {editFormControls.selectControl("defaultChargeBearer", "Default charge bearer", this.onChange, this.state.defaultChargeBearer, chargeBearerOptions)}

                                        {editFormControls.checkboxControl("paymentsUsePurposeCodeFromInvoice", "Use purpose code from invoice", this.onChange, this.state.paymentsUsePurposeCodeFromInvoice)}
                                        {!this.state.paymentsUsePurposeCodeFromInvoice && editFormControls.textControl("paymentsDefaultPurposeCode", "Default payment purpose code", this.onChange, this.state.paymentsDefaultPurposeCode)}

                                        {editFormControls.checkboxControl("paymentsAutoSetSHARToSEPA", "Automatically set charge bearer to SHAR for SEPA payments", this.onChange, this.state.paymentsAutoSetSHARToSEPA)}
                                        {editFormControls.checkboxControl("paymentsConvertToAccountCurrency", "Convert payments to account currency", this.onChange, this.state.paymentsConvertToAccountCurrency)}
                                        {editFormControls.checkboxControl("paymentsDoGroup", "Group payments by default", this.onChange, this.state.paymentsDoGroup)}
                                        {editFormControls.textControl("paymentIdPrefix", "Payment ID prefix", this.onChange, this.state.paymentIdPrefix)}

                                        <hr />

                                        {editFormControls.selectControl("defaultVATRateIdForLocalCompanies", "Default VAT rate for local companies", this.onChange, this.state.defaultVATRateIdForLocalCompanies, vatRateOptions)}
                                        {editFormControls.textControl("ccAllEmailsTo", "CC all emails to", this.onChange, this.state.ccAllEmailsTo, "Enter email address to CC all emails")}
                                        {editFormControls.selectControl("mergeStatementPeriod", "Merge statements", this.onChange, this.state.mergeStatementPeriod, mergeStatementPeriodOptions)}

                                        <hr />

                                        {editFormControls.checkboxControl("exportStatementToFile", "Export statement to file", this.onChange, this.state.exportStatementToFile)}
                                        {this.state.exportStatementToFile && editFormControls.textAreaControl("statementExportTemplate_Outgoing", "Statement export template (Outgoing payments)", this.onChange, this.state.statementExportTemplate_Outgoing)}
                                        {this.state.exportStatementToFile && editFormControls.textControl("statementExportFilename_Outgoing", "Default filename", this.onChange, this.state.statementExportFilename_Outgoing)}
                                        {this.state.exportStatementToFile && (<hr />)}

                                        {this.state.exportStatementToFile && editFormControls.textAreaControl("statementExportTemplate_OutgoingAndCharges", "Statement export template (Outgoing payments and bank charges)", this.onChange, this.state.statementExportTemplate_OutgoingAndCharges)}
                                        {this.state.exportStatementToFile && editFormControls.textControl("statementExportFilename_OutgoingAndCharges", "Default filename", this.onChange, this.state.statementExportFilename_OutgoingAndCharges)}
                                        {this.state.exportStatementToFile && (<hr />)}

                                        {this.state.exportStatementToFile && editFormControls.textAreaControl("statementExportTemplate_Incoming", "Statement export template (Incoming payments)", this.onChange, this.state.statementExportTemplate_Incoming)}
                                        {this.state.exportStatementToFile && editFormControls.textControl("statementExportFilename_Incoming", "Default filename", this.onChange, this.state.statementExportFilename_Incoming)}
                                        {this.state.exportStatementToFile && (<hr />)}

                                        {this.state.exportStatementToFile && editFormControls.textAreaControl("statementExportTemplate_IncomingAndCharges", "Statement export template (Incoming payments and bank charges)", this.onChange, this.state.statementExportTemplate_IncomingAndCharges)}
                                        {this.state.exportStatementToFile && editFormControls.textControl("statementExportFilename_IncomingAndCharges", "Default filename", this.onChange, this.state.statementExportFilename_IncomingAndCharges)}
                                        {this.state.exportStatementToFile && (<hr />)}

                                        {this.state.exportStatementToFile && editFormControls.textAreaControl("statementExportTemplate_Charges", "Statement export template (Bank charges)", this.onChange, this.state.statementExportTemplate_Charges)}
                                        {this.state.exportStatementToFile && editFormControls.textControl("statementExportFilename_Charges", "Default filename", this.onChange, this.state.statementExportFilename_Charges)}
                                        {this.state.exportStatementToFile && (<hr />)}

                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.state.error)}

                        {editFormControls.warningAlertWithLink(this.state.warningText, this.state.warningLink, this.state.warningLinkText)}

                        {editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "Company settings")}

                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading && editForm}

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ companySettings, country, currency, vatRate }) => ({
    companySettings: companySettings.companySettings,
    countries: country.countries,
    currencies: currency.currencies,
    error: companySettings.error,
    loadingCompanySettings: companySettings.loading,
    loadingCountries: country.loading,
    loadingCurrencies: currency.loading,
    loadingVATRates: vatRate.loading,
    saveSuccess: companySettings.saveSuccess,
    saving: companySettings.saving,
    vatRates: vatRate.vatRates
})

const mapDispatchToProps = dispatch => ({
    onGetCompanySettings: () => dispatch(actionsCompanySettings.companySettingsGet()),
    onGetCountries: () => dispatch(actionsCountry.countryGetAll()),
    onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
    onGetVATRates: () => dispatch(actionsVATRate.vatRateGetAll()),
    onCreateCompanySettings: (companySettings, history) => dispatch(actionsCompanySettings.companySettingsCreate(companySettings, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySettingsEdit);
