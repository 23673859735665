import * as actionTypes from "./actionTypes";

const initialState = {
	error: null,
	deleting: false,
	loading: false,
	notCreatedPaymentBulks: null,
	paymentBulks: null,
	saving: false,
	total: null
}

export default function PaymentBulkReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.PAYMENT_BULK_CREATE_SUCCESS:
			return {
				...state,
				saving: false,
				error: null,
				payments: [action.payment],
				notCreatedPaymentBulks: null
			}
		case actionTypes.PAYMENT_BULK_CREATE_PARTIAL_SUCCESS:
			return {
				...state,
				saving: false,
				error: null,
				notCreatedPaymentBulks: action.notCreatedPaymentBulks
			}
		case actionTypes.PAYMENT_BULK_CREATE_ERROR:
			return {
				...state,
				loading: false,
				saving: false,
				error: action.error
			}
		case actionTypes.PAYMENT_BULK_CREATE_FILES:
			return {
				...state,
				error: null,
				saving: true
			}
		case actionTypes.PAYMENT_BULK_CREATE_FILES_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.PAYMENT_BULK_CREATE_FILES_SUCCESS:
			return {
				...state,
				error: null,
				saving: false
			}
		case actionTypes.PAYMENT_BULK_CREATE_FROM_INVOICES:
			return {
				...state,
				saving: true,
				error: null,
				notCreatedPaymentBulks: null
			}
		case actionTypes.PAYMENT_BULK_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.PAYMENT_BULK_DELETE_LIST:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.PAYMENT_BULK_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.PAYMENT_BULK_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null
			}
		case actionTypes.PAYMENT_BULK_EDIT:
			return {
				...state,
				error: null,
				saving: true
			}
		case actionTypes.PAYMENT_BULK_EDIT_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.PAYMENT_BULK_EDIT_SUCCESS:
			return {
				...state,
				saving: false
			}
		case actionTypes.PAYMENT_BULK_GET_BY_ID:
		case actionTypes.PAYMENT_BULK_GET_PREPARED:
		case actionTypes.PAYMENT_BULK_GET_UNALLOCATED:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.PAYMENT_BULK_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.PAYMENT_BULK_GET_SUCCESS:
			return {
				...state,
				loading: false,
				paymentBulks: action.paymentBulks
			}
		case actionTypes.PAYMENT_BULK_GET_TOTAL:
			return {
				...state,
				loadingTotal: true,
				total: null,
				error: null
			}
		case actionTypes.PAYMENT_BULK_GET_TOTAL_SUCCESS:
			return {
				...state,
				loadingTotal: false,
				total: action.total
			}
		case actionTypes.PAYMENT_BULK_GET_TOTAL_ERROR:
			return {
				...state,
				loadingTotal: false,
				total: null,
				error: action.error
			}
		default:
			return state;
	}
}