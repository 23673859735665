export const modules = [
    {
        "name": "MODULE_AUTO_ALLOCATION",
        "description": "Allocation criteria"
    },
    {
        "name": "MODULE_BANK_ACCOUNT",
        "description": "Bank accounts"
    },
    {
        "name": "MODULE_PAYMENT",
        "description": "Bank payments"
    },
    {
        "name": "MODULE_CM20PAYMENTS",
        "description": "Bank payments (CM2)"
    },
    {
        "name": "MODULE_BANK_STATEMENT",
        "description": "Bank statements"
    },
    {
        "name": "MODULE_CUSTOMER",
        "description": "Customers"
    },
    {
        "name": "MODULE_CUSTOMER_AGREEMENT",
        "description": "Customer agreements"
    },
    {
        "name": "MODULE_CUSTOMER_QUOTE",
        "description": "Customer quotes"
    },
    {
        "name": "MODULE_DASHBOARD",
        "description": "Dashboard"
    },
    {
        "name": "MODULE_EXTERNAL_DATA_SYNC",
        "description": "External data sync"
    },
    {
        "name": "MODULE_SCHEDULED_TASK",
        "description": "Scheduled tasks"
    },
    {
        "name": "MODULE_SMART_ID",
        "description": "Smart-ID"
    },
    {
        "name": "MODULE_SUPPLIER",
        "description": "Suppliers"
    },
    {
        "name": "MODULE_TASK",
        "description": "Tasks"
    },
];