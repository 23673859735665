import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { connect } from "react-redux";

import * as actions from '../../store/AllocationCriterion/actions';
import * as AllocationCriterionTrustLevel from '../../definitions/enums/AllocationCriterionTrustLevel';
import Backdrop from '../Common/Backdrop';
import * as editFormControls from '../../helpers/editFormControls';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';

import classes from './Pages.module.css';

const fieldDefaultValues = {
    id: 0,
    sequenceNo: 0,
    trustLevel: "SUGGESTION",
    type: "",
    hasAmount: true,
    hasBeneficiaryName: false,
    hasBeneficiaryRegNo: false,
    hasCurrency: true,
    hasInvoiceNoFullyContainedInPaymentDetails: false,
    hasPaymentDetailsFullyMatch: false,
    hasPaymentDateGreaterThanInvoiceDate: false,
    hasPaymentDateGreaterThanOrEqualsInvoiceDate: false,
    hasRecordTypeIsBankCharge: false,
}

class EditAllocationCriterion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...fieldDefaultValues,

            changed: false
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    setStateFromAllocationCriterion() {
        if (this.props.allocationCriterion && this.props.allocationCriterion.id) {
            this.setState({
                id: this.props.allocationCriterion.id,
                hasAmount: this.props.allocationCriterion.hasAmount,
                hasCurrency: this.props.allocationCriterion.hasCurrency,
                type: this.props.allocationCriterion.type,
                sequenceNo: this.props.allocationCriterion.sequenceNo,
                trustLevel: this.props.allocationCriterion.trustLevel,
                hasBeneficiaryName: this.props.allocationCriterion.hasBeneficiaryName,
                hasBeneficiaryRegNo: this.props.allocationCriterion.hasBeneficiaryRegNo,
                hasRecordTypeIsBankCharge: this.props.allocationCriterion.hasRecordTypeIsBankCharge,
                hasInvoiceNoFullyContainedInPaymentDetails: this.props.allocationCriterion.hasInvoiceNoFullyContainedInPaymentDetails,
                hasPaymentDetailsFullyMatch: this.props.allocationCriterion.hasPaymentDetailsFullyMatch,
                hasPaymentDateGreaterThanInvoiceDate: this.props.allocationCriterion.hasPaymentDateGreaterThanInvoiceDate,
                hasPaymentDateGreaterThanOrEqualsInvoiceDate: this.props.allocationCriterion.hasPaymentDateGreaterThanOrEqualsInvoiceDate,
            });
        } else {
            this.setState({
                ...fieldDefaultValues
            });
            this.setState({
                type: this.props.allocationCriterion.type ? this.props.allocationCriterion.type : "",
            });
            if (this.props.allocationCriterion.type === "BANK_CHARGE") {
                this.setState({
                    hasAmount: false,
                    hasCurrency: false
                });
            }
        }
    }

    componentDidMount() {
        this.setStateFromAllocationCriterion();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.allocationCriterion !== this.props.allocationCriterion) {
            this.setStateFromAllocationCriterion();
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedAllocationCriterion = {
            hasAmount: this.state.hasAmount,
            hasCurrency: this.state.hasCurrency,
            type: this.state.type,
            sequenceNo: this.state.sequenceNo,
            trustLevel: this.state.trustLevel,
            hasBeneficiaryName: this.state.hasBeneficiaryName,
            hasBeneficiaryRegNo: this.state.hasBeneficiaryRegNo,
            hasRecordTypeIsBankCharge: this.state.hasRecordTypeIsBankCharge,
            hasInvoiceNoFullyContainedInPaymentDetails: this.state.hasInvoiceNoFullyContainedInPaymentDetails,
            hasPaymentDetailsFullyMatch: this.state.hasPaymentDetailsFullyMatch,
            hasPaymentDateGreaterThanInvoiceDate: this.state.hasPaymentDateGreaterThanInvoiceDate,
            hasPaymentDateGreaterThanOrEqualsInvoiceDate: this.state.hasPaymentDateGreaterThanOrEqualsInvoiceDate,
        };
        if (this.state.id) {
            newOrUpdatedAllocationCriterion = {
                id: this.state.id,
                ...newOrUpdatedAllocationCriterion
            };
        }
        this.props.onSaveAllocationCriterion(newOrUpdatedAllocationCriterion);
        this.setState({
            changed: false
        });
        this.props.onClose();
    }

    closeForm = () => {
        this.props.onClose();
    }

    render() {

        let criterionFieldControls;
        switch (this.state.type) {
            case "PAYMENT":
                criterionFieldControls = <React.Fragment>
                    {editFormControls.checkboxControl("hasAmount", "Amount", this.onChange, this.state.hasAmount)}
                    {editFormControls.checkboxControl("hasCurrency", "Currency", this.onChange, this.state.hasCurrency)}
                    {editFormControls.checkboxControl("hasBeneficiaryName", "Beneficiary name", this.onChange, this.state.hasBeneficiaryName)}
                    {editFormControls.checkboxControl("hasBeneficiaryRegNo", "Beneficiary reg. No.", this.onChange, this.state.hasBeneficiaryRegNo)}
                    {editFormControls.checkboxControl("hasPaymentDetailsFullyMatch", "Payment details (full match)", this.onChange, this.state.hasPaymentDetailsFullyMatch)}
                </React.Fragment>
                break;
            case "CUSTOMER_INVOICE":
                criterionFieldControls = <React.Fragment>
                    {editFormControls.checkboxControl("hasAmount", "Amount", this.onChange, this.state.hasAmount)}
                    {editFormControls.checkboxControl("hasCurrency", "Currency", this.onChange, this.state.hasCurrency)}
                    {editFormControls.checkboxControl("hasBeneficiaryName", "Beneficiary name", this.onChange, this.state.hasBeneficiaryName)}
                    {editFormControls.checkboxControl("hasBeneficiaryRegNo", "Beneficiary reg. No.", this.onChange, this.state.hasBeneficiaryRegNo)}
                    {editFormControls.checkboxControl("hasInvoiceNoFullyContainedInPaymentDetails", "Invoice No. (full match)", this.onChange, this.state.hasInvoiceNoFullyContainedInPaymentDetails)}
                    {editFormControls.checkboxControl("hasPaymentDateGreaterThanInvoiceDate", "Payment date > Invoice date", this.onChange, this.state.hasPaymentDateGreaterThanInvoiceDate)}
                    {editFormControls.checkboxControl("hasPaymentDateGreaterThanOrEqualsInvoiceDate", "Payment date >= Invoice date", this.onChange, this.state.hasPaymentDateGreaterThanOrEqualsInvoiceDate)}
                </React.Fragment>
                break;
            case "SUPPLIER_INVOICE":
                criterionFieldControls = <React.Fragment>
                    {editFormControls.checkboxControl("hasAmount", "Amount", this.onChange, this.state.hasAmount)}
                    {editFormControls.checkboxControl("hasCurrency", "Currency", this.onChange, this.state.hasCurrency)}
                    {editFormControls.checkboxControl("hasBeneficiaryName", "Beneficiary name", this.onChange, this.state.hasBeneficiaryName)}
                    {editFormControls.checkboxControl("hasBeneficiaryRegNo", "Beneficiary reg. No.", this.onChange, this.state.hasBeneficiaryRegNo)}
                    {editFormControls.checkboxControl("hasInvoiceNoFullyContainedInPaymentDetails", "Invoice No. (full match)", this.onChange, this.state.hasInvoiceNoFullyContainedInPaymentDetails)}
                    {editFormControls.checkboxControl("hasPaymentDateGreaterThanInvoiceDate", "Payment date > Invoice date", this.onChange, this.state.hasPaymentDateGreaterThanInvoiceDate)}
                    {editFormControls.checkboxControl("hasPaymentDateGreaterThanOrEqualsInvoiceDate", "Payment date >= Invoice date", this.onChange, this.state.hasPaymentDateGreaterThanOrEqualsInvoiceDate)}
                </React.Fragment>
                break;
            case "BANK_CHARGE":
                criterionFieldControls = <React.Fragment>
                    {editFormControls.checkboxControl("hasRecordTypeIsBankCharge", "Record type is 'Bank charge'", this.onChange, this.state.hasRecordTypeIsBankCharge)}
                </React.Fragment>
                break;
        }

        const AllocationCriterionTrustLevelOptions = inputSelectUtils.generateOptionsFromData(
            AllocationCriterionTrustLevel.AllocationCriterionTrustLevel.filter(row => row.id !== 'BUILT_IN'),
            row => row.description
        );

        return (

            <div>
                <Backdrop
                    show
                    onClick={this.closeForm}
                >
                </Backdrop>
                <div
                    className={classes.RequestBankStatementContainer}
                >
                    <Card
                        className={classes.PageCard}
                    >
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    {this.props.allocationCriterion ? "Edit" : "Create"} allocation criterion
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.closeForm}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody>

                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                {editFormControls.hiddenValueControl("type", this.onChange, this.state.type)}

                                {editFormControls.staticTextControl("sequenceNo", "Sequence No.", this.state.sequenceNo)}
                                {editFormControls.selectControl("trustLevel", "Trust level", this.onChange, this.state.trustLevel, AllocationCriterionTrustLevelOptions)}

                                {criterionFieldControls}
                                <Row>
                                    <Col></Col>
                                    <Col>
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                    </Col>
                                </Row>
                            </form>

                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (allocationCriterion) => ({
    saving: allocationCriterion.saving
});

const mapDispatchToProps = dispatch => ({
    onSaveAllocationCriterion: (allocationCriterion) => dispatch(actions.allocationCriterionCreate(allocationCriterion))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAllocationCriterion);