import { put, call, takeLatest } from "redux-saga/effects";

import * as actions from "./actions";
import * as actionsSmartId from "../SmartId/actions";
import * as actionTypes from "./actionTypes";
import * as endpointsBackend from "../../definitions/endpoints/endpoints-backend";
import * as endpointsFrontend from "../../definitions/endpoints/endpoints-frontend";
import * as loginHelper from "../../helpers/loginHelper";
import * as rest from "../../helpers/restHelper";

function* userAccountResendActivationSaga({ data }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.USER_ACCOUNT_RESEND_ACTIVATION,
			data
		)
		yield put(actions.userAccountResendActivationSuccess(response.data));
	} catch(e) {
		yield put(actions.userAccountResendActivationError(e));
	}
}

function* userAccountUpdateSaga({ updatedUserAccount }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.USER_ACCOUNT_UPDATE_DATA,
			updatedUserAccount
		);

		yield put(actions.userAccountUpdateSuccess(response.data));
	} catch(e) {
		yield put(actions.userAccountUpdateError(e));
	}
}

function* userLoginSaga({ payload: { user, history } }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.USER_ACCOUNT_LOGIN,
			{
				username: user.email.trim(),
				password: user.password,
			},
			{
				withCredentials: true
			}
		);
		yield put(actions.loginSuccess(response.data));

		loginHelper.saveLastUser(user.email, response.data.tenantExternalId);
		loginHelper.saveLoginType(loginHelper.LOGIN_TYPE_PASSWORD);
		loginHelper.clearSmartIdSessionData();

		history.push(endpointsFrontend.START_PAGE_REDIRECT);

	} catch (error) {

		yield put(actions.loginFail(error));

	}
}

function* userLoginCheckSaga({ payload: { history }}) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.USER_ACCOUNT_LOGIN_CHECK,
			{
				withCredentials: true
			}
		);

		yield put(actions.loginSuccess(response.data));
		yield put(actionsSmartId.smartIdLoginRefresh());

		history.push(endpointsFrontend.START_PAGE_REDIRECT);

	} catch (error) {

		yield put(actions.loginCheckFail());

	}
}

function* userLogoutSaga({ payload: { history } }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.USER_ACCOUNT_LOGOUT,
			{
				withCredentials: true
			}
		);
		loginHelper.clearSmartIdSessionData();

	} catch (error) {
		yield put(apiError(error));
	}

	history.push(endpointsFrontend.USER_ACCOUNT_LOGIN);
}

function* userRegisterSaga({ payload: { data, history } }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.USER_ACCOUNT_CREATE,
			data,
			{
				withCredentials: false
			}
		);
		history.push(endpointsFrontend.USER_ACCOUNT_REGISTER_SUCCESS_ACTIVATION_PENDING.replace(":email", data.username));
	} catch (error) {
		yield put(actions.userRegisterError(error));
	}
}

function* authSaga() {
	yield takeLatest(actionTypes.USER_ACCOUNT_RESEND_ACTIVATION, userAccountResendActivationSaga);
	yield takeLatest(actionTypes.USER_ACCOUNT_UPDATE, userAccountUpdateSaga);
	yield takeLatest(actionTypes.USER_LOGIN, userLoginSaga);
	yield takeLatest(actionTypes.USER_LOGIN_CHECK, userLoginCheckSaga);
	yield takeLatest(actionTypes.USER_LOGOUT, userLogoutSaga);
	yield takeLatest(actionTypes.USER_REGISTER, userRegisterSaga);
}

export default authSaga;
