import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';

export const exportToXLSX = (records, columns, filename, sheetName) => {
    if (!records || !records.length) {
        return;
    }

    filename = filename ? filename : "report.xlsx";
    sheetName = sheetName ? sheetName : "Report";

    const recordsToExport = [];
    for (let i in records) {
        const thisRecord = records[i];
        let thisRecordToExport = {};
        for (let i in columns) {
            thisRecordToExport = {
                ...thisRecordToExport,
                [columns[i].text]: thisRecord[columns[i].dataField]
            }
        }
        recordsToExport.push(thisRecordToExport);
    };

    const ws = XLSX.utils.json_to_sheet(recordsToExport);

    const headerCellStyle = {
        font: { bold: true },
    };

    const fieldKeys = Object.keys(recordsToExport[0]);
    for (let colIdx = 0; colIdx < fieldKeys.length; colIdx++) {
        const cellRef = XLSX.utils.encode_cell({ r: 0, c: colIdx });	// Calculate cell reference from row and column index (e.g. [0, 0] => "A1")
        ws[cellRef].s = headerCellStyle;
    }

    // Calculate column widths based on maximum value lengths
    const columnWidths = recordsToExport.reduce((acc, record) => {
        Object.keys(record).forEach((key) => {
            acc[key] = Math.max((acc[key] || 0), String(record[key]).length);
        });
        return acc;
    }, {});

    ws['!cols'] = [];	// Need to initialize ws['!cols'] before we can use it
    const columnIndexes = Object.keys(columnWidths);
    columnIndexes.forEach((colIdx, key) => {
        ws['!cols'][key] = { width: columnWidths[colIdx] + 2 };
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
    const blob = new Blob([wbout], { type: 'application/vnd.ms-excel' });
    saveAs(blob, filename);
}