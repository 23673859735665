import * as actionTypes from "./actionTypes";

export const paymentBulkCreateFiles = (paymentBulksToCreateFiles, history) => {
	return {
		type: actionTypes.PAYMENT_BULK_CREATE_FILES,
		paymentBulksToCreateFiles: paymentBulksToCreateFiles,
		history: history
	}
}

export const paymentBulkCreateFilesError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_BULK_CREATE_FILES_ERROR,
		error: thisError
	}
}

export const paymentBulkCreateFilesSuccess = (exportFile) => {
	return {
		type: actionTypes.PAYMENT_BULK_CREATE_FILES_SUCCESS,
		exportFile: exportFile
	}
}

export const paymentBulkCreateError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_BULK_CREATE_ERROR,
		error: thisError
	}
}

export const paymentBulkCreateFromInvoices = (selectedInvoices, bankAccountId, currencyId, doGroupPayments, urgency, chargeBearer, history) => {
	return {
		type: actionTypes.PAYMENT_BULK_CREATE_FROM_INVOICES,
		selectedInvoices: selectedInvoices, 
		bankAccountId: bankAccountId,
		currencyId: currencyId,
		doGroupPayments: doGroupPayments,
		urgency: urgency,
		chargeBearer: chargeBearer,
		history: history
	}
}

export const paymentBulkCreatePartialSuccess = (notCreatedPaymentBulks) => {
	return {
		type: actionTypes.PAYMENT_BULK_CREATE_PARTIAL_SUCCESS,
		notCreatedPaymentBulks: notCreatedPaymentBulks
	}
}

export const paymentBulkCreateSuccess = (payment) => {
	return {
		type: actionTypes.PAYMENT_BULK_CREATE_SUCCESS,
		payment: payment
	}
}

export const paymentBulkEdit = (paymentBulk, history) => {
	return {
		type: actionTypes.PAYMENT_BULK_EDIT,
		paymentBulk: paymentBulk,
		history: history
	}
}

export const paymentBulkEditError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_BULK_EDIT_ERROR,
		error: thisError
	}
}

export const paymentBulkEditSuccess = () => {
	return {
		type: actionTypes.PAYMENT_BULK_EDIT_SUCCESS
	}
}

export const paymentBulkDelete = (id, history) => {
	return {
		type: actionTypes.PAYMENT_BULK_DELETE,
		id: id,
		history: history
	}
}

export const paymentBulkDeleteList = (paymentBulksToDelete, history) => {
	return {
		type: actionTypes.PAYMENT_BULK_DELETE_LIST,
		paymentBulksToDelete: paymentBulksToDelete,
		history: history
	}
}

export const paymentBulkDeleteError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_BULK_DELETE_ERROR,
		error: thisError
	}
}

export const paymentBulkDeleteSuccess = () => {
	return {
		type: actionTypes.PAYMENT_BULK_DELETE_SUCCESS
	}
}

export const paymentBulkGetById = (id) => {
	return {
		type: actionTypes.PAYMENT_BULK_GET_BY_ID,
		id: id
	}
}

export const paymentBulkGetError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_BULK_GET_ERROR,
		error: thisError
	}
}

export const paymentBulkGetPrepared = () => {
	return {
		type: actionTypes.PAYMENT_BULK_GET_PREPARED
	}
}

export const paymentBulkGetSuccess = (data) => {
	return {
		type: actionTypes.PAYMENT_BULK_GET_SUCCESS,
		paymentBulks: data
	}
}

export const paymentBulkGetTotal = (selectedPaymentBulks, history) => {
	return {
		type: actionTypes.PAYMENT_BULK_GET_TOTAL,
		selectedPaymentBulks: selectedPaymentBulks,
		history: history
	}
}

export const paymentBulkGetUnallocated = () => {
	return {
		type: actionTypes.PAYMENT_BULK_GET_UNALLOCATED
	}
}

export const paymentBulkGetTotalError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_BULK_GET_TOTAL_ERROR,
		error: thisError
	}
}

export const paymentBulkGetTotalSuccess = (total) => {
	return {
		type: actionTypes.PAYMENT_BULK_GET_TOTAL_SUCCESS,
		total: total
	}
}

