import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

import AppRoute from "./routes/route";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import NonAuthLayout from "./components/NonAuthLayout";
import VerticalLayout from "./components/VerticalLayout/";

import "./assets/scss/theme.scss";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const Layout = VerticalLayout;

		return (
			<React.Fragment>
				<Router basename="/">
					<Switch>
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}

						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								exact
							/>
						))}
					</Switch>
				</Router>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout,
	}
}

App.propTypes = {
	layout: PropTypes.object,
}

export default connect(mapStateToProps, null)(App);
