import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert, Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Row } from "reactstrap";

import * as actionsPayment from '../../store/Payment/actions';
import * as actionsPaymentBulk from '../../store/PaymentBulk/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as browseFormControls from "../../helpers/browseFormControls";
import * as browseFormTools from "../../helpers/browseFormTools";
import * as columnsPayment from "../../definitions/columns/payment";
import * as columnsPaymentBulk from "../../definitions/columns/paymentBulk";
import * as columnsPaymentLine from "../../definitions/columns/paymentLine";
import * as config from '../../config';
import * as editFormControls from "../../helpers/editFormControls";
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as selectRowUtils from '../../helpers/selectRowUtils';
import * as xlsxHelper from '../../helpers/xlsxHelper';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class PaymentBulkBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			paymentBulksAreExpandable: false,
			paymentTotal: null,
			reportDropdownOpen: false,
			selectedRows: [],
			error: null
		}
	}

	setExpandable() {
		let paymentBulksAreExpandable = false;
		for (let j in this.props.paymentBulks) {

			const thisPaymentBulk = this.props.paymentBulks[j];
			if (thisPaymentBulk.isExpandable) {
				paymentBulksAreExpandable = true;
				break;
			}
		}

		this.setState({
			paymentBulksAreExpandable: paymentBulksAreExpandable
		});
	}

	componentDidMount() {
		if (this.props.selectedCompany) {
			this.props.onGetPreparedPayments();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		// 2023.11.27: fix: 231123-CompanyId
		if (prevProps.selectedCompany != this.props.selectedCompany) {
			this.props.onGetPreparedPayments();
		}

		if (prevProps.total != this.props.total) {
			this.setState({
				paymentTotal: this.props.total
			});
		}

		if (prevProps.error != this.props.error) {
			this.setState({
				error: this.props.error
			})
		}

		if ((prevProps.paymentBulks != this.props.paymentBulks) && this.props.paymentBulks && this.props.paymentBulks.length) {
			this.setExpandable();
		}

		if (prevProps.paymentLines != this.props.paymentLines && this.props.paymentLines && this.props.paymentLines.length) {
			xlsxHelper.exportToXLSX(this.props.paymentLines, [
				columnsPaymentLine.supplierName,
				columnsPaymentLine.supplierInvoiceNo,
				columnsPaymentLine.amountToPay,
				columnsPaymentLine.currencyCode,
				columnsPaymentLine.supplierBankAccountNo
			], "prepared-payments-details.xlsx", "Prepared payments (details)");
		}

		if (prevState.selectedRows !== this.state.selectedRows) {
			this.props.onGetPaymentTotal(this.state.selectedRows);
		}
	}

	onApprovePayments = () => {
		if (window.confirm("Approve the selected payments for signing?")) {
			this.props.onApprovePayments(this.state.selectedRows, this.props.history);
		}
	}

	onDeletePayments = () => {
		if (window.confirm("Are you sure you want to delete the selected payments?")) {
			this.props.onDeletePayments(this.state.selectedRows /*, this.props.history*/);
			this.setState({
				selectedRows: []
			});
		}
	}

	columns = [
		columnsPaymentBulk.bankAccountNo,
		columnsPaymentBulk.supplierName,
		columnsPaymentBulk.totalAmount,
		columnsPaymentBulk.currencyCode,
		columnsPaymentBulk.paymentDate,
		columnsPaymentBulk.supplierBankAccountNo,
		columnsPaymentBulk.paymentDetails
	];

	onSummaryReport = () => {
		xlsxHelper.exportToXLSX(this.props.paymentBulks, this.columns, "prepared-payments-summary.xlsx", "Prepared payments (summary)");
	}

	onDetailedReport = () => {
		// Relies on componentDidUpdate() to issue exportToXLSX()
		this.props.onGetPreparedPaymentLines();
	}

	render() {

		const pageTitle = "Prepared payments | " + config.AppName;;
		const breadcrumbsTitle = "Payments";
		const breadcrumbsItem = "Browse prepared payments";

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				this.props.history.push(endpointsFrontend.PAYMENT_EDIT.replace(":id", row.id));
			}
		};

		const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
			},

			onSelectAll: (isSelect, rows, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect)
				this.setState({
					selectedRows: selectedRows
				});
			}
		};

		const expandPaymentBulkRow = {
			renderer: (row, rowIndex) => {
				const columns = browseFormTools.removeFiltersFromColumns([
					columnsPayment.bankAccountNo,
					columnsPayment.supplierName,
					columnsPayment.totalAmountToPay,
					columnsPayment.currencyCode,
					columnsPayment.supplierBankAccountNo,
					columnsPayment.paymentDetails
				]);

				return (
					<table className="table">
						<tbody style={{ borderColor: "#FFFFFF" }}>
							<tr>
								<td style={{ border: "0px transparent" }}></td>
								<td style={{ border: "0px transparent" }}>
									<div
										style={{
											position: "relative"
										}}
									>
										<BootstrapTable
											keyField='id'
											data={row.payments}
											columns={columns}
											condensed
										/>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				);
			},

			// The following usage of paymentBulksAreExpandable seems to be the only correct one. 
			// If it is used to redefine paymentBulkTable instead, there are problems 
			// (apparently because of state change BootstrapTable first gets defined without expanding rows, 
			// then gets redefined with expanding rows and that leads to errors).
			// However we leave the code in cm20-payments-browse and payment-file-browse as it is,
			// because it works - apparently because the table only gets defined upon row expansion,
			// when the state is already set.
			showExpandColumn: this.state.paymentBulksAreExpandable,
			//expandByColumnOnly: true,

			expandColumnRenderer: ({ expanded }) => browseFormControls.expandColumnRenderer(expanded),
			expandHeaderColumnRenderer: ({ isAnyExpands }) => browseFormControls.expandHeaderColumnRenderer(isAnyExpands)
		}

		const paymentBulkTable =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.paymentBulks}
					columns={this.columns}
					pagination={paginationFactory({
						sizePerPageList: [{
							text: '10', value: 10
						}, {
							text: '25', value: 25
						}, {
							text: '50', value: 50
						}, {
							text: 'All', value: this.props.paymentBulks && this.props.paymentBulks.length ? this.props.paymentBulks.length : 100
						}]
					})}
					rowEvents={rowEvents}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory()}
					selectRow={selectRow}
					expandRow={expandPaymentBulkRow}
				/>
			</div>

		const approveButton =
			<Button
				color="primary"
				disabled={this.state.selectedRows.length === 0}
				onClick={this.onApprovePayments}
			>
				Approve for signing
				{" "}
				{this.props.saving ? editFormControls.buttonSpinner() : null}
			</Button>

		const reportDropdown =
			<Dropdown
				isOpen={this.state.reportDropdownOpen}
				toggle={() => this.setState({
					reportDropdownOpen: !this.state.reportDropdownOpen
				})}
			>
				<DropdownToggle
					color="secondary"
					disabled={!this.props.paymentBulks || this.props.paymentBulks.length === 0}
					caret
				>
					Report...
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem onClick={this.onSummaryReport}>Summary (XLSX)</DropdownItem>
					<DropdownItem onClick={this.onDetailedReport}>Detailed by invoices (XLSX)</DropdownItem>
				</DropdownMenu>
			</Dropdown>

		const totalLabel =
			<Label
				className="col-form-label col-lg-2"
			>
				{this.state.paymentTotal ? this.state.paymentTotal.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null}
				{" "}
				{this.state.paymentTotal ? this.state.paymentTotal.currencyCode : null}
			</Label>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.state.error)}
										{editFormControls.errorAlert(this.props.errorLines)}

										{this.props.notCreatedPayments && this.props.notCreatedPayments.length ? (
											<Alert color="warning">{this.props.notCreatedPayments.length} payment(s) could not be created because their total amount is zero or negative</Alert>
										) : null}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.paymentBulks ?
											paymentBulkTable
											: null}

										<div style={{ display: 'flex' }}>
											{totalLabel}

											{approveButton}

											&nbsp;

											{reportDropdown}

											&nbsp;

											{editFormControls.deleteButton(this.props.deleting, this.onDeletePayments, true, this.state.selectedRows.length === 0)}
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ company, payment, paymentBulk }) => ({
	deleting: paymentBulk.deleting,
	error: paymentBulk.error,
	errorLines: payment.errorLines,
	loading: paymentBulk.loading,
	loadingLines: payment.loadingLines,
	notCreatedPayments: payment.notCreatedPayments,
	paymentBulks: paymentBulk.paymentBulks,
	paymentLines: payment.paymentLines,
	saving: paymentBulk.saving,
	selectedCompany: company.selectedCompany,
	total: paymentBulk.total,
})

const mapDispatchToProps = dispatch => ({
	onApprovePayments: (paymentsToSendToBank, history) => dispatch(actionsPaymentBulk.paymentBulkCreateFiles(paymentsToSendToBank, history)),
	onDeletePayments: (paymentsToDelete) => dispatch(actionsPaymentBulk.paymentBulkDeleteList(paymentsToDelete)),
	onGetPreparedPayments: () => dispatch(actionsPaymentBulk.paymentBulkGetPrepared()),
	onGetPreparedPaymentLines: () => dispatch(actionsPayment.paymentLineGetPrepared()),
	onGetPaymentTotal: (selectedPaymentBulks, history) => dispatch(actionsPaymentBulk.paymentBulkGetTotal(selectedPaymentBulks, history)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PaymentBulkBrowse));
