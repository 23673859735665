import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as formatUtils from "../../helpers/formatUtils";
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from '../../helpers/sagaHelper';

function* deleteBankStatementSaga({ idList }) {
	try {
		const response = yield call(
			rest.del,
			endpointsBackend.BANK_STATEMENT_GET_ALL,
			{ idList: idList }
		);
		yield put(actions.bankStatementDeleteSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.bankStatementDeleteError(e));
	}
}

function* getAllBankStatementsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.BANK_STATEMENT_GET_ALL
		);

		const entries = [];
		for (let key in response.data) {
			entries.push({
				...response.data[key],
				allocatedAmountRatioInfo: formatUtils.formatAmountRatio(response.data[key].allocatedAmountRatio),
				allocatedAmountRatioValue: formatUtils.ratioValue(response.data[key].allocatedAmountRatio),
				allocatedRecordRatioInfo: formatUtils.formatRatio(response.data[key].allocatedRecordRatio),
				allocatedRecordRatioValue: formatUtils.ratioValue(response.data[key].allocatedRecordRatio),
				appliedRatioInfo: formatUtils.formatRatio(response.data[key].appliedRatio),
				appliedRatioValue: formatUtils.ratioValue(response.data[key].appliedRatio)
			});
		}

		yield put(actions.bankStatementGetSuccess(entries));
	} catch (e) {
		yield put(actions.bankStatementGetError(e));
	}
}

function* getBankStatementByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.BANK_STATEMENT_GET_BY_ID.replace("{bankStatementId}", id)
		);

		yield put(actions.bankStatementGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.bankStatementGetError(e));
	}
}

function* receiveBankStatementsSaga() {
	try {
		yield call(
			rest.get,
			endpointsBackend.BANK_STATEMENT_RECEIVE_ALL
		);

		yield put(actions.bankStatementReceiveSuccess());
		yield call(getAllBankStatementsSaga);
	} catch (e) {
		yield put(actions.bankStatementReceiveError(e));
	}
}

function* refreshAllBankStatementsSaga() {
	// Same as getAllBankStatementsSaga(), but does not put actions.bankStatementGetInProcess(), i.e. does not trigger loading = true in the reducer
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.BANK_STATEMENT_GET_ALL
		);

		const entries = [];
		for (let key in response.data) {
			entries.push({
				...response.data[key],
				allocatedAmountRatioInfo: formatUtils.formatAmountRatio(response.data[key].allocatedAmountRatio),
				allocatedAmountRatioValue: formatUtils.ratioValue(response.data[key].allocatedAmountRatio),
				allocatedRecordRatioInfo: formatUtils.formatRatio(response.data[key].allocatedRecordRatio),
				allocatedRecordRatioValue: formatUtils.ratioValue(response.data[key].allocatedRecordRatio),
				appliedRatioInfo: formatUtils.formatRatio(response.data[key].appliedRatio),
				appliedRatioValue: formatUtils.ratioValue(response.data[key].appliedRatio)
			});
		}

		yield put(actions.bankStatementGetSuccess(entries));
	} catch (e) {
		yield put(actions.bankStatementGetError(e));
	}
}

function* requestBankStatementsSaga({ request }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.BANK_STATEMENT_REQUEST,
			request
		);

		yield put(actions.bankStatementRequestSuccess());
		yield call(getAllBankStatementsSaga);
	} catch (e) {
		yield put(actions.bankStatementRequestError(e));
	}
}

function* uploadBankStatementSaga({ bankStatement }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.BANK_STATEMENT_LOAD_FROM_FILE,
			bankStatement
		);
		yield put(actions.bankStatementUploadSuccess(response.data));
		yield call(getAllBankStatementsSaga);
	} catch (e) {
		yield put(actions.bankStatementUploadError(e));
	}
}

function* bankStatementSaga() {
	yield (takeLatest(actionTypes.BANK_STATEMENT_GET_ALL, getAllBankStatementsSaga));
	yield (takeLatest(actionTypes.BANK_STATEMENT_GET_BY_ID, getBankStatementByIdSaga));
	yield (takeLatest(actionTypes.BANK_STATEMENT_RECEIVE, receiveBankStatementsSaga));
	yield (takeLatest(actionTypes.BANK_STATEMENT_REFRESH_ALL, refreshAllBankStatementsSaga));
	yield (takeLatest(actionTypes.BANK_STATEMENT_REQUEST, requestBankStatementsSaga));
	yield (takeLatest(actionTypes.BANK_STATEMENT_UPLOAD, uploadBankStatementSaga));
	yield (takeLatest(actionTypes.BANK_STATEMENT_DELETE, deleteBankStatementSaga));
}

export default bankStatementSaga;